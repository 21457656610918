import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { InstantSearch, Configure } from 'react-instantsearch-dom';
import { Button, Drawer } from 'antd';

import { useProducts } from '../hooks/useProducts';
import { useTags } from '../hooks/useTags';

import SelectionResults from './SelectionResults';
import SelectionSidebar from './SelectionSidebar';
import SelectionFilter from './SelectionFilters';
import SelectionCartList from './SelectionCartList';
import SearchBox from '../../../Common/Algolia/SearchBox';
import Pagination from '../../../Common/Algolia/Pagination';
import StoreHeader from '../components/StoreHeader';

import { productsPerPage } from '../helpers';
import { paginate } from 'components/Common/helpers/helper';

import 'instantsearch.css/themes/algolia.css';

const SelectionPage = () => {
	const [productsLoading, products] = useProducts();
	const [tagsLoading, , , getFilterTagIds] = useTags();
	const searchFuse = useSelector(state => state.ecommerce.storeSearchFuse);
	const publicEmbed = useSelector(state => state.settings.publicEmbed);

	const selectableProducts = useMemo(() => products.filter(p => p.show), [products]);
	const [showFilters, setShowFilters] = useState(false);

	const loading = tagsLoading || productsLoading;

	const customSearchClient = {
		search: requests =>
			new Promise((resolve, reject) => {
				if (loading) return reject();

				const { query, page, hitsPerPage, facetFilters = [] } = requests[0].params;

				// title and desc search, definitions in ecommerce redux
				let totalHits =
					query === '' ? selectableProducts : searchFuse.search(query).map(r => r.item);

				const facets = { category: {}, tag: {} };

				const categorySet = new Set(selectableProducts.map(p => p.categoryId));
				const tagSet = new Set(selectableProducts.flatMap(p => getFilterTagIds(p)));

				const buildCategoryFacets = () => {
					for (const categoryId of categorySet) {
						const count = totalHits.filter(p => p.categoryId === categoryId).length;
						facets.category[categoryId] = count;
					}
				};

				const buildTagFacets = () => {
					for (const tagId of tagSet) {
						const count = totalHits.filter(p => getFilterTagIds(p).includes(tagId))
							.length;
						facets.tag[tagId] = count;
					}
				};

				// build facets before filtering first
				buildCategoryFacets();
				buildTagFacets();

				// facet filtering logic
				for (let i = 0; i < facetFilters.length; i++) {
					const key = facetFilters[i][0].split(':')[0];
					const values = facetFilters[i].map(filter => parseInt(filter.split(':')[1]));

					switch (key) {
						case 'category':
							totalHits = totalHits.filter(p => values.includes(p.categoryId));
							buildTagFacets(); // filtering by category only affects count of tags
							break;
						case 'tag':
							totalHits = totalHits.filter(p =>
								values.some(id => getFilterTagIds(p).includes(id)),
							);
							buildCategoryFacets(); // filtering by tags only affect count of cats
							break;
					}
				}

				// sort
				totalHits.sort((a, b) => b.publicationDate - a.publicationDate);

				// pagination
				const nbHits = totalHits.length;
				const nbPages = Math.ceil(nbHits / hitsPerPage);
				const hits = paginate(totalHits, page, hitsPerPage);

				resolve({ results: [{ hits, nbHits, page, nbPages, hitsPerPage, facets }] });
			}),
	};

	return (
		<main className={publicEmbed ? 'gx-m-4' : 'container'}>
			<InstantSearch indexName="ecommerce" searchClient={customSearchClient}>
				{/* <StoreHeader /> */}
				<section className="gx-d-flex" style={{ overflow: 'visible', gap: '2em' }}>
					<Drawer
						placement="right"
						visible={showFilters}
						onClose={() => setShowFilters(false)}
					>
						<div className="gx-algolia-sidebar gx-mx-0 gx-pt-5 gx-pt-4">
							<SelectionCartList />
						</div>
					</Drawer>

					<div className="gx-flex-1" style={{ overflow: 'visible' }}>
						<div className="gx-d-flex gx-justify-content-end">
							<Button className="gx-d-lg-none" onClick={() => setShowFilters(true)}>
								My Shopping Cart
							</Button>
						</div>

						<div className="gx-mb-3 gx-d-flex">
							<SelectionFilter />
							<SearchBox />
						</div>

						<div className="gx-mb-4 selection-result-wrapper">
							<SelectionResults />
						</div>

						<Configure hitsPerPage={productsPerPage} />

						<div className="gx-text-center gx-pt-3">
							<Pagination />
						</div>
					</div>
					<aside className="gx-d-none gx-d-lg-block cart-display-wrapper">
						<div className="gx-algolia-sidebar gx-mx-0 gx-p-3 card shadow-2">
							<SelectionCartList />
						</div>
					</aside>
				</section>
			</InstantSearch>
		</main>
	);
};

export default SelectionPage;

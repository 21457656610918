import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Button, Spin, Alert } from 'antd';
import { useLocation } from 'react-router';
import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom';
import {
	addRegistrationItemsToCartSuccess,
	requestResetCart,
	requestToFetchExhibitorCartUuid,
	customStateHandlerCMS,
	requestToUpdateStepDetails
} from '@appRedux/actions';
import { requestEraseCommonData } from '@appRedux/actions/Common';
import CircularProgress from '@components/CircularProgress';
import FormComponent from '@components/form';
import { parseScreen } from '@util/form';
import { goToFirstError, generateItems } from '@components/Membership/helpers/generalHelpers';
import { addRegistrationItemsToCart } from '../../../api/Ecommerce';
import _isEmpty from 'lodash/isEmpty';

const ExhibitorFinancialItems = ({
	nextStep = () => { },
	previousStep = () => { },
	order = 0,
	showMakePayment = false,
	showPrevious = false,
	isMultiple = false,
	isStaffRegistration = false,
	updateForm = () => { },
	formFields = {},
	doValidate = false,
	formValues,
}) => {
	const [formControl] = Form.useForm();
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const isTokenInUrl = location?.search?.includes('token');

	const { corporateMembership, loginInfo, settings, auth } = useSelector(state => state);
	const { menu, menuFetched } = corporateMembership;
	const subMenu = menu.find(data => data.screentype === "SubMenu")?.submenu ?? [];
	const { corporateMembershipModuleUrlId: moduleUrlId } = loginInfo;
	const { authUser } = auth;
	const { publicEmbed } = settings;
	const loading = !menuFetched;


	const [loader, setLoader] = useState(true);
	const [companyCreated, setCompanyCreated] = useState(false);
	const [financialItemsMenuItems, setFinancialItemsMenuItems] = useState([]);
	const [currentFinancialItem, setCurrentFinancialItem] = useState({});
	const [validate, setValidate] = useState(false);
	const [formState, setFormState] = useState({});
	const [initialValues, setInitialValues] = useState({});
	const [validationsObject, setValidationsObject] = useState({ error: false, validationErrors: {}, });
	const [cartLoader, setCartLoader] = useState(false);
	const [alertMessage, setAlertMessage] = useState(false);

	useEffect(() => {
		if (corporateMembership.currentFinancialItem > -1 && financialItemsMenuItems.length > 0) {
			setCurrentFinancialItem(financialItemsMenuItems[corporateMembership.currentFinancialItem]);
		}
	}, [corporateMembership.currentFinancialItem, financialItemsMenuItems]);

	useEffect(() => {
		if (corporateMembership.exhibitorCart?.simpleInvoiceObj && !isMultiple) {
			setInitialValues(corporateMembership.exhibitorCart.simpleInvoiceObj);
		}
		dispatch(requestEraseCommonData());
	}, []);

	useEffect(() => {
		if (corporateMembership.exhibitorCart?.simpleInvoiceObj && !isMultiple) {
			setInitialValues(corporateMembership.exhibitorCart?.simpleInvoiceObj);
		}
	}, [corporateMembership?.exhibitorCart?.simpleInvoiceObj]);

	useEffect(() => {
		if (publicEmbed) {
			setLoader(false);
			dispatch(requestToFetchExhibitorCartUuid({
				appDir: loginInfo.appdir,
				moduleUrlId: moduleUrlId,
				publicEmbed: true,
			}));
		}
		if (corporateMembership.firstPaymentMade && !publicEmbed) {
			dispatch(requestResetCart());
		}
	}, [publicEmbed]);

	useEffect(() => {
		if (corporateMembership.userCompanyAffiliationFetched) {
			setLoader(false);
			if (corporateMembership.userCompanyAffiliation['comp_uuid'] && (!publicEmbed || isTokenInUrl)) {
				setCompanyCreated(true);
			} else if (!publicEmbed) {
				history.push('home');
			}
		}
	}, [
		corporateMembership.userCompanyAffiliationFetched,
		publicEmbed,
		corporateMembership.userCompanyAffiliation?.comp_uuid,
	]);
	useEffect(() => {
		if (!loading) {
			if (isMultiple) {
				setCurrentFinancialItem(formFields);
				setInitialValues(formValues);
			} else if (menu) {
				const menuItems = menu.filter(menu => menu.screentype === 'SubMenu');
				const subMenu = menuItems[0].submenu;
				// let the spread as it is, state variable getting affected if the spread removed.
				setFinancialItemsMenuItems([...subMenu]);
				let tempFinancialItems = { ...subMenu[0] };
				tempFinancialItems.fieldids = tempFinancialItems?.fieldids?.filter(({ field_definition }) => field_definition !== 'individual_corp_member')
				setCurrentFinancialItem(tempFinancialItems);
			} else if (publicEmbed) {
				history.push(`/main/profilehome`);
			}
		}
	}, [loading, publicEmbed, order, isMultiple, formValues]);

	useEffect(() => {
		if (corporateMembership.exhibitorCartFetched && !isMultiple) {
			setInitialValues(corporateMembership.exhibitorCart.simpleInvoiceObj);
		}
	}, [
		corporateMembership.exhibitorCartFetched,
		corporateMembership.exhibitorCart.simpleInvoiceObj,
	]);
	useEffect(() => {
		if (isMultiple) { setValidate(doValidate) }
	}, [doValidate, isMultiple])
	const handleSubmit = event => {
		setCartLoader(true)
		setAlertMessage(false)
		//prevent double submit of button, it can't differenatiae between react and html button so sometimes it submits twice
		event?.preventDefault && event.preventDefault();
		let topElement = document.querySelector('#root > div > div > div > div:nth-child(1) > div');
		if (topElement) {
			if (topElement.scrollIntoViewIfNeeded) { topElement.scrollIntoViewIfNeeded(); }
			else { topElement.scrollIntoView(); }
		}
		setValidate(true);
		if (validationsObject['error']) {
			setCartLoader(false)
			goToFirstError(validationsObject);
			NotificationManager.warning('You are missing something', 'Validation');
			return;
		}
		const itemstoadd = { ...generateItems(formState) };
		removeAdditionalSlotDetails(itemstoadd)   // if plan is change collapse step2 details otherwise maintained in the redux
		addRegistrationItemsToCart({
			payload: { itemstoadd },
			urlId: moduleUrlId,
			exhibUuid: corporateMembership.exhibitorCartDetails.exhibuuid,
			compUuid: corporateMembership.userCompanyAffiliation.comp_uuid,
			appDir: loginInfo.appdir,
			cartUuid: corporateMembership.exhibitorCartDetails.exhibitorCartUuid,
			publicEmbed
		}).then((data) => {
			if (!data.error) {
				dispatch(addRegistrationItemsToCartSuccess(data))
				setCartLoader(false)
				if (showMakePayment) { history.push(`/main/company/${moduleUrlId}/checkout`); }
				else { nextStep(); }
			} else {
				setCartLoader(false)
			}
			dispatch(customStateHandlerCMS({ key: "membershipFormVisited", data: 1 }))
		}).catch(((err) => {
			NotificationManager.error("Something went wrong!!!")
			setCartLoader(false)
		}))
	};

	useEffect(() => {
		if (corporateMembership.membershipFormVisited > 0) {
			setAlertMessage(true)
		}
	}, [corporateMembership.membershipFormVisited])

	const removeAdditionalSlotDetails = (itemstoadd) => {
		let tempFinancialItems = { ...subMenu[0] };
		tempFinancialItems.fieldids = tempFinancialItems?.fieldids?.filter(({ field_definition }) => field_definition === 'company_corp_member')
		let selectedformItem = tempFinancialItems.fieldids.filter((item => {
			return Object.keys(formState).some((data) => data === item.fieldname)
		}))
		selectedformItem = Object.keys(itemstoadd).filter((key) => key.includes(selectedformItem[0]?.fieldname)).
			reduce((cur, key) => { return Object.assign(cur, { [key]: itemstoadd[key] }) }, {});
		let checkFormValueId = corporateMembership?.exhibitorCart?.InvoiceItemArray.find(data => data?.value_id === Object.values(selectedformItem)[0]?.valueid)
		if (_isEmpty(checkFormValueId)) {
			const detailsObj = {
				additionalInfo: [],
				totalAdditionalCost: '',
				members: [],
				selectedMembership: ''
			}
			dispatch(requestToUpdateStepDetails(detailsObj));
		}
		return
	}


	const handleFormChange = (validations, all) => {
		setValidationsObject(validations);
		let tmpFormState = { ...formState, ...{ ...all, } };
		Object.keys(tmpFormState,).forEach(key =>
			tmpFormState[key] === undefined || tmpFormState[key] == -1
				? delete tmpFormState[key]
				: {}
		);
		setFormState(tmpFormState);
		setInitialValues({ ...all });
	}

	return (loading || loader) ? (<CircularProgress className="gx-profile-content" />)
		: (companyCreated || publicEmbed) && (
			<Spin spinning={cartLoader} tip={"add to cart..."} >
				{alertMessage && !corporateMembership.generalSettings.HideAdditionalMemberTab && <Alert message="If you change your membership plan you may need to re-do your individual staff membership assignments." type="warning" showIcon className='gx-mt-3 gx-font-weight-medium membership-plan'></Alert>}
				<div className={`gx-main-content ${"gx-mt-3"}`}>
					{((loginInfo.source_hex
						&& (corporateMembership?.userCompanyAffiliation?.comp_uuid || !authUser)
					) || publicEmbed)
						&& Object.keys(currentFinancialItem).length
						&& (
							<FormComponent
								form={formControl}
								initialValues={initialValues}
								formData={parseScreen(currentFinancialItem)}
								validationConfig={{
									module: 'exhibitor',
									moduleurlid: moduleUrlId,
									menuid: currentFinancialItem['menuid'],
								}}
								onChange={isMultiple ? updateForm : handleFormChange}
								validate={validate}
								staffRegistration={isStaffRegistration}
							/>
						)
					}
					<div>
						{!isMultiple &&
							<div>
								{showPrevious ? <Button type='primary' className='gx-float-left' onClick={previousStep}>Previous</Button> : null}
								<Button
									type='primary'
									className='gx-float-right'
									onClick={handleSubmit}>
									{showMakePayment ? "Make Payment" : "Next"}
								</Button>
							</div>
						}
					</div>
				</div>
			</Spin>
		)
};

export default ExhibitorFinancialItems;
import React, { useMemo } from 'react';
import { useParams, useHistory } from 'react-router';

import { useProducts } from '../hooks/useProducts';

import ConsumptionHeader from '../components/ConsumptionHeader';
import ConsumptionArea from '../components/ConsumptionArea';
import '../styles/custom.less';

const ProductPage = () => {
	const { productId } = useParams();
	const history = useHistory();

	const [prodsLoading, products] = useProducts();
	const product = useMemo(() => products.find(prod => prod.id === parseInt(productId)), [
		products,
	]);
	const parentBundle = useMemo(() => products.find(prod => prod.id === product.parentId), [
		products,
	]);

	const goBack = () => {
		if (parentBundle?.isSuperBundle) {
			history.push(`../selection/${parentBundle.id}${history.location.search}`);
		} else {
			history.push(`../selection${history.location.search}`);
		}
	};

	return (
		<main className="gx-bg-white gx-d-flex gx-flex-column" /* style={{ minHeight: '100vh' }} */>
			<ConsumptionHeader
				loading={prodsLoading}
				product={product}
				superBundle={parentBundle}
				goBack={goBack}
			/>

			<article className="gx-flex-1">
				<ConsumptionArea
					loading={prodsLoading}
					product={product}
					expanded
				/>
			</article>
		</main>
	);
};

export default ProductPage;

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { requestStoreProductPrices } from '../../../../appRedux/actions/EcommerceStore';

export const useProductPrices = () => {
	const dispatch = useDispatch();
	const productPrices = useSelector(state => state.ecommerce.storeProductPrices);
	const appdir = useSelector(state => state.loginInfo.appdir);
	const loading = useSelector(state => state.ecommerce.storePricesLoading);

	const { moduleUrlId } = useParams();

	const getPrices = productIds => {
		productIds = productIds.filter(id => !productPrices.some(p => p.productid === id));
		if (productIds.length > 0)
			dispatch(requestStoreProductPrices({ appdir, moduleUrlId, productIds }));
	};

	return [productPrices, getPrices, loading];
};

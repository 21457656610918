import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@components/CircularProgress';
import { Button, Card } from 'antd';
import { useParams } from 'react-router';
import CreateCompany from './CreateCompany';
import { RightOutlined } from '@ant-design/icons';

const ExhibitorHome = props => {
	const nextStep = props.nextStep ? props.nextStep: () => { };
	const previousStep = props.previousStep ? props.previousStep: () => { };
	const [loader, setLoader] = useState(true);
	const [companyCreated, setCompanyCreated] = useState(false);
	const [homeMenuItems, setHomeMenuItems] = useState([]);
	const { moduleUrlId } = useParams();

	useEffect(() => {
		if (props.corporateMembership.userCompanyAffiliationFetched) {
			setLoader(false);
			if (props.corporateMembership.userCompanyAffiliation.comp_uuid) {
				setCompanyCreated(true);
			}
		}
	}, [props.corporateMembership.userCompanyAffiliationFetched]);

	useEffect(() => {
		if (props.corporateMembership.menuFetched) {
			if (props.corporateMembership.menu) {
				setHomeMenuItems(
					props.corporateMembership.menu.filter(menu => {
						return menu.screentype === 'Home';
					}),
				);
			} else {
				props.history.push(`/main/profilehome`);
			}
		}
	}, [props.corporateMembership.menuFetched]);

	const handleSubmit =() => {
	nextStep();
	};

	const updateCompanyCreated = isCreated => {
		setCompanyCreated(isCreated);
	};
	return (
		<div>
			{!props.corporateMembership.generalSettings.disable_join_renew && (
						<>
							<br />
						</>
					)}
			{!loader && !companyCreated && (
				<div>
					<CreateCompany updateCompanyCreated={updateCompanyCreated} />
				</div>
			)}
			{!loader && companyCreated && props.corporateMembership.menuFetched && (
				<>
					{!props.isMultiple &&
						!props.corporateMembership.generalSettings.disable_join_renew && (
							<div>
								{props.isRegistrationSteps ? (
									<Button
										type="primary"
										size="large"
										icon={<RightOutlined />}
										className="gx-float-left gx-mb-2"
										onClick={handleSubmit}
									>
										{'Start'}
									</Button>
								) : null}
							</div>
						)}
					{!props.corporateMembership.generalSettings.disable_join_renew && (
						<>
							{' '}
							<br />
							<br />
						</>
					)}

					<div>
						{props.corporateMembership.menuFetched &&
							homeMenuItems.length > 0 &&
							homeMenuItems.map(homeMenuItem => {
								return (
									<div
										key={homeMenuItem.menuid}
										dangerouslySetInnerHTML={{
											__html: homeMenuItem.blurb,
										}}
										className={'gx-mt-3'}
									></div>
								);
							})}
					</div>
					<div>
						{!props.isMultiple &&
							!props.corporateMembership.generalSettings.disable_join_renew && (
								<div style={{ 'text-align': 'center' }}>
									{props.showPrevious ? (
										<Button
											type="primary"
											className="gx-float-left gr-mt-3"
											onClick={previousStep}
										>
											Previous
										</Button>
									) : null}
									{/* {props.isRegistrationSteps ? (
										<Button
											type="primary"
											size="large"
											icon={<RightOutlined />}
											className="gx-float-left gr-mt-3"
											onClick={handleSubmit}
										>
											{'Start'}
										</Button>
									) : null} */}
								</div>
							)}
					</div>
				</>
			)}
			{loader && (
				<Card className="card">
					<CircularProgress className="gx-profile-content" />
				</Card>
			)}
		</div>
	);
};

function mapStateToProps(state) {
	return {
		corporateMembership: state.corporateMembership,
		loginInfo: state.loginInfo,
		settings: state.settings,
		common: state.common,
	};
}

export default connect(mapStateToProps)(ExhibitorHome);

import { all, call, fork, put, takeLatest, select, takeEvery } from 'redux-saga/effects';

import {
	REQUEST_CONSUMPTION_PRODUCTS,
	REQUEST_CONSUMPTION_TAGS,
	REQUEST_CONSUMPTION_CATEGORIES,
	REQUEST_CONSUMPTION_PRODUCT_INFO,
	REQUEST_CONSUMPTION_BUNDLE_INFO,
	REQUEST_CONSUMPTION_PRODUCT_ANALYSIS,
	REQUEST_CONSUMPTION_FILES_ANALYSIS
} from 'constants/ActionTypes';
import { REQUEST_CONSUMPTION_CONFIG } from '../actions';

import {
	receiveProducts,
	receiveTags,
	receiveCategories,
	receiveProductInfo,
	receiveBundleInfo,
	loadingProducts,
	loadingTags,
	loadingCategories,
	receiveConfig,
	loadingConfig,
	storeProductAnalysisSuccess,
	storeFileAnalysisSuccess
} from 'appRedux/actions/Consumption';

import { fetchError } from 'appRedux/actions/Common';

import axios from 'axios';
import auth from 'loginAuth/loginAuth';

const api = 'https://masterapp.econference.io/masterapp_summer2012/apiv2/index.cfm';
const nodeApi = 'https://bm6uvz69il.execute-api.us-east-1.amazonaws.com/dev/api';

const getConfig = ({ appDir }) =>
	axios.get(
		`${nodeApi}?appdir=${appDir}&module=consumption&component=consumption&function=config`,
	);

const getProducts = async () =>
	await axios.get(`${api}?&module=products&component=user_products&function=get_user_products`, {
		headers: { Authorization: JSON.parse(auth.getCookie('jwt')) },
	});

const getCategories = async () =>
	await axios.get(`${api}?&module=products&component=products&function=get_categories`, {
		headers: { Authorization: JSON.parse(auth.getCookie('jwt')) },
	});

const getTags = async ({ source }) =>
	await axios.get(`${api}?source=${source}&module=client&component=tags&function=see_tags`, {
		headers: { Authorization: JSON.parse(auth.getCookie('jwt')) },
	});

const getProductInfo = ({ uuid }) =>
	axios.get(
		`${api}?module=products&component=products&function=get_product_info&product_uuid=${uuid}`,
		{
			headers: { Authorization: JSON.parse(auth.getCookie('jwt')) },
		},
	);

const getBundleInfo = async ({ uuid }) =>
	await axios.get(
		`${api}?module=products&component=products&function=get_bundle_details&product_uuid=${uuid}`,
		{ headers: { Authorization: JSON.parse(auth.getCookie('jwt')) } },
	);

const authenticateUser = async ({ appdir, uuid, ssoToken }) => {
	if (!uuid || !ssoToken) return;
	const { data } = await axios.post(
		`${api}?appdir=${appdir}&module=login&component=login&function=authenticate_user`,
		{
			uuid,
			sso_token: ssoToken,
		},
	);
	auth.setCookie('contact_uuid', JSON.stringify(uuid ?? ''), 7);
	auth.setCookie('ssotoken', JSON.stringify(ssoToken ?? ''), 7);
	auth.setCookie('jwt', JSON.stringify(data.jwt ?? ''), 7);
};

const storeProductAnalysis = async ({appdir, contactUid, productId}) => {
	await axios({
		method: "post",
		url: `${nodeApi}?appdir=${appdir}&module=ecommerce&component=reports&function=consumption`,
		data: { 
			contact_uuid: contactUid,
			product_id: productId,
			log_object: {
				event: "view_product"
			}
		},
		headers: { "Content-Type": "application/json" },
	});
}

const storeFileAnalysis = async ({ appdir, contactUid, productId, title, fileSize }) => {
	await axios({
		method: "post",
		url: `${nodeApi}?appdir=${appdir}&module=ecommerce&component=reports&function=consumption`,
		data: { 
			contact_uuid: contactUid,
			product_id: productId,
			log_object: {
				event: "request_file",
				target: title,
				value: fileSize
			}
		},
		headers: { "Content-Type": "application/json" },
	});
}

function* getConfigSaga() {
	try {
		const status = yield select(state => state.consumption.configStatus);
		if (status === 'loading' || status === 'success') return;
		yield put(loadingConfig());

		const appDir = window.location.hostname.substr(0, window.location.hostname.indexOf('.'));
		const { data } = yield call(getConfig, { appDir });

		yield put(receiveConfig(data));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getConfigWatcher() {
	yield takeEvery(REQUEST_CONSUMPTION_CONFIG, getConfigSaga);
}

function* getProductsSaga({ payload }) {
	try {
		const status = yield select(state => state.consumption.productsStatus);
		if (status === 'loading' || status === 'success') return;
		yield put(loadingProducts());

		const query = yield select(state => state.router.location.query);
		const appdir = window.location.hostname.substr(0, window.location.hostname.indexOf('.'));

		yield call(authenticateUser, {
			appdir,
			uuid: query.uuid,
			ssoToken: query.token,
		});

		const results = yield call(getProducts, payload);

		yield put(receiveProducts(results));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getProductsWatcher() {
	yield takeEvery(REQUEST_CONSUMPTION_PRODUCTS, getProductsSaga);
}

function* getTagsSaga({ payload }) {
	try {
		const status = yield select(state => state.consumption.tagsStatus);
		if (status === 'loading' || status === 'success') return;

		yield put(loadingTags());

		const results = yield call(getTags, payload);
		yield put(receiveTags(results));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getTagsWatcher() {
	yield takeEvery(REQUEST_CONSUMPTION_TAGS, getTagsSaga);
}

function* getCategoriesSaga({ payload }) {
	try {
		const status = yield select(state => state.consumption.categoriesStatus);
		if (status === 'loading' || status === 'success') return;
		yield put(loadingCategories());

		const results = yield call(getCategories, payload);
		yield put(receiveCategories(results));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getCategoriesWatcher() {
	yield takeEvery(REQUEST_CONSUMPTION_CATEGORIES, getCategoriesSaga);
}

function* getProductInfoSaga({ payload }) {
	try {
		const results = yield call(getProductInfo, payload);
		yield put(receiveProductInfo({ results, uuid: payload.uuid }));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getProductInfoWatcher() {
	yield takeLatest(REQUEST_CONSUMPTION_PRODUCT_INFO, getProductInfoSaga);
}

function* getBundleInfoSaga({ payload }) {
	try {
		const results = yield call(getBundleInfo, payload);
		yield put(receiveBundleInfo({ results, uuid: payload.uuid }));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getBundleInfoWatcher() {
	yield takeLatest(REQUEST_CONSUMPTION_BUNDLE_INFO, getBundleInfoSaga);
}

function* storeProductAnalysisSaga({ payload }) {
	try {
		const results = yield call(storeProductAnalysis, payload);
		yield put(storeProductAnalysisSuccess({ results }));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* storeProductAnalysisWatcher() {
	yield takeLatest(REQUEST_CONSUMPTION_PRODUCT_ANALYSIS, storeProductAnalysisSaga);
}

function* storeFileAnalysisSaga({ payload }) {
	try {
		const results = yield call(storeFileAnalysis, payload);
		yield put(storeFileAnalysisSuccess({ results }));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* storeFileAnalysisWatcher() {
	yield takeLatest(REQUEST_CONSUMPTION_FILES_ANALYSIS, storeFileAnalysisSaga);
}

export default function* () {
	yield all([
		fork(getProductsWatcher),
		fork(getTagsWatcher),
		fork(getCategoriesWatcher),
		fork(getProductInfoWatcher),
		fork(getBundleInfoWatcher),
		fork(getConfigWatcher),
		fork(storeProductAnalysisWatcher),
		fork(storeFileAnalysisWatcher),
	]);
}

import React, { useEffect, useState } from 'react';
import { Button, Input, message, Form, Card, Modal, Checkbox, notification } from 'antd';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import _isEmpty from 'lodash/isEmpty';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import {
	requestAuthUser,
	requestAuthenticateUser,
	requestValidateEmail,
	requestClearValidateEmail,
	requestCheckoutCreateProfile,
	requestMagicLogin,
} from 'appRedux/actions/Auth';

import {
	requestInitialAPI,
	requestLoginFormData,
	requestProfileData,
	requestRetrieveAccount,
} from 'appRedux/actions/LoginPageInfo';

import { requestPaymentSettings } from 'appRedux/actions/Ecommerce';

import CircularProgress from 'components/CircularProgress/index';
import auth from '../../../loginAuth/loginAuth';
import { Col, Row, Alert } from 'antd';

const CheckoutSignIn = props => {
	const [localLoader, setlocalLoader] = useState(false);
	const [loginInfo, setLoginInfo] = useState('');
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const [submitLoad, setSubmitLoad] = useState(false);
	const [validateEmail, setValidateEmail] = useState(false);
	const [showPasswordInput, setShowPasswordInput] = useState(false);
	const [myPassword, setMyPassword] = useState('');
	const [myConfirmPassword, setMyConfirmPassword] = useState('');

	const [checkboxToggle, setCheckboxToggle] = useState(false);

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');

	const [isModalVisible, setIsModalVisible] = useState(false);

	const [invalidEmailAlert, setInvalidEmailAlert] = useState(false);
	const [invalidLoginAlert, setInvalidLoginAlert] = useState(false);
	const [resetPasswordAlert, setResetPasswordAlert] = useState(false);

	const [email, setEmail] = useState('');

	const { alertMessage, showMessage, loader } = useSelector(({ auth }) => auth);

	const history = useHistory();
	const { moduleUrlId } = useParams();

	const languageObject = useSelector(
		state => state.ecommerce.attendeeSettings?.pagelanguagestruct,
	);

	const info = props.initialData;
	const appdir = props.initialData.appdir;

	useEffect(() => {
		const key = 'UserInfo-' + appdir + '-' + moduleUrlId;
		/*
    have to use proxyurl because blocked by CORS policy. "Access-Control-Allow-Origin" header contains multiple values
    have to figure out on the backend later
    https://stackoverflow.com/questions/43871637/no-access-control-allow-origin-header-is-present-on-the-requested-resource-whe
    */

		let jwtToken = auth.getCookie('jwt');
		if (typeof jwtToken !== undefined && jwtToken != '') {
			dispatch(
				requestAuthUser({
					ssotoken: JSON.parse(auth.getCookie('ssotoken')),
					contact_uuid: JSON.parse(auth.getCookie('contact_uuid')),
					jwt: JSON.parse(auth.getCookie('jwt')),
				}),
			);
			//source_hex, jwt, sso_token, contact_uuid
			// const previousLocation = JSON.parse(auth.getCookie('previous_location'));
		} else {
			dispatch(
				requestLoginFormData({
					appdir: appdir,
				}),
			);
		}
		document.title = 'X-CD Conference 2020';
	}, []);

	useEffect(() => {
		// if the user has an email and a password

		if (props.auth.emailExists === 1 && props.auth.password_reset === 0) {
			setShowPasswordInput(true);
			setValidateEmail(true);
		} else if (props.auth.emailExists === 1 && props.auth.password_reset === 1) {
			// if the user has an email and but no password (i.e. someone created an account for them)
			setResetPasswordAlert(true);
		} else if (props.auth.emailExists === 0 && props.initialData.firstName === '') {
			// the email doesn't exist
			setInvalidEmailAlert(true);
		}
		//This is being used in FreeCheckout component
		if (props.requestMaintainSignInStep) {
			props.requestMaintainSignInStep(true);
		}
	}, [props.auth.emailExists]);

	//watches change in jwt token or alert message after submit login is clicked

	useEffect(() => {
		if (props.auth.alertMessage === 'Email or Password was incorrect.') {
			setInvalidLoginAlert(true);
			setSubmitLoad(false);
		} else {
			const jwtToken = auth.getCookie('jwt');

			if (!jwtToken && props.auth.emailExists !== '') {
				requestProfile(info.source_hex, props.auth.contact_uuid);
			}
		}

		if (props.auth.emailExists !== '') {
			setSubmitLoad(false);
		}
	}, [props.auth]);

	useEffect(() => {
		dispatch(
			requestPaymentSettings({
				moduleUrlId: moduleUrlId,
			}),
		);
	}, []);

	const sendMagicLink = () => {
		const email = props.auth.retrieveAccount[0].email;
		const cartUuid = props.ecommerce.cartUuid;

		dispatch(requestMagicLogin({ appdir, moduleUrlId, cartUuid, email }));
	};

	const onPasswordChange = e => {
		setMyPassword(e.target.value);
	};

	const onConfirmPasswordChange = e => {
		setMyConfirmPassword(e.target.value);
	};

	const submitLogin = values => {
		if (validateEmail === true) {
			setSubmitLoad(true);
			let formData = new FormData();
			formData.append('email', values.email);
			formData.append('password', values.password);
			formData.append('react_login', 1);
			dispatch(
				requestAuthenticateUser({
					appdir: appdir,
					formData: formData,
				}),
			);
		}
	};
	const checkValidateEmail = () => {
		let formData = new FormData();
		formData.append('login_request', 1);
		formData.append('react_login', 1);

		dispatch(requestValidateEmail({ appdir: appdir, email: email, formData: formData }));
	};

	const onEmailChange = e => {
		setEmail(e.target.value);
	};

	const onFirstNameChange = e => {
		setFirstName(e.target.value);
	};

	const onLastNameChange = e => {
		setLastName(e.target.value);
	};

	const requestProfile = (source_hex, contact_uuid) => {
		setSubmitLoad(true);

		dispatch(requestProfileData({ source_hex: source_hex, contact_uuid: contact_uuid }));
	};

	const onInvalidEmailAlertClose = () => {
		setInvalidEmailAlert(false);
		dispatch(requestClearValidateEmail());
	};

	const onInvalidLoginAlertClose = () => {
		setInvalidLoginAlert(false);
	};

	const onResetPasswordAlertClose = () => {
		setResetPasswordAlert(false);
	};

	const onCheckBoxChange = () => {
		setCheckboxToggle(!checkboxToggle);
	};

	const passwordNotMatchNotification = () => {
		notification['error']({
			message: 'Your password does not match. Please Try again.',
		});
	};

	const resetPasswordClick = () => {
		dispatch(
			requestRetrieveAccount({
				fullname: props.auth.retrieveAccount[0].fullname,
				email: props.auth.retrieveAccount[0].email,
				company: props.auth.retrieveAccount[0].company,
				phonearray: props.auth.retrieveAccount[0].phonearray,
				UUID: props.auth.retrieveAccount[0].UUID,
				primary_color: props.initialData.login_form.primary_color,
			}),
		);
		history.push('/retrieveaccount');
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const showModal = () => {
		setIsModalVisible(true);
	};

	const untoggleShowPassword = () => {
		setInvalidLoginAlert(false);
		setShowPasswordInput(false);
		setValidateEmail(false);
		setResetPasswordAlert(false);
		setSubmitLoad(false);
		dispatch(requestClearValidateEmail());
	};

	const createProfile = () => {
		if (myPassword !== myConfirmPassword) {
			passwordNotMatchNotification('error');
			setSubmitLoad(false);
		} else {
			let formData = new FormData();

			formData.append('firstname', firstName);
			formData.append('lastname', lastName);
			formData.append('email', email);
			formData.append('password', myPassword);

			if (checkboxToggle === true) {
				formData.append('contact_consent', 1);
			} else {
				formData.append('contact_consent', 0);
			}

			dispatch(
				requestCheckoutCreateProfile({
					appdir: appdir,
					moduleUrlId: moduleUrlId,
					formData: formData,
				}),
			);
		}
	};

	const loginBox = login_form => {
		return (
			<div className="gx-app-login-container" style={{ textAlign: 'center' }}>
				<Card className="gx-card">
					{login_form && (
						<div style={{ textAlign: 'center' }}>
							<h2>
								{showPasswordInput
									? languageObject?.Checkout_LoginPasswordHeader ??
									  'Enter your Credentials'
									: props.auth.emailExists === 0
									? languageObject?.Checkout_LoginPageEmailNotFoundHeader ??
									  'Account not Found'
									: languageObject?.Checkout_LoginEmailHeader ??
									  'PLEASE SIGN IN TO CHECKOUT'}
							</h2>
						</div>
					)}
					<br />
					<h2
						style={{
							fontFamily: 'NoirPro, sans-serif',
							fontSize: '14px',
							color: '#535353',
							fontWeight: '600',
							textTransform: 'uppercase',
						}}
					>
						<div
							dangerouslySetInnerHTML={{
								__html: showPasswordInput
									? languageObject?.Checkout_LoginPasswordHeaderHelp ??
									  '<p>Enter your password</p>'
									: props.auth.emailExists === 0
									? languageObject?.Checkout_LoginPageEmailNotFoundHeaderHelp ??
									  '<p>Create your account</p>'
									: languageObject?.Checkout_LoginEmailHeaderHelp ??
									  (login_form
											? '<p>HAVE AN ACCOUNT? ' + login_form.help_text + '</p>'
											: '<p>Have an account? Please enter your credentials.</p>'),
							}}
						></div>
						{/* {languageObject?.Checkout_LoginEmailHeaderHelp ??
							(login_form
								? 'HAVE AN ACCOUNT? ' + login_form.help_text
								: 'Have an account? Please enter your credentials.')} */}
					</h2>
					<Form
						id="myForm"
						initialValues={{
							remember: loginInfo ? JSON.parse(loginInfo).email : false,
						}}
						name="basic"
						layout="vertical"
						onFinish={submitLogin}
						className="gx-signin-form gx-form-row0"
					>
						{showPasswordInput && (
							<div style={{ textAlign: 'left' }}>
								<Button
									shape={'round'}
									className="button-primary gx-font-weight-semi-bold shadow-2 gx-mb-3"
									onClick={untoggleShowPassword}
								>
									Back
								</Button>
							</div>
						)}
						<Form.Item
							initialValue={loginInfo ? JSON.parse(loginInfo).email : ''}
							validateTrigger="onSubmit"
							rules={[
								{
									type: info.login_form
										? info.login_form.email_input_type
										: 'email',
									message: info.login_form
										? info.login_form.invalid_email_label
										: 'The input is not valid E-mail!',
								},
								{
									required: true,
									message: info.login_form
										? info.login_form.no_email_label
										: 'Please enter your email',
								},
							]}
							name="email"
						>
							<Input
								placeholder="Email"
								onChange={e => {
									onEmailChange(e);
								}}
								value={email}
							/>
						</Form.Item>

						{info.login_form?.password && showPasswordInput && (
							<Form.Item
								initialValue={loginInfo ? JSON.parse(loginInfo).password : ''}
								rules={[
									{
										required: true,
										message: info.login_form
											? info.login_form.no_password_label
											: 'Please enter your password!',
									},
								]}
								name="password"
							>
								<Input
									type="password"
									placeholder={
										info.login_form
											? info.login_form.password_label
											: 'Password'
									}
								/>
							</Form.Item>
						)}
						{/* <Form.Item  name="remember" valuePropName='checked'>
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item> */}
						{/* {invalidEmailAlert && (
							<Alert
								message={
									info
										? info.login_form.email_not_found_msg
										: 'There is no account associated with this email.'
								}
								type="error"
								closable="true"
								onClose={onInvalidEmailAlertClose}
							/>
						)} */}

						{validateEmail && (
							<Form.Item>
								<Button
									form="myForm"
									type="default"
									key="submit"
									htmlType="submit"
									loading={submitLoad}
									shape={'round'}
									className="button-primary gx-font-weight-semi-bold shadow-2"
								>
									{info.login_form?.login_button_label ?? 'Login'}
								</Button>
								<h2>{props.loginInfo?.initialData}</h2>
							</Form.Item>
						)}
						{invalidLoginAlert && (
							<div>
								<Alert
									message={
										info
											? info.login_form.incorrect_password
											: 'Incorrect login. Please enter a valid login and password.'
									}
									type="error"
									closable="true"
									onClose={onInvalidLoginAlertClose}
								/>
								<p>{languageObject.Checkout_send_magic_login_link_help_text}</p>
								<Button
									shape={'round'}
									className="button-primary gx-font-weight-semi-bold shadow-2 gx-mb-3"
									onClick={sendMagicLink}
								>
									{languageObject.Checkout_send_magic_login_link_button}
								</Button>
							</div>
						)}

						{props.auth.magicLinkSentMsg === 'Email sent.' && (
							<p>
								{languageObject.Checkout_magic_login_link_sent_msg} {email}
							</p>
						)}

						{props.auth.magicLinkSentMsg === 'Cart not found.' && (
							<p>Please add an item to your cart before using the magic link.</p>
						)}
						{resetPasswordAlert && (
							<Alert
								message={
									info
										? info.login_form.incomplete_password
										: 'A link has been sent to your email to reset your password.'
								}
								type="error"
								closable="true"
								onClose={onResetPasswordAlertClose}
							/>
						)}

						{props.auth.alertMessage === 'Email or Password was incorrect.' &&
							resetPasswordAlert && (
								<Button
									shape={'round'}
									className="button-primary gx-font-weight-semi-bold shadow-2"
									onClick={resetPasswordClick}
								>
									Reset Password
								</Button>
							)}

						{!validateEmail && (
							<Form.Item>
								<Button
									form="myForm"
									type="default"
									onClick={checkValidateEmail}
									loading={submitLoad}
									shape={'round'}
									className="button-primary gx-font-weight-semi-bold shadow-2 gx-mb-3"
								>
									Continue
								</Button>
								{props.loginInfo && <h2>{props.loginInfo.initialData}</h2>}
							</Form.Item>
						)}
					</Form>

					{props.auth.emailExists === 0 && (
						<div>
							<h2
								style={{
									fontFamily: 'NoirPro, sans-serif',
									fontSize: '14px',
									color: '#535353',
									fontWeight: '600',
									textTransform: 'uppercase',
								}}
							>
								{languageObject?.Checkout_LoginPageCreateProfileHeader ??
									'NEW USER? PLEASE CREATE A PROFILE'}
							</h2>
							<br />
							<Form
								id="profileInfo"
								name="basic"
								layout="vertical"
								// onFinish={submitLogin}
								className="gx-signin-form gx-form-row0"
							>
								<div style={{ display: 'flex' }}>
									<Checkbox onChange={onCheckBoxChange}></Checkbox>
									<div
										style={{ 'margin-left': '2%' }}
										dangerouslySetInnerHTML={{
											__html:
												props.initialData.consent_object.ConsentRowLabel,
										}}
									></div>
								</div>
								<Link onClick={showModal}>
									{props.initialData.consent_object.HyperlinkLabel}
								</Link>
								<Modal
									title={props.initialData.consent_object.DescriptionTitle}
									visible={isModalVisible}
									cancelButtonProps={{ style: { display: 'none' } }}
									okButtonProps={{ style: { display: 'none' } }}
									onCancel={handleCancel}
								>
									<div
										dangerouslySetInnerHTML={{
											__html:
												props.initialData.consent_object
													.ConsentDescriptiveText,
										}}
									></div>
								</Modal>
								<br />

								<Form.Item
									rules={[
										{
											required: true,
										},
									]}
									name="First Name"
								>
									<Input
										placeholder={'First Name'}
										onChange={e => {
											onFirstNameChange(e);
										}}
									/>
								</Form.Item>
								<Form.Item
									rules={[
										{
											required: true,
										},
									]}
									name="Last Name"
								>
									<Input
										placeholder={'Last Name'}
										onChange={e => {
											onLastNameChange(e);
										}}
									/>
								</Form.Item>
								<Form.Item
									initialValue={email}
									rules={[
										{
											required: true,
										},
									]}
									name="email"
								>
									<Input
										placeholder={'Email'}
										onChange={e => {
											onEmailChange(e);
										}}
									/>
								</Form.Item>

								<Form.Item
									initialValue={''}
									validateTrigger="onBlur"
									rules={[
										{
											required: true,
											message: 'Please enter your password',
										},
										{
											min: 5,
											message: 'Password must be minimum 5 characters',
										},
									]}
									name="password"
								>
									<Input.Password
										placeholder="Password"
										onChange={onPasswordChange}
										value={myPassword}
										iconRender={visible =>
											visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
										}
									/>
								</Form.Item>
								<Form.Item
									initialValue={''}
									validateTrigger="onBlur"
									rules={[
										{
											required: true,
											message: 'Please enter your password',
										},
										{
											min: 5,
											message: 'Password must be minimum 5 characters',
										},
									]}
									name="confirm_password"
								>
									<Input.Password
										placeholder="Confirm Password"
										onChange={onConfirmPasswordChange}
										value={myConfirmPassword}
										iconRender={visible =>
											visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
										}
									/>
								</Form.Item>
								<Button
									form="myForm"
									type="default"
									// key="submit"
									// htmlType="submit"
									onClick={createProfile}
									// loading={submitLoad}
									shape={'round'}
									className="button-primary gx-font-weight-semi-bold shadow-2"
								>
									Submit
								</Button>
							</Form>
						</div>
					)}
				</Card>
			</div>
		);
	};
	const showLoginScreen = () => {
		return (
			<Row style={{ height: '100%' }}>
				{info && !_isEmpty(info.login_form) && info.ads && info.ads.length > 0 && (
					<Col xl={24} lg={24} md={24} sm={24} xs={24}>
						{loginBox(info.login_form)}
					</Col>
				)}
				{info &&
					!_isEmpty(info.login_form) &&
					((info.ads && info.ads.length == 0) || !info.ads) && (
						<Col xl={24} lg={24} md={24} sm={24} xs={24}>
							{loginBox(info.login_form)}
						</Col>
					)}
			</Row>
		);
	};
	return (
		<div className="gx-app-login-wrap">
			{loader || localLoader || _isEmpty(info.login_form) ? (
				<div className="gx-loader-view">
					<CircularProgress className="gx-profile-content" />
				</div>
			) : (
				showLoginScreen()
			)}
			{showMessage ? message.error(alertMessage.toString()) : null}
		</div>
	);
};

const mapStateToProps = state => {
	const myState = { ...state };

	return {
		auth: myState.auth,
		initialData: myState.loginInfo,
		ecommerce: myState.ecommerce,
	};
};

const mapDispatchToProps = dispatch => ({
	requestInitialAPI: () => dispatch(requestInitialAPI()),
});
// https://stackoverflow.com/questions/41754489/when-would-bindactioncreators-be-used-in-react-redux

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSignIn);

import React from 'react'
import { useSelector } from 'react-redux'
import { Avatar, Typography, Tag, Col, Row } from 'antd';
import moment from "moment"
import { handleMemberRenewal } from '@components/Membership/helpers/generalHelpers';

const CorporateRenderMemberInfo = (props) => {
    const corporateMembership = useSelector(state => state.corporateMembership)
    const { reviewID, contact_picture, uuid, firstName, lastName, email, showContactID, expirationDate, groupName, inGoodStanding } = props.record
    return (
		<div key={uuid}>
			<Row wrap={false}>
				<Col flex="50px">
					<Avatar
						className="primary-background gx-pointer gx-mr-2 gx-ml-2"
						src={contact_picture ? contact_picture : ''}
						key={uuid}
						size={50}
					>
						{firstName[0] ? firstName[0].toLowerCase() : firstName.toLowerCase()}
					</Avatar>
				</Col>
				<Col flex="auto">
					<div className="gx-ml-2 gx-font-weight-normal" style={{ fontSize: '14px' }}>
						<Typography>
							{lastName}, {firstName}
						</Typography>
						<Typography>{email}</Typography>
						<div>
							{handleMemberRenewal(props, corporateMembership).isrenewalMember ? (
								<div>
									<Tag color="warning" className="gx-mt-1">{`Expiry: ${moment(
										expirationDate,
									).format("'MMM DD, YYYY'")}`}</Tag>
								</div>
							) : handleMemberRenewal(props, corporateMembership)
									.isPastExpiryMember ? (
								<div>
									<Tag color="red" className="gx-mt-1">{`Expiry: ${moment(
										expirationDate,
									).format("'MMM DD, YYYY'")}`}</Tag>
								</div>
							) : null}
						</div>
						{showContactID ? <Typography>Contact ID: {reviewID}</Typography> : null}
						{inGoodStanding ? (
							<Typography>
								Expiry Date: {moment(expirationDate).format('MMM DD, YYYY')}
							</Typography>
						) : null}
					</div>
				</Col>
			</Row>
		</div>
	);
}

export default CorporateRenderMemberInfo;

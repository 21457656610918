import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useBundleInfo } from '../hooks/useBundleInfo';
import { useTags } from '../hooks/useTags';
import { useShoppingCart } from '../hooks/useShoppingCart';
import { useContentType } from '../hooks/useContentType';
import { useProductFilter } from '../hooks/useProductFilter';
import { getCheckoutRoute, getCheckoutPublicRoute } from '../helpers';

import BundleProductRender from './BundleProductRender';
import BundleTextRender from './BundleTextRender';
import StoreHeader from '../components/StoreHeader';
import BundleChildRender from './BundleChildRender';

import { Card, Tag, Button, Skeleton, Image, Spin, Radio, Divider } from 'antd';

import { ReactComponent as CartPlus } from '../../../../assets/icons/cartPlus.svg';
import { ReactComponent as Remove } from '../../../../assets/icons/remove.svg';
import { ReactComponent as PDF } from '../../../../assets/icons/pdf.svg';
import { ReactComponent as Book } from '../../../../assets/icons/book.svg';
import { ReactComponent as Left } from '../../../../assets/icons/left.svg';
import { ReactComponent as CartCheck } from '../../../../assets/icons/cartCheck.svg';

const BundlePage = () => {
	const [selectedBundle, setSelectedBundle] = useState({});
	const [filterByPrice] = useProductFilter();
	const history = useHistory();
	const { moduleUrlId, bundleId } = useParams();
	const publicEmbed = useSelector(state => state.settings.publicEmbed);
	const id = parseInt(bundleId);
	const showTags = !useSelector(
		state =>
			state.ecommerce.attendeeSettings?.attendeesiteadmin?.generalconfigobject?.hide_all_tags,
	);

	const [tagsLoading, tagsMap, getDisplayTagIds] = useTags();
	const [addToCart, cartCount, cart, removeFromCart] = useShoppingCart();
	const [
		bundle,
		children,
		bundlePrice,
		childrenPrices,
		totalPrice,
		bundleSavings,
		savingsPercentage,
		pricesLoading
	] = useBundleInfo(id);

	const [typeLoading, CONTENT, getContentTypes, getFascadeContentTypes] = useContentType();

	useEffect(() => {
		if (!pricesLoading) {
			filterByPrice();
		}
	}, [pricesLoading]);

	const handleSelectBundle = (selectedData) => {
		setSelectedBundle(selectedData);
	};

	const checkout = () => {
		history.push(
			publicEmbed ? getCheckoutPublicRoute(moduleUrlId) : getCheckoutRoute(moduleUrlId),
		);
	};

	const addedToCart = useMemo(
		() => cart.some(invoice => invoice.field_id === bundlePrice?.fieldid),
		[cart],
	);
	const loading = !bundle || tagsLoading || typeLoading || pricesLoading;

	const fascadeContentTypes = bundle ? getFascadeContentTypes(bundle) : new Set();
	const contentTypes = bundle ? getContentTypes(bundle) : new Set();

	if (loading)
		return (
			<main className={publicEmbed ? 'gx-m-4' : 'container'}>
				<StoreHeader />
				<main className="gx-d-lg-flex">
					<section className="gx-pr-lg-5" style={{ flexBasis: '67%' }}>
						<Card className="card">
							<Image
								className="gx-mb-3 card-image"
								alt="placeholder"
								src={require('../../../../assets/images/placeholder-image.png')}
							/>
							<Skeleton active className="gx-mb-3"></Skeleton>
							<Skeleton.Button
								className="gx-d-block"
								style={{ width: '100%' }}
								active
								size="large"
							/>
						</Card>
					</section>
					<section style={{ flexBasis: '33%' }}>
						<Skeleton active title={{ width: '50%' }} paragraph={{ rows: 0 }} />
						<hr />
						<Card className="card gx-mb-3">
							<Skeleton active></Skeleton>
						</Card>
					</section>
				</main>
				<main className="gx-d-lg-flex">
					<section style={{ flexBasis: '100%' }}>
						<Skeleton active title={{ width: '50%' }} paragraph={{ rows: 0 }} />
						<hr />
						<Card className="card gx-mb-3">
							<Skeleton active></Skeleton>
						</Card>
						<Card className="card gx-mb-3">
							<Skeleton active></Skeleton>
						</Card>
						<Card className="card gx-mb-3">
							<Skeleton active></Skeleton>
						</Card>
					</section>
				</main>
			</main>
		);

	return (
		<main className="container">
			<div className="gx-mb-4 gx-d-flex">
				<Link
					to={
						publicEmbed
							? `/public/ecommerce/${moduleUrlId}/selection${history.location.search}`
							: `/main/ecommerce/${moduleUrlId}/selection`
					}
				>
					<div className="gx-d-flex gx-align-items-center">
						<Left style={{ width: '1em' }} />
						<span className="gx-ml-1 gx-text-black">Back</span>
					</div>
				</Link>
				<Button
					disabled={parseInt(cartCount) > 0 ? false : true}
					onClick={checkout}
					className="gx-ml-auto button-primary"
					shape="round"
				>
					<div className="gx-d-flex gx-justify-content-center gx-align-items-center">
						<CartCheck className="gx-mr-2" style={{ width: '1.2em' }} />
						<p className="gx-mb-0">My Shopping Cart ({cartCount})</p>
					</div>
				</Button>
			</div>

			<main className="gx-d-lg-flex">
				<section style={{ flexBasis: '100%' }}>
					<Card className="gx-position-sticky card shadow-2" style={{ top: '2em' }}>
						{/* product category */}
						<div className="gx-d-flex gx-justify-content-between">
							<div className='gx-pt-4'>
								<span className="category-ribbon">{bundle.category}</span>

								{/* {bundle?.image
										? <Image
											className="gx-mb-3 gx-mt-3 card-image"
											alt="placeholder"
											src={
												bundle?.image ||
												require('../../../../assets/images/product-placeholder.png')
											}
											// style={{ maxHeight: '8em' }}
											preview={false}
										/>
										: null
									} */}

								{/* tags */}
								{showTags &&
									getDisplayTagIds(bundle).map(
										tagId =>
											tagsMap[tagId] && (
												<Tag key={tagId} className="tag">
													{tagsMap[tagId]}
												</Tag>
											),
									)}

								{/* title */}
								{/* <h2 className="gx-mb-2 gx-font-weight-semi-bold">{bundle.title}</h2> */}
								<h1>{bundle.title}</h1>
								{/* description */}
								{bundle.description && (<p
									dangerouslySetInnerHTML={{
										__html: bundle.description,
									}}
									className="gx-mb-0"
								/>)}

								{bundle.sourceId && fascadeContentTypes.size >= 2 && (
									<>
										<hr />
										<div className="gx-d-flex gx-align-items-center">
											<h4 className="gx-mr-3 gx-mb-0 fg-grey">Format:</h4>
											<Radio.Group className="gx-d-flex gx-align-items-center">
												{fascadeContentTypes.has(CONTENT.DIGITAL) && (
													<Radio.Button
														onClick={() =>
															contentTypes.has(CONTENT.PRINT) &&
															history.push(`./${bundle.sourceId}`)
														}
														value="digital"
														className="radio-button-left gx-mb-0"
													>
														<div className="gx-d-flex gx-align-items-center">
															<PDF
																className="gx-mr-2"
																style={{ width: '1.2em' }}
															/>
															<p className="gx-mb-0">Digital</p>
														</div>
													</Radio.Button>
												)}
												{fascadeContentTypes.has(CONTENT.PRINT) && (
													<Radio.Button
														onClick={() =>
															contentTypes.has(CONTENT.DIGITAL) &&
															history.push(`./${bundle.sourceId}`)
														}
														value="print"
														className="radio-button-right gx-mb-0"
													>
														<div className="gx-d-flex gx-align-items-center">
															<Book
																className="gx-mr-2"
																style={{ width: '1.2em' }}
															/>
															<p className="gx-mb-0">Print</p>
														</div>
													</Radio.Button>
												)}
											</Radio.Group>
										</div>
									</>
								)}
							</div>
							<div className='gx-d-flex gx-flex-column gx-align-items-center gx-ml-5'>
								<div>
									{bundlePrice?.price_collection?.primary ? (
										<div className="gx-d-flex gx-align-items-center">
											<h4 className="fg-grey gx-mr-2 gx-mb-0">Price:</h4>
											<h2 className="gx-mr-2 gx-mb-0">
												{bundlePrice.price_collection.primary.price === 0
													? 'FREE'
													: `$${bundlePrice.price_collection.primary.price}`}
											</h2>
											<div className="product-price-details">
												{bundlePrice?.price_collection?.teaser && (
													<Tag className="teaser-tag gx-mb-0 gx-mr-2">
														{bundlePrice?.price_collection?.teaser?.price === 0
															? 'FREE'
															: `$${bundlePrice?.price_collection?.teaser?.price}`}{' '}
														for members
													</Tag>
												)}
											</div>
										</div>
									) : (
										<Spin className="gx-ml-1" size="small" />
									)}
								</div>
								<div className='gx-mt-3'>
									<Button
										onClick={() =>
											addedToCart
												? removeFromCart(bundlePrice)
												: addToCart(bundlePrice)
										}
										className="button-primary"
										size="large"
										shape="round"
										style={{ height: 'auto' }}
									>
										<div className="gx-d-flex gx-justify-content-center gx-align-items-center gx-px-2 gx-py-1">
											{addedToCart ? (
												<Remove className="gx-mr-2" style={{ width: '1em' }} />
											) : (
												<CartPlus className="gx-mr-2" style={{ width: '1.2em' }} />
											)}

											{bundlePrice?.price_collection && (
												<h4 className="gx-mb-0 gx-text-white">
													{addedToCart ? 'Remove from Cart' : `Add to Cart`}
												</h4>
											)}
										</div>
									</Button>
								</div>
							</div>
						</div>
					</Card>
				</section>
			</main>
			<main>
				<section>
					{contentTypes.has(CONTENT.PRINT) ? (
						<Card className="card shadow-1">
							<h1 className="gx-text-center gx-font-weight-bold">Contents</h1>
							<div className="gx-d-flex gx-align-items-center gx-mb-2">
								<hr className="divider gx-flex-1 gx-m-0" />
								<p className="gx-m-0 gx-mx-2  gx-text-grey">*</p>
								<hr className="divider gx-flex-1 gx-m-0" />
							</div>
							{children?.map(child => (
								<BundleTextRender key={child.id} product={child} />
							))}
						</Card>
					) : (
						selectedBundle?.title
							? <BundleChildRender product={selectedBundle} handleSelectBundle={handleSelectBundle} />
							: <>
								{children?.length
									? <>
										<div className="gx-d-flex gx-align-items-end">
											<h1 className="gx-mb-0 gx-mr-2">{bundle.title}</h1>
										</div>
										<hr
											style={{
												background: 'lightgray',
											}}
										/>
									</>
									: null
								}
								{children?.map(child => (
									<BundleProductRender key={child.id} product={child} handleSelectBundle={handleSelectBundle} />
								))}
							</>
					)}
				</section>
			</main>
		</main>
	);
};

export default BundlePage;

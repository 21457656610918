import {
	REQUEST_ECOMMERCE_STORE_PRODUCT_TAGS,
	RECEIVE_ECOMMERCE_STORE_PRODUCT_TAGS,
	REQUEST_ATTENDEE_SETTINGS,
	RECEIVE_ATTENDEE_SETTINGS,
	REQUEST_ECOMMERCE_STORE_PRODUCTS,
	RECEIVE_ECOMMERCE_STORE_PRODUCTS,
	REQUEST_ECOMMERCE_STORE_PRODUCT_PRICES,
	RECEIVE_ECOMMERCE_STORE_PRODUCT_PRICES,
	REQUEST_FILTER_PRODUCTS_BY_PRICE
} from 'constants/ActionTypes';

export const LOADING_ATTENDEE_SETTINGS = 'LOADING_ATTENDEE_SETTINGS';
export const LOADING_TAGS = 'LOADING_TAGS';

export const requestStoreProductTags = payload => ({
	type: REQUEST_ECOMMERCE_STORE_PRODUCT_TAGS,
	payload,
});

export const loadingTags = payload => ({
	type: LOADING_TAGS,
	payload,
});

export const receiveStoreProductTags = payload => ({
	type: RECEIVE_ECOMMERCE_STORE_PRODUCT_TAGS,
	payload,
});

export const requestAttendeeSettings = payload => ({
	type: REQUEST_ATTENDEE_SETTINGS,
	payload,
});

export const loadingAttendeeSettings = payload => ({
	type: LOADING_ATTENDEE_SETTINGS,
	payload,
});

export const receiveAttendeeSettings = payload => ({
	type: RECEIVE_ATTENDEE_SETTINGS,
	payload,
});

export const requestStoreProducts = payload => ({
	type: REQUEST_ECOMMERCE_STORE_PRODUCTS,
	payload,
});

export const receiveStoreProducts = payload => ({
	type: RECEIVE_ECOMMERCE_STORE_PRODUCTS,
	payload,
});

export const requestStoreProductPrices = payload => ({
	type: REQUEST_ECOMMERCE_STORE_PRODUCT_PRICES,
	payload,
});

export const receiveStoreProductPrices = payload => ({
	type: RECEIVE_ECOMMERCE_STORE_PRODUCT_PRICES,
	payload,
});

export const requestFilterProductByPrice = payload => ({
	type: REQUEST_FILTER_PRODUCTS_BY_PRICE,
	payload
});

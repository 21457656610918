import {
	HIDE_MESSAGE,
	INIT_URL,
	ON_HIDE_LOADER,
	ON_SHOW_LOADER,
	SHOW_MESSAGE,
	SIGNIN_USER_SUCCESS,
	SIGNOUT_USER_SUCCESS,
	SIGNUP_USER_SUCCESS,
	REQUEST_SIGNIN_TOKEN,
	SET_AUTH_USER,
	RECEIVE_AUTHENTICATE_USER,
	REQUEST_LOGOUT_USER,
	RECEIVE_RESET_PASSWORD,
	RECEIVE_VALIDATE_EMAIL,
	REQUEST_CLEAR_VALIDATE_EMAIL,
	REQUEST_ERASE_AUTH_DATA,
	REQUEST_DELETE_RESET_PASSWORD_MSG,
	EXHIBITOR_AUTH_SIGNEDUP,
	RECEIVE_CHECKOUT_CREATE_PROFILE,
	RECEIVE_MAGIC_LOGIN,
} from 'constants/ActionTypes';

const INIT_STATE = {
	loader: false,
	alertMessage: null,
	showMessage: false,
	initURL: '',
	authUser: false,
	ssotoken: '',
	jwt: '',
	contact_uuid: '',
	resetPasswordSuccess: '',
	consent_object: '',
	emailExists: '', // 1 means it exist, 0 means it doesn't exist
	password_reset: '', // 0 means they have password, 1 means they need to reset password
	retrieveAccount: '',
	magicLinkSentMsg: '',
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case REQUEST_SIGNIN_TOKEN: {
			return {
				...state,
				ssotoken: action.payload.ssotoken,
				jwt: action.payload.jwt,
				contact_uuid: action.payload.contact_uuid,
				authUser: true,
			};
		}

		case SIGNUP_USER_SUCCESS: {
			return {
				...state,
				loader: false,
				authUser: true,
			};
		}
		case SIGNIN_USER_SUCCESS: {
			return {
				...state,
				loader: false,
				authUser: true,
			};
		}
		case INIT_URL: {
			return {
				...state,
				initURL: action.payload,
			};
		}
		case SIGNOUT_USER_SUCCESS: {
			return {
				...state,
				authUser: null,
				initURL: '/',
				loader: false,
			};
		}

		case SHOW_MESSAGE: {
			return {
				...state,
				alertMessage: action.payload,
				showMessage: true,
				loader: false,
			};
		}
		case HIDE_MESSAGE: {
			return {
				...state,
				alertMessage: '',
				showMessage: false,
				loader: false,
			};
		}
		case ON_SHOW_LOADER: {
			return {
				...state,
				loader: true,
			};
		}
		case ON_HIDE_LOADER: {
			return {
				...state,
				loader: false,
			};
		}
		case SET_AUTH_USER: {
			return {
				...state,
				authUser: true,
				contact_uuid: action.payload.contact_uuid,
				jwt: action.payload.jwt,
				ssotoken: action.payload.ssotoken,
				alertMessage: action.payload.error_message,
				consent_object: action.payload.consent_object,
			};
		}
		case RECEIVE_AUTHENTICATE_USER: {
			return {
				...state,
				authUser: true,
				contact_uuid: action.payload.contact_uuid,
				jwt: action.payload.jwt,
				ssotoken: action.payload.ssotoken,
				alertMessage: action.payload.error_message,
				consent_object: action.payload.consent_object,
			};
		}
		case REQUEST_LOGOUT_USER: {
			return {
				...state,
				loader: false,
				alertMessage: '',
				showMessage: false,
				initURL: '',
				authUser: false,
				ssotoken: '',
				jwt: '',
				contact_uuid: '',
				resetPasswordSuccess: '',
			};
		}
		case RECEIVE_RESET_PASSWORD: {
			return {
				...state,
				authUser: action.payload.success,
				resetPasswordSuccess: action.payload.success,
			};
		}

		case RECEIVE_VALIDATE_EMAIL: {
			return {
				...state,
				emailExists: action.payload.emailExists,
				password_reset: action.payload.password_reset,
				retrieveAccount: action.payload.contactArray,
			};
		}

		case REQUEST_CLEAR_VALIDATE_EMAIL: {
			return {
				...state,
				emailExists: '',
				password_reset: '',
				alertMessage: null,
				magicLinkSentMsg: '',
			};
		}
		case REQUEST_DELETE_RESET_PASSWORD_MSG: {
			return {
				...state,
				resetPasswordSuccess: '',
			};
		}
		case RECEIVE_CHECKOUT_CREATE_PROFILE: {
			return {
				...state,
				jwt: action.payload.jwt,
				ssotoken: action.payload.sso_token,
				authUser: true,
			};
		}

		case REQUEST_ERASE_AUTH_DATA:
			return INIT_STATE;
		case EXHIBITOR_AUTH_SIGNEDUP: {
			return {
				...state,
				jwt: action.payload.jwt,
				ssotoken: action.payload.sso_token,
				contact_uuid: action.payload.UUID,
				authUser: true,
			};
		}

		case RECEIVE_MAGIC_LOGIN: {
			return {
				...state,
				magicLinkSentMsg: action.payload.message,
			};
		}

		default:
			return state;
	}
};

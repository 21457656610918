import React from 'react';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import _isEmpty from 'lodash/isEmpty';

import { getCheckoutRoute, getCheckoutPublicRoute } from '../helpers';
import { useShoppingCart } from '../hooks/useShoppingCart';
import { ReactComponent as CartEmpty } from '../../../../assets/icons/CartEmpty.svg';

const SelectionCartList = () => {
    const cartItems = useSelector(state => state.ecommerce.cartItems);
    const [, , , removeFromCart] = useShoppingCart();
    const history = useHistory();
    const { moduleUrlId } = useParams();
    const publicEmbed = useSelector(state => state.settings.publicEmbed);
    const InvoiceItemArray = cartItems?.InvoiceItemArray ? cartItems?.InvoiceItemArray : [];
    const actionClicked = (price) => {
        removeFromCart({ ...price, fieldid: price?.field_id, fieldname: price?.field_name, valueid: price?.value_id });
    };
    const checkout = () => {
        history.push(
            publicEmbed ? getCheckoutPublicRoute(moduleUrlId) : getCheckoutRoute(moduleUrlId),
        );
    };
    return (
        <div>
            <div className="gx-d-flex gx-justify-content-between gx-align-items-center">
                <h3 style={{ color: '#353535' }}>My Shopping Cart</h3>
                {!_isEmpty(InvoiceItemArray) && <div><CartEmpty style={{ width: '2em' }} /></div>}
            </div>
            {_isEmpty(InvoiceItemArray)
                ? <div className="gx-d-flex gx-flex-column gx-justify-content-center gx-align-items-center gx-mt-4">
                    <div><CartEmpty style={{ width: '5em' }} /></div>
                    <h3 style={{ color: '#353535' }}>Your Shopping Cart Is Empty</h3>
                </div>
                : <div>
                    {InvoiceItemArray?.map((items) => {
                        return (
                            <div className="gx-d-flex gx-justify-content-between gx-align-items-center gx-mt-3" >
                                <div>{items.invoice_description}</div>
                                <div className="gx-mx-2 gx-font-weight-bold">{items.amount ? `$${items.amount}` : items.amount}</div>
                                <Button
                                    className="button-secondary"
                                    onClick={() => actionClicked(items)}
                                >
                                    Remove
                                </Button>
                            </div>
                        )
                    })}
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {InvoiceItemArray?.length > 1 ? (
                            <div>
                                <Button
                                    className="button-primary"
                                    shape="round"
                                    onClick={checkout}
                                >
                                    Check Out
                                </Button>
                            </div>
                        ) : null}
                    </div>
                </div>
            }
        </div>
    );
};

export default SelectionCartList;

import React from 'react';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ url, expanded, style = {} }) => (
	<main className="player-wrapper" style={{ height: expanded ? '35vh' : '25vh', ...style }}>
		<ReactPlayer
			url={url}
			width="100%"
			height="100%"
			controls
			config={{ file: { attributes: { controlsList: 'nodownload' } } }}
			onContextMenu={e => e.preventDefault()}
		/>
	</main>
);

export default VideoPlayer;

import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { connectStateResults } from 'react-instantsearch-dom';
import { Tag, Empty, Skeleton, Card, Image } from 'antd';

import { useProductPrices } from '../hooks/useProductPrices';
import { useProducts } from '../hooks/useProducts';
import { useProductFilter } from '../hooks/useProductFilter';

import ProductCardList from './ProductCardList';
import CurrentRefinements from '../../../Common/Algolia/CurrentRefinements';

import { productsPerPage } from '../helpers';

const SelectionResults = ({ searchResults, searchState }) => {
	const history = useHistory();
	const [productPrices, getPrices, priceLoading] = useProductPrices();
	const [filterByPrice] = useProductFilter();
	const [, products] = useProducts();

	const VIEW = { GRID: 0, LIST: 1 };
	const [viewType, setViewType] = useState(VIEW.GRID);
	const categoriesMap = useSelector(state => state.ecommerce.storeCategoriesMap);

	const loading = !searchResults || priceLoading;

	const showProduct = product => {
		if (product.childrenIds.length > 0)
			history.push(`bundle/${product.id}${history.location.search}`);
		else history.push(`product/${product.id}${history.location.search}`);
	};

	useEffect(() => {
		if (!searchResults) return;
		getPrices(searchResults.hits.map(product => product.id));
	}, [searchResults]);

	useEffect(() => {
		if (!priceLoading) {
			filterByPrice();
		}
	}, [priceLoading]);

	const getConstructProduct = () => {
		const reconstructedItems = searchResults?.hits.map((hit) => {
			let childProducts = products.filter(product => hit?.childrenIds?.includes(product.id));
			let totalCount = childProducts.length ? childProducts?.reduce((accumulated, curVal) => {
				return accumulated + curVal?.childrenIds?.length
			}, 0) : 0;
			return {
				...hit,
				grandChildrenCount: totalCount
			}
		});
		return reconstructedItems;
	};

	const constructedProducts = useMemo(getConstructProduct, [searchResults?.hits, products, productPrices]);

	if (loading)
		return (
			<>
				<Skeleton active title paragraph={{ rows: 0 }}></Skeleton>
				<div className="product-grid product-list-wrapper">
					{[...Array(productsPerPage)].map((_, index) => (
						<Card key={index} className="card shadow-1">
							<div className="gx-d-flex">
								<div className="gx-text-center gx-mt-3 gx-mr-3">
									<Image
										className="card-image"
										style={{ height: '7em' }}
										alt="placeholder"
										src={require('../../../../assets/images/placeholder-image.png')}
									/>
								</div>
								<div className='gx-flex-1'>
									<Skeleton active></Skeleton>
									<Skeleton.Button
										className="gx-d-block gx-ml-auto"
										active
										size="large"
										shape="round"
									/>
								</div>
							</div>
						</Card>
					))}
				</div>
			</>
		);
	else if (searchResults.nbHits === 0) return <Empty style={{ margin: '15em 0 15em 0' }} />;

	return (
		<section style={{ overflow: 'visible' }}>
			{/* viewtype toggle and search result count */}
			<div className="gx-mb-3 gx-d-flex gx-align-items-center">
				<CurrentRefinements categoriesMap={categoriesMap} />
				{/* <p>{searchResults.nbHits} results found</p>

				<Radio.Group
					onChange={({ target }) => setViewType(target.value)}
					value={viewType}
					className="gx-ml-auto gx-d-flex gx-align-items-center"
				>
					<Radio.Button value={VIEW.GRID} className="radio-button-left">
						<div className="gx-d-flex gx-align-items-center">
							<Grid
								className="gx-mr-2"
								style={{
									width: '1em',
								}}
							/>
							<p className="gx-mb-0">Grid</p>
						</div>
					</Radio.Button>
					<Radio.Button value={VIEW.LIST} className="radio-button-right">
						<div className="gx-d-flex gx-align-items-center">
							<List
								className="gx-mr-2"
								style={{
									width: '1em',
								}}
							/>
							<p className="gx-mb-0">List</p>
						</div>
					</Radio.Button>
				</Radio.Group> */}
			</div>
			{constructedProducts?.map(product => (
				<div key={product.id} className="gx-mb-3 product-list-wrapper" style={{ overflow: 'visible' }}>
					<ProductCardList
						product={product}
						price={productPrices.find(price => price.productid === product.id)}
						showProduct={() => showProduct(product)}
					/>
				</div>
			))}
		</section>
	);
};

export default connectStateResults(SelectionResults);

import React, { useState, useEffect } from 'react';
import { DatePicker, Form } from 'antd';
import Title from '../components/Title';
import Description from '../components/Description';
import NormalText from '../components/NormalText';
import useRules from '../hooks/useRules';
import moment from 'moment';

export const Date = ({
	label,
	description,
	primaryColor,
	secondaryColor,
	options,
	textColor,
	required,
	name,
	value,
	onChange,
	passDateValue,
}) => {
	const rules = useRules(required);

	const dateFormat = 'YYYY/MM/DD';
	const [selectedDate, setSelectedDate] = useState(null);

	const onDateChange = (date, dateString) => {
		setSelectedDate(date);
		passDateValue({ date, label, name });
	};

	return (
		<div>
			<Title label={label} color={primaryColor} required={required} name={name} />
			<Description label={description} color={secondaryColor} />
			<Form.Item name={name} rules={rules}>
				<div className="gx-d-flex" style={{ width: '100%' }}>
					<DatePicker
						onChange={onDateChange}
						value={selectedDate?moment(selectedDate):value && value!=''?moment(value):null}
						defaultValue={moment()}
						format={dateFormat}
					/>
				</div>
			</Form.Item>
		</div>
	);
};

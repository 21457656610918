import React, { useState, useMemo } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';

// import { InstantSearch, Configure } from 'react-instantsearch-dom';

import { useProducts } from '../hooks/useProducts';
// import { useBundleInfo } from '../hooks/useBundleInfo';
// import { useSearchClient } from '../hooks/useSearchClient';
import GroupContext from './GroupContext';

// import FilterSidebar from '../components/FilterSidebar';
import ConsumptionHeader from '../components/ConsumptionHeader';
import ConsumptionArea from '../components/ConsumptionArea/';
// import SidebarToggle from '../components/SidebarToggle';
// import ConsumptionResults from './ConsumptionResults';
// import Pagination from 'components/Common/Algolia/Pagination';
// import SearchBox from 'components/Common/Algolia/SearchBox';

import { paramsToSearchState, searchStateToParams } from '../helpers';
// import { sidebarWidth, toggleWidth, hitsPerPage } from '../constants';

// import { Button } from 'antd';
// import { ReactComponent as Filter } from 'assets/icons/filter.svg';

import '../styles/custom.less';

const GroupConsumptionPage = () => {
	const { superBundleId, bundleId, productId } = useParams();
	const history = useHistory();
	const location = useLocation();
	const [searchState, setSearchState] = useState(paramsToSearchState(location.search));
	const [sidebarOpen, setSidebarOpen] = useState(true);
	// const [filterOpen, setFilterOpen] = useState(false);

	const [prodsLoading, products] = useProducts();
	const superBundle = useMemo(() => products.find(prod => prod.id === parseInt(superBundleId)), [
		products,
	]);
	// const [infoLoading, bundleInfo] = useBundleInfo(prodsLoading, superBundle?.uuid);
	const bundle = useMemo(() => products.find(child => child.id === parseInt(bundleId)), [
		products,
		bundleId,
	]);
	const product = useMemo(
		() => products.find(child => child.id === parseInt(productId)),
		[products, productId],
	);

	const consumingProduct = bundleId ? bundle : superBundle;

	// const searchClient = useSearchClient(infoLoading, bundleInfo);

	// const loading = infoLoading || prodsLoading || !bundle;

	// const searchStateChange = state => {
	// 	setSearchState(state);
	// 	const urlParams = searchStateToParams(state);
	// 	history.push(`?${urlParams}`);
	// };

	return (
		<GroupContext.Provider value={{ superBundle, bundle, product }}>
			{/* <InstantSearch
				indexName="consumption/group-consumption"
				searchClient={searchClient}
				onSearchStateChange={searchStateChange}
				searchState={searchState}
			> */}
				{/* <Configure hitsPerPage={hitsPerPage} /> */}

				<main
					className="gx-bg-white gx-d-flex gx-flex-column"
					// style={{ minHeight: '100vh', maxHeight: '100vh', overflowY: 'auto' }}
				>
					<ConsumptionHeader
						loading={prodsLoading}
						product={consumingProduct}
						superBundle={superBundle}
						goBack={() =>
							history.push(
								`/${
									history.location.pathname.split('/')[1]
								}/consumption/selection/${superBundle.id}?${searchStateToParams(
									searchState,
								)}`,
							)
						}
					/>

					<section className="gx-flex-1 gx-d-flex">
						<article
							className="content-area"
							style={{
								width: `100%`,
								// width: `calc(100% - ${
								// 	toggleWidth + (sidebarOpen ? sidebarWidth : 0)
								// }px)`,
							}}
						>
							<ConsumptionArea
								loading={prodsLoading || !consumingProduct}
								product={consumingProduct}
								expanded={!sidebarOpen}
							/>
						</article>

						{/* <SidebarToggle
							isOpen={sidebarOpen}
							close={() => setSidebarOpen(show => !show)}
						/> */}

						{/* <aside style={{ overflowX: 'hidden', flex: '1 0' }}>
							<div style={{ width: sidebarWidth, position: 'relative' }}>
								<aside className="gx-px-4 gx-py-5">
									<div className="gx-d-flex gx-align-items-center gx-pb-5">
										<h2 className="gx-text-primary gx-font-weight-semi-bold gx-mb-0">
											Content
										</h2>
										<Button
											onClick={() => setFilterOpen(true)}
											className="shadow-1 button-white gx-mb-0 gx-ml-auto gx-d-flex gx-align-items-center"
											shape="round"
											style={{ border: 'none' }}
										>
											<Filter
												className="gx-my-auto gx-mr-2"
												style={{ width: '.9rem' }}
											/>
											Filters
										</Button>
									</div>

									<p
										onClick={() =>
											history.push(
												`/${
													history.location.pathname.split('/')[1]
												}/consumption/group/${
													superBundle.id
												}?${searchStateToParams(searchState)}`,
											)
										}
										className="gx-mb-4 gx-pointer"
										style={{
											fontSize: '.9rem',
											textDecoration:
												consumingProduct?.id === superBundle?.id
													? 'underline'
													: '',
										}}
									>
										{superBundle?.title}
									</p>

									<ConsumptionResults loading={prodsLoading} />
									<div className="gx-text-center gx-pt-2">
										<Pagination />
									</div>
								</aside>

								<aside
									className={`${
										filterOpen ? 'filter-sidebar-show' : 'filter-sidebar-hide'
									} gx-bg-white gx-px-4 gx-py-5 gx-w-100`}
								>
									<div className="gx-text-right gx-mb-3">
										<Button
											onClick={() => setFilterOpen(false)}
											className="shadow-1 button-white"
											shape="round"
										>
											Close
										</Button>
									</div>

									<div className="gx-mb-3">
										<SearchBox />
									</div>

									<div
										className="gx-algolia-sidebar"
										style={{ width: sidebarWidth }}
									>
										<FilterSidebar tags />
									</div>
								</aside>
							</div>
						</aside> */}
					</section>
				</main>
			{/* </InstantSearch> */}
		</GroupContext.Provider>
	);
};

export default GroupConsumptionPage;

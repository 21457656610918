import React, { useState, useRef, useLayoutEffect, useMemo, Fragment } from 'react';
import { useSelector } from 'react-redux';

import { useShoppingCart } from '../hooks/useShoppingCart';
import { useTags } from '../hooks/useTags';

import { Card, Tag, Button, Spin, Popover, Alert, Divider } from 'antd';
import { PlayCircleOutlined, PictureOutlined, FilePdfOutlined } from '@ant-design/icons';

import { ReactComponent as Remove } from '../../../../assets/icons/remove.svg';

import { FILE } from 'components/Common/constants';

// import classNames from 'classnames';
import './bundle.css';

const BundleProductCard = ({ product, price, heightChanged, collapseDuration, priceLoading }) => {
	const [, tagsMap] = useTags();
	const [collapsibleShow, setCollapsibleShow] = useState(false);
	const [collapsibleHeight, setCollapsibleHeight] = useState(0);
	const collapsible = useRef();
	const showTags = !useSelector(
		state =>
			state.ecommerce.attendeeSettings?.attendeesiteadmin?.generalconfigobject?.hide_all_tags,
	);

	const [addToCart, , cart, removeFromCart] = useShoppingCart();
	const addedToCart = useMemo(() => cart.some(invoice => invoice.field_id === price?.fieldid), [
		cart,
	]);
	const { styling } = useSelector(({ loginInfo }) => loginInfo);

	const contentTypeCount = {};

	useLayoutEffect(() => {
		// if (!collapsibleShow) return;
		// setCollapsibleHeight(collapsible.current.scrollHeight);
		// heightChanged(collapsible.current.scrollHeight);
		// setCollapsibleShow(false);
	});

	for (const file of product.files || []) {
		if (contentTypeCount[file?.type]) contentTypeCount[file?.type]++;
		else contentTypeCount[file?.type] = 1;
	}

	// const toggleCollapse = () => {
	// 	if (collapsibleHeight > 0) {
	// 		heightChanged(-collapsibleHeight);
	// 		setCollapsibleHeight(0);
	// 	} else setCollapsibleShow(true);
	// };

	const hasPrice = !priceLoading && price?.price_collection;

	return (
		<Card
			style={{ borderLeft: `6px solid ${styling['primary-dark-color']}`, borderRadius: '5px' }}
			className="gx-my-2 card-padding-0 card shadow-1"
		>
			<div className="gx-pt-3 gx-px-4 gx-pb-2 bundle-card-wrapper">
				<div className="gx-d-flex gx-justify-content-between">
					<div className="gx-mr-2 gx-mb-2">
						<h2
							className="gx-mr-2 bundle-title gx-font-weight-semi-bold"
							style={{ display: 'inline' }}
						>
							{price?.price_collection?.primary.label ?? product.title}
						</h2>
					</div>
					<div className="product-card-btn-wrapper">
						{/* description popover */}
						{product.description
							? (
								<Popover
									trigger="click"
									content={
										<p
											dangerouslySetInnerHTML={{
												__html: product.description,
											}}
											style={{
												maxWidth: '40em',
											}}
										></p>
									}
								>
									<Button
										className="gx-ml-auto button-secondary gx-w-100"
										shape="round"
									>
										MORE INFO
									</Button>
								</Popover>
							) : null
						}
					</div>

				</div>
				<div
					className={"gx-d-flex gx-align-items-end gx-justify-content-between"}
				>
					<div>
						{Object.keys(contentTypeCount).length ? (
							<div style={{ minWidth: '30rem' }}>
								<Divider orientation="left" plain>
									Includes
								</Divider>
								<div className="content-count bundle-text-content gx-d-flex gx-align-items-center gx-mb-2">
									{Object.entries(contentTypeCount).map(content => (
										<Fragment key={content[0]}>
											{content[0] === FILE.PDF ? (
												<span className='gx-ml-2'><FilePdfOutlined /> {content[1]} {content[0].toUpperCase()}</span>
											) : null}
											{content[0] === FILE.VIDEO ? (
												<span className='gx-ml-2'><PlayCircleOutlined /> {content[1]} {content[0]}</span>
											) : null}
											{content[0] === FILE.IMAGE ? (
												<span className='gx-ml-2'><PictureOutlined /> {content[1]} {content[0]}</span>
											) : null}
											<span className="gx-mr-2 gx-font-weight-bold"></span>
										</Fragment>
									))}
								</div>
							</div>
						) : null}
					</div>
					<div className="product-card-btn-wrapper gx-mb-1 gx-mt-3">
						<div className="product-price-details gx-d-flex gx-justify-content-center">
							{/* {price?.price_collection?.teaser && (
								<Tag className="teaser-tag">
									{price?.price_collection?.teaser?.price === 0
										? 'FREE'
										: `$${price?.price_collection?.teaser?.price}`}{' '}
									for members
								</Tag>
							)} */}
						</div>
						{!priceLoading ? (
							hasPrice && (
								<Button
									className="button-primary gx-w-100"
									shape="round"
									onClick={() => (addedToCart ? removeFromCart(price) : addToCart(price))}
								>
									<div className="gx-d-flex gx-justify-content-center gx-align-items-center">
										{addedToCart ? (
											<p>
												<Remove className="gx-mr-2" style={{ width: '0.9em' }} />
												Remove
											</p>
										) : (
											price?.price_collection && (
												<>
													<p className="gx-mb-0">
														{price.price_collection.primary.price === 0
															? 'FREE'
															: `BUY $${price.price_collection.primary.price}`}
													</p>
												</>
											)
										)}
									</div>
								</Button>
							)
						) : (
							<Spin className="gx-ml-1" size="small" />
						)}
					</div>
				</div>
			</div>

			{/* contents of the product */}
			{/* <div
				ref={collapsible}
				style={{
					height: `${collapsibleHeight}px`,
					overflow: 'hidden',
					transition: `height ${collapseDuration}ms ease-in-out`,
				}}
			>
				{product.files?.map((file, index) => (
					<div
						key={index}
						className="gx-bg-light-grey gx-px-4 gx-py-3 gx-border-grey gx-border-top gx-d-flex gx-align-items-center"
					>
						{file.type === FILE.VIDEO && (
							<Video
								className="gx-my-auto gx-mr-3 fg-primary"
								style={{ width: '1.2rem' }}
							/>
						)}
						{file.type === FILE.PDF && (
							<PDF
								className="gx-my-auto gx-mr-3 fg-primary"
								style={{ width: '1.2rem' }}
							/>
						)}
						{file.type === FILE.IMAGE && (
							<Image
								className="gx-my-auto gx-mr-3 fg-primary"
								style={{ width: '1.2rem' }}
							/>
						)}
						{file.type === FILE.LINK && (
							<Link
								className="gx-my-auto gx-mr-3 fg-primary"
								style={{ width: '1.2rem' }}
							/>
						)}
						<div>
							<h4 className="gx-mb-1 fg-grey">{file.title}</h4>
							<p className="gx-mb-0">{file?.description}</p>
						</div>
						<Padlock className="fg-secondary gx-ml-3" style={{ width: '.8rem' }} />
					</div>
				))}
			</div> */}

			{/* {product.files?.length > 0 && (
				<div
					className={classNames(
						'gx-px-4',
						'gx-py-2',
						'gx-border-top',
						'gx-d-flex',
						'gx-align-items-center',
						{
							'gx-border-grey': collapsibleHeight > 0,
						},
					)}
				>
					<Button
						onClick={toggleCollapse}
						className="gx-mb-0 gx-p-0 fg-grey"
						type="link"
						icon={collapsibleHeight > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />}
					>
						{collapsibleHeight > 0 ? 'Hide items' : 'Show items'} (
						{product.files?.length ?? 0})
					</Button>

					<div className="content-count gx-ml-3 gx-mr-3 gx-d-flex gx-align-items-center">
						{Object.entries(contentTypeCount).map(content => (
							<Fragment key={content[0]}>
								<span className="gx-mr-2">
									{content[1]} {content[0]}
								</span>
								<span className="gx-mr-2 gx-font-weight-bold">-</span>
							</Fragment>
						))}
					</div> */}

			{/* tags */}
			{/* <div>
						{showTags &&
							product.tagIds.map(
								tagId =>
									tagsMap[tagId] && (
										<Tag key={tagId} className="gx-mb-0 tag">
											{tagsMap[tagId]}
										</Tag>
									),
							)}
					</div>

					{hasPrice && (
						<Button
							onClick={() => (addedToCart ? removeFromCart(price) : addToCart(price))}
							className="gx-ml-auto gx-mb-0 button-secondary-outline button-round"
							shape="circle"
						>
							{addedToCart ? (
								<Remove style={{ width: '0.9rem', margin: '0.65em 0' }} />
							) : (
								<CartPlus className="cart-icon" />
							)}
						</Button>
					)}
				</div>
			)} */}
		</Card>
	);
};

export default BundleProductCard;

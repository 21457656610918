import React, { useEffect, useState } from 'react';
import { Badge, Button, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { ShoppingOutlined } from '@ant-design/icons';
import { getCompanyState, isWebView } from '@components/Membership/helpers/generalHelpers';
import { IN_GOOD_STANDING, EXPIRED, IN_RENEWAL_PERIOD } from '@components/Membership/helpers/constant';
import { requestToUpdateStepDetails } from '@appRedux/actions';
import { isEmpty } from 'lodash';

const TabNavigation = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const loginInfo = useSelector(state => state.loginInfo);
  const corporateMembership = useSelector(state => state.corporateMembership);
  const publicEmbed = useSelector(state => state.settings.publicEmbed);

  const moduleUrlId = loginInfo.corporateMembershipModuleUrlId;
  const exhibitorCartDetails = corporateMembership.exhibitorCart;
  const menu = corporateMembership.menu;
  const loading = !corporateMembership.menuFetched;

  const [selectedTab, setSelectedTab] = useState('home');
  const [filteredTab, setFilteredTab] = useState([]);

  useEffect(() => {
    let splittedPath = location.pathname.split('/');
    menu.length && menu.forEach(menu => {
      if (splittedPath.includes(menu.screentype.toLowerCase())) { setSelectedTab(menu.screentype.toLowerCase()) }
    });
  }, [location.pathname, menu]);

  useEffect(() => {
    const expirationState = getCompanyState({
      expiryDate: corporateMembership.company.expiry_date,
      renewal: corporateMembership?.generalSettings.renewal_period_days
    })
    let tempMenu = menu.filter(
      m => !(expirationState === IN_GOOD_STANDING && m.screentype === 'SubMenu'),
    );
    tempMenu = tempMenu.filter(
      m =>
        !(
          (expirationState === EXPIRED || expirationState === IN_RENEWAL_PERIOD) &&
          !isEmpty(corporateMembership?.exhibitorCartDetails?.pending_payment_data) &&
          m.screentype === 'SubMenu'
        ),
    );
    setFilteredTab(tempMenu)
  }, [menu])
  const handleTabChange = (key) => {
    dispatch(requestToUpdateStepDetails({ currentStep: 0 }))
    if (publicEmbed) {
      history.push(`/main/public/company/${moduleUrlId}/${key}?public_embed=1`);
    } else {
      history.push(`/main/company/${moduleUrlId}/${key}`);
    }
    setSelectedTab(key);
  }
  const CheckOutButton = () => {
    const checkoutData = menu.find(m => m.screentype === "Checkout");
    return (checkoutData && exhibitorCartDetails?.InvoiceItemArray?.length > 0 ?
      <Badge count={exhibitorCartDetails?.InvoiceItemArray?.length ?? 0}>
        <Button
          type='primary'
          className='gx-m-0 gx-ml-2'
          icon={<ShoppingOutlined />}
          block={!isWebView()}
          onClick={() => handleTabChange(checkoutData.screentype.toLowerCase())}
          disabled={exhibitorCartDetails?.InvoiceItemArray?.length > 0 ? false : true}
        >{checkoutData.button}</Button>
      </Badge> : null
    )
  }
  // Used flex instead of the tabExtraContent Prop which misbehaved with the design.
  return (!loading && (
    <div className={isWebView() ? 'gx-d-flex gx-justify-content-between' : null}>
      {!isWebView() && <div className='gx-text-right'>
        <CheckOutButton />
      </div>}
      <Tabs className='coporate-tab-nav' defaultActiveKey='home' activeKey={selectedTab} onChange={handleTabChange}>
        {filteredTab.map((tab, inx) => {
          return tab.screentype !== "Checkout" ? <Tabs.TabPane tab={tab.button} key={tab.screentype.toLowerCase()} /> : null
        })}
      </Tabs>
      {isWebView() && <CheckOutButton />}
    </div>
  ));
};

export default TabNavigation;

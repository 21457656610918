import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Card } from 'antd';
import CircularProgress from '@components/CircularProgress';
import {
	requestUserCompanyAffiliationData,
	requestCompanyData,
	requestCompanyMembersData,
	requestCorporateMembershipModuleUrlId,
	requestExhibitorCartDetails,
	requestExhibitorCart,
	requestMiniProfileFields,
	requestLoginFormData,
	requestAttendeesInfo,
	requestCompanyStaffDiscount,
	requestAuthenticateUser,
} from '@appRedux/actions';
import CompanyHeader from './CompanyHeader';
import TabNavigation from './TabNavigation';
import { NotificationContainer } from 'react-notifications';
import { isWebView } from '@components/Membership/helpers/generalHelpers';

export default function HeaderAndNavigation({ children, location }) {
	const dispatch = useDispatch();

	const publicEmbed = useSelector(state => state.settings.publicEmbed);
	const loginInfo = useSelector(state => state.loginInfo);
	const corporateMembership = useSelector(state => state.corporateMembership);
	const common = useSelector(state => state.common);

	const [showHeader, setShowHeader] = useState(true);
	const [showNavigation, setShowNavigation] = useState(true);
	const [loading, setLoading] = useState(true);
	const auth = useSelector(state => state.auth);
	const moduleUrlId = loginInfo.corporateMembershipModuleUrlId;
	const moduleUrlIdFetched = corporateMembership.moduleUrlIdFetched;

	useEffect(() => {
		if (publicEmbed) {
			// setShowHeader(false);
			setShowNavigation(false);
		}
		if (location.pathname.includes('checkout') && showNavigation) { setShowNavigation(false); }
		else if (!publicEmbed) { setShowNavigation(true) };
	}, [publicEmbed, location.pathname])

	useEffect(() => {
		if (publicEmbed && !loginInfo.loginFormDataFetched) {
			dispatch(requestLoginFormData({ appdir: loginInfo.appdir }));
		}
		if (publicEmbed && location.search.includes('uuid') && location.search.includes('token')) {
			const urlParams = new URLSearchParams(location.search);
			let formData = new FormData();
			formData.append('uuid', urlParams.get('uuid'));
			formData.append('sso_token', urlParams.get('token'));
			dispatch(
				requestAuthenticateUser({
					appdir: loginInfo.appdir,
					formData: formData,
				}),
			);
		}
		if (loginInfo.appdir && !corporateMembership.miniProfileFieldsFetched && auth.jwt && !publicEmbed) {
			dispatch(requestMiniProfileFields({ appDir: loginInfo.appdir }));
		}
	}, [publicEmbed, loginInfo.appdir]);

	useEffect(() => {
		if (!moduleUrlIdFetched) {
			dispatch(requestCorporateMembershipModuleUrlId({ moduleUrlId }));
		}
	}, []);

	useEffect(() => {
		if (!corporateMembership.companyFetched && corporateMembership.userCompanyAffiliation.comp_uuid) {
			dispatch(requestCompanyData({
				compuuid: corporateMembership.userCompanyAffiliation.comp_uuid,
			}));
		}
		if (corporateMembership.userCompanyAffiliation.comp_uuid && loginInfo.appdir) {
			dispatch(requestExhibitorCartDetails({
				appDir: loginInfo.appdir,
				urlId: moduleUrlId,
				compUuid: corporateMembership.userCompanyAffiliation.comp_uuid,
			}))
		}
	}, [corporateMembership.userCompanyAffiliation.comp_uuid, loginInfo.appdir, moduleUrlId]);
	useEffect(() => {
		if (
			auth.jwt &&
			(location.search?.includes('token') ||
				(loginInfo.appdir && !corporateMembership.userCompanyAffiliationFetched))
		) {
			dispatch(
				requestUserCompanyAffiliationData({
					appDir: loginInfo.appdir,
					urlId: moduleUrlId,
				}),
			);
		}
	}, [auth.jwt]);

	useEffect(() => {
		if (corporateMembership.exhibitorCartDetails.exhibuuid && corporateMembership.userCompanyAffiliation['comp_uuid']) {
			dispatch(requestExhibitorCart({
				compUuid: corporateMembership.userCompanyAffiliation['comp_uuid'],
				urlId: moduleUrlId,
				exhibUuid: corporateMembership.exhibitorCartDetails.exhibuuid,
			}));
		}
	}, [corporateMembership.exhibitorCartDetails.exhibuuid, corporateMembership.userCompanyAffiliation['comp_uuid']])

	useEffect(() => {
		if (corporateMembership.userCompanyAffiliation.comp_uuid) {
			dispatch(requestCompanyData({ compuuid: corporateMembership.userCompanyAffiliation.comp_uuid, }));
		}
	}, [corporateMembership.oldExhibitorCart]);

	useEffect(() => {
		if (
			corporateMembership.userCompanyAffiliationFetched &&
			corporateMembership.userCompanyAffiliation.comp_uuid &&
			!corporateMembership.companyMembersFetched
		) {
			dispatch(requestCompanyMembersData({
				compuuid: corporateMembership.userCompanyAffiliation.comp_uuid,
				urlId: moduleUrlId,
			}));
		}
	}, [
		corporateMembership.userCompanyAffiliationFetched,
		corporateMembership.userCompanyAffiliation.comp_uuid,
	]);

	useEffect(() => {
		const isAllAPIsFetched = publicEmbed
			? (
				!loginInfo.isLoading
				&& corporateMembership.menuFetched
			) : (
				!loginInfo.isLoading
				&& corporateMembership.miniProfileFieldsFetched
				&& corporateMembership.menuFetched
				&& corporateMembership.userCompanyAffiliationFetched
				&& corporateMembership.companyMembersFetched
				&& corporateMembership.companyFetched
				&& corporateMembership.exhibitorCartFetched
			)
		if (isAllAPIsFetched) { setLoading(false); }
	}, [
		loginInfo.isLoading,
		corporateMembership.userCompanyAffiliationFetched,
		corporateMembership.miniProfileFieldsFetched,
		corporateMembership.companyMembersFetched,
		corporateMembership.menuFetched,
		corporateMembership.companyFetched,
		corporateMembership.exhibitorCartFetched,
		publicEmbed
	])

	return (
		<>
			{corporateMembership.userCompanyAffiliation.comp_uuid || publicEmbed ?
				<div>
					{showHeader && <CompanyHeader />}
					<Card style={{ width: '100%' }} className={isWebView() ? "card card-shadow" : null} bordered={isWebView()}>
						{showNavigation && <TabNavigation />}
						{loading ? <CircularProgress /> : children}
						<NotificationContainer />
					</Card>
				</div> :
				<Card className='affiliateUser'>
					<Alert message="No Company Affiliation Found" type="error" showIcon />
				</Card>
			}
		</>
	);
}

const emailRegex = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{1,15})$/;
export const validateContactForm = (form, getAllEmailIds) => {
	let temp = { ...form };
	let valid = true;

	if (temp['firstname']) {
		if (!temp['firstname'].value) {
			temp['firstname'].error = 'Required';
			valid = false;
		} else {
			temp['firstname'].error = '';
		}
	}

	if (temp['lastname']) {
		if (!temp['lastname'].value) {
			temp['lastname'].error = 'Required';
			valid = false;
		} else {
			temp['lastname'].error = '';
		}
	}

	if (temp['email']) {
		if (!temp['email'].value) {
			temp['email'].error = 'Required';
			valid = false;
		} else if (!temp['email'].value.match(emailRegex)) {
			temp['email'].error = 'Please enter valid email';
			valid = false;
		} else if (getAllEmailIds.includes(temp['email'].value)) {
			temp['email'].error = 'This email address already exists';
			valid = false;
		} else {
			temp['email'].error = '';
		}
	}

	if (temp['membership']) {
		if (!temp['membership'].value) {
			temp['membership'].error = 'Required';
			valid = false;
		} else {
			temp['membership'].error = '';
		}
	}
	return { form: temp, valid: valid };
};

export const validateUserForm = form => {
	let temp = { ...form };
	let valid = true;

	if (temp['firstname']) {
		if (!temp['firstname'].value) {
			temp['firstname'].error = 'Required';
			valid = false;
		} else {
			temp['firstname'].error = '';
		}
	}

	if (temp['lastname']) {
		if (!temp['lastname'].value) {
			temp['lastname'].error = 'Required';
			valid = false;
		} else {
			temp['lastname'].error = '';
		}
	}

	if (temp['email']) {
		if (!temp['email'].value) {
			temp['email'].error = 'Required';
			valid = false;
		} else if (!temp['email'].value.match(emailRegex)) {
			temp['email'].error = 'Please enter valid email';
			valid = false;
		} else {
			temp['email'].error = '';
		}
	}

	if (temp['company']) {
		if (!temp['company'].value) {
			temp['company'].error = 'Required';
			valid = false;
		} else {
			temp['company'].error = '';
		}
	}

	if (temp['country']) {
		if (!temp['country'].value) {
			temp['country'].error = 'Required';
			valid = false;
		} else {
			temp['country'].error = '';
		}
	}

	if (temp['state']) {
		if (!temp['state'].value) {
			temp['state'].error = 'Required';
			valid = false;
		} else {
			temp['state'].error = '';
		}
	}

	if (temp['city']) {
		if (!temp['city'].value) {
			temp['city'].error = 'Required';
			valid = false;
		} else {
			temp['city'].error = '';
		}
	}

	return { form: temp, valid: valid };
};

export const validateContactInfo = (form, getAllEmailIds = []) => {
	let temp = { ...form };
	let valid = true;

	if (temp['firstname']) {
		if (!temp['firstname'].value) {
			temp['firstname'].error = 'Required';
			valid = false;
		} else {
			temp['firstname'].error = '';
		}
	}

	if (temp['lastname']) {
		if (!temp['lastname'].value) {
			temp['lastname'].error = 'Required';
			valid = false;
		} else {
			temp['lastname'].error = '';
		}
	}

	if (temp['email']) {
		if (!temp['email'].value) {
			temp['email'].error = 'Required';
			valid = false;
		} else if (!temp['email'].value.match(emailRegex)) {
			temp['email'].error = 'Please enter valid email';
			valid = false;
		} else if (getAllEmailIds.includes(temp['email'].value)) {
			temp['email'].error = 'This email address already exists';
			valid = false;
		} else {
			temp['email'].error = '';
		}
	}

	return { form: temp, valid: valid };
};

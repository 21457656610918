import React, { useMemo } from 'react';
import { Button } from 'antd';

import BundleProductRender from './BundleProductRender';
import { ReactComponent as Left } from '../../../../assets/icons/left.svg';
import { useBundleInfo } from '../hooks/useBundleInfo';
import { ReactComponent as Remove } from '../../../../assets/icons/remove.svg';
import { useShoppingCart } from '../hooks/useShoppingCart';

const BundleChildRender = ({ product, handleSelectBundle }) => {
    const [
        bundle,
        children,
        bundlePrice,
        ,
        totalPrice,
        bundleSavings,
        savingsPercentage,
    ] = useBundleInfo(product.id);
    const [addToCart, , cart, removeFromCart] = useShoppingCart();

    const addedToCart = useMemo(
        () => cart.some(invoice => invoice.field_id === bundlePrice?.fieldid),
        [cart],
    );

    return (
        <>
            <div className="gx-d-flex gx-align-items-end gx-justify-content-between">
                <div className='gx-d-flex gx-align-items-center'>
                    <Button
                        className="button-secondary"
                        size="small"
                        shape="round"
                        style={{ height: 'auto' }}
                        onClick={() => handleSelectBundle({})}
                    >
                        <Left style={{ width: '0.5em' }} />
                        <span className="gx-ml-1">Back</span>
                    </Button>
                    <h1 className="gx-mb-0 gx-mr-2">{bundle.title}</h1>

                </div>
                <div className="gx-mr-4 product-card-btn-wrapper">
                    <Button
                        className="button-primary gx-w-100"
                        shape="round"
                        onClick={(e) => (addedToCart ? removeFromCart(bundlePrice) : addToCart(bundlePrice))}
                    >
                        <div className="gx-d-flex gx-justify-content-center gx-align-items-center">
                            {addedToCart ? (
                                <p>
                                    <Remove className="gx-mr-2" style={{ width: '0.9em' }} />
                                    Remove
                                </p>
                            ) : (
                                bundlePrice?.price_collection && (
                                    <>
                                        <p className="gx-mb-0">
                                            {bundlePrice.price_collection.primary.price === 0
                                                ? 'FREE'
                                                : `BUY $${bundlePrice.price_collection.primary.price}`}
                                        </p>

                                    </>
                                )
                            )}
                        </div>
                    </Button>
                </div>
            </div>
            <hr
                style={{
                    background: 'lightgray',
                }}
            />
            {children.map(child => (
                <BundleProductRender key={child.id} product={child} handleSelectBundle={handleSelectBundle} />
            ))}

        </>
    );
};

export default BundleChildRender;

/* global logEvent */
import setGenerics from '../styler/index';
import LogRocket from 'logrocket';
class auth {
	//const auth = firebase.auth();

	static signOut() {
		return new Promise(function (resolve, reject) {
			let authState = {
				loader: false,
				alertMessage: '',
				message: '',
				showMessage: false,
				initURL: '',
				authUser: '',
				user: '',
				config: '',
				userInfo: '',
			};
			resolve(authState);
		});
	}

	static signInWithSSO(payload) {
		return new Promise(function (resolve, reject) {
			let el = window.location;
			let appdir = el.hostname.substr(0, el.hostname.indexOf('.'));
			const urlParams = new URLSearchParams(el.search);
			let token;
			let uuid;

			if (el.href.includes('token') && el.href.includes('uuid')) {
				token = urlParams.get('token');
				uuid = urlParams.get('uuid');
			} else if (payload) {
				let info = JSON.parse(payload);
				token = info.ssoToken;
				uuid = info.userUUID;
			}
			var splittedStr = el.href.split('/');
			const urlid = splittedStr[4];

			let params = {
				sso_token: token,
				uuid: uuid,
			};
			let domain = 'masterapp.econference.io';
			if (params.appdir == 'npao') {
				domain = 'masterapp.econference.io';
			}
			fetch(
				`'https://masterapp.econference.io/masterapp_summer2012/apiv2/index.cfm?appdir=${appdir}&module=login&component=login&function=authenticate_user`,
				{
					method: 'POST',
					body: JSON.stringify(params),
					headers: {
						'Content-Type': 'application/json',
					},
				},
			)
				.then(res => res.json())
				.then(result => {
					if (result) {
						let authState = {
							loader: false,
							alertMessage: '',
							showMessage: false,
							initURL: '',
							authUser: result.contact_uuid,
							userInfo: result,
						};
						let userInfo = {
							ssoToken: token,
							userUUID: uuid,
						};

						let key = 'UserInfo-' + appdir + '-' + urlid;
						if (typeof localStorage !== 'undefined') {
							localStorage.setItem(key, JSON.stringify(userInfo));
						} else {
							// localStorage not defined
							auth.setCookie(key, JSON.stringify(userInfo), 2);
						}
						resolve(authState);
					} else {
						const appdir =
							window.location.hostname == 'localhost'
								? 'scbna'
								: window.location.hostname.substr(
										0,
										window.location.hostname.indexOf('.'),
								  );
						const urlid = window.location.href.split('/')[4];
						const key = 'UserInfo-' + appdir + '-' + urlid;
						localStorage.removeItem(key);
						if (result.error_message) {
							let authState = {
								loader: true,
								alertMessage: 'Login Failed',
								message: result.error_message,
								showMessage: true,
								initURL: '',
								authUser: '',
								user: '',
								config: '',
								userInfo: '',
							};
							resolve(authState);
							// window.location.replace('http://masterapp.econference.io/'+ appdir)
						}
					}
				})
				.catch(console.log);
		});
	}

	static signInWithLogin(payload) {
		return new Promise(function (resolve, reject) {
			let el = window.location;
			// var el = document.createElement('a');
			// el.href = 'https://scbna.econference.io/program/P5i9hSN/login';
			let appdir = el.hostname.substr(0, el.hostname.indexOf('.'));
			var splittedStr = el.href.split('/');
			const urlid = splittedStr[splittedStr.length - 2];
			let params = {
				appdir: appdir,
				email: payload.email,
				password: payload.password ? payload.password : '',
				urlid: urlid,
			};
			auth.login(params).then(response => {
				resolve(response);
			});
		});
	}

	static login(params) {
		return new Promise(function (resolve, reject) {
			let domain = 'masterapp.econference.io';
			if (params.appdir == 'npao') {
				domain = 'masterapp.econference.io';
			}
			fetch(
				`https://${domain}/masterapp_summer2012/controllers/mobileapp/login/process_v2.cfm`,
				{
					method: 'POST',
					body: JSON.stringify(params),
					headers: {
						'Content-Type': 'application/json',
					},
				},
			)
				.then(res => res.json())
				.then(result => {
					if (result && result.reviewid) {
						auth.fetchConfig(params.appdir, params.urlid).then(config => {
							setGenerics(config).then(() => {
								auth.fetchContactInfo(result.user_uuid, config).then(user => {
									let authState = {
										loader: false,
										alertMessage: '',
										showMessage: false,
										initURL: '',
										authUser: result.user_uuid,
										user: { ...user[0], badges: result.badges, ...result },
										config: config,
										userInfo: result,
									};
									let userInfo = {
										ssoToken: result.ssotoken,
										userUUID: result.user_uuid,
									};
									let key = 'UserInfo-' + params.appdir + '-' + params.urlid;

									if (typeof localStorage !== 'undefined') {
										localStorage.setItem(key, JSON.stringify(userInfo));
									} else {
										// localStorage not defined
										auth.setCookie(key, JSON.stringify(userInfo), 2);
									}
									resolve(authState);
								});
							});
						});
					} else {
						const appdir =
							window.location.hostname == 'localhost'
								? 'scbna'
								: window.location.hostname.substr(
										0,
										window.location.hostname.indexOf('.'),
								  );
						const urlid = window.location.href.split('/')[4];
						const key = 'UserInfo-' + appdir + '-' + urlid;
						localStorage.removeItem(key);
						if (result.error_message) {
							let authState = {
								loader: true,
								alertMessage: 'Login Failed',
								message: result.error_message,
								showMessage: true,
								initURL: '',
								authUser: '',
								user: '',
								config: '',
								userInfo: '',
							};
							resolve(authState);
							// window.location.replace('http://masterapp.econference.io/'+ appdir)
						}
					}
				})
				.catch(console.log);
		});
	}

	static setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
		var expires = 'expires=' + d.toUTCString();
		// samesite=none has been removed because it doesn't add cookie in the normal case
		// need to debug as to why it didn't work and see what works for iframe.
		document.cookie = `${cname}=${cvalue};${expires};path=/;${
			window.location.protocol === 'http:' ? '' : `samesite=none;secure=true`
		}`;
	}

	static deleteCookie(cname) {
		document.cookie = `${cname}=;expires=Thu, 01-Jan-70 00:00:01 GMT;path=/;${
			window.location.protocol === 'http:' ? '' : `samesite=none;secure=true`
		}`;
	}

	static getCookie(cname) {
		var name = cname + '=';
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return '""';
	}

	static fetchContactInfo(ids, config) {
		return new Promise(function (resolve, reject) {
			let url =
				'https://atm8dzfs09.execute-api.us-east-2.amazonaws.com/production/contact/' +
				config.source_hex;
			let uuids = { uuids: ids };
			let paramData = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(uuids),
			};
			fetch(url, paramData)
				.then(res => res.json())
				.then(contactsData => {
					resolve(contactsData);
				});
		});
	}

	static fetchConfig(appdir, urlid) {
		return new Promise(function (resolve, reject) {
			fetch(
				'https://masterapp.econference.io/masterapp_summer2012/controllers/mobileapp/settings/process_v2.cfm?appdir=' +
					appdir +
					'&urlid=' +
					urlid,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
				},
			)
				.then(res => res.json())
				.then(config => {
					resolve(config);
				});
		});
	}
}

export default auth;

import React from 'react';
import { useSelector } from 'react-redux';
import { connectStateResults } from 'react-instantsearch-dom';
import { Card, Skeleton, Image, Empty } from 'antd';

import { hitsPerPage } from '../constants';
import CurrentRefinements from '../../Common/Algolia/CurrentRefinements';
import ProductCard from '../SelectionPage/ProductCard';

const StateResults = ({ searchResults, loading, gotoProduct }) => {
	const searchLoading = loading || !searchResults;
	const categoriesMap = useSelector(state => state.consumption.categoriesMap);

	if (searchLoading)
		return (
			<section className="product-grid">
				{[...Array(hitsPerPage)].map((_, index) => (
					<Card key={index} className="card gx-mb-0 shadow-1">
						<div className="gx-d-flex">
							<Image
								className="card-image"
								style={{ height: '7em' }}
								alt="placeholder"
								src={require('assets/images/placeholder-image.png')}
							/>
							<Skeleton className="gx-ml-3" active paragraph={{ rows: 3 }}></Skeleton>
						</div>
						<Skeleton.Button
							className="gx-d-block gx-ml-auto"
							active
							size="large"
							shape="round"
						/>
					</Card>
				))}
			</section>
		);
	if (searchResults.nbHits === 0) return <Empty style={{ margin: '20em 0' }} />;

	return (
		<div>
			<div className="gx-mb-3 gx-d-flex gx-align-items-center">
				<CurrentRefinements categoriesMap={categoriesMap} />
			</div>
			<section className="product-grid product-list-wrapper">
				{searchResults.hits.map((product, index) => (
					<ProductCard product={product} goto={gotoProduct} key={index} />
				))}
			</section>
		</div>
	);
};

export default connectStateResults(StateResults);

import React, { useEffect, useState } from 'react';
import {
	requestToFetchPaymentSettings,
	requestToDeleteRegistrationItemFromCart,
	requestToDeleteExhibitorPayment,
	requestToUpdateStepDetails,
	requestExhibitorCart,
	requestIgnorePurchaseRequiredRedirect
} from '@appRedux/actions';
import { connect, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from '@components/CircularProgress';
import RegistrationItems from '@components/Common/RegistrationItems';
import PaymentOptions from '@components/Common/PaymentOptions';
import FreeItemPayment from '@components/Common/FreeItemPayment';
import PayNow from '@components/Common/PayNow';
import { useParams } from 'react-router';
import { Alert, Button, Spin, Typography } from 'antd';
import GoBack from '@components/Common/GoBack';
import { useHistory } from 'react-router-dom';

import { ReactComponent as BagIcon } from '../../../assets/icons/bag.svg';
import useAfterPaymentProcess from '@components/Membership/hooks/useAfterPaymentProcess';

const ExhibitorCheckout = props => {
	const dispatch = useDispatch()
	// if cart is empty, show msg you have no amount due, show manage staff
	const publicEmbed = props.settings.publicEmbed;
	const history = useHistory();
	const width = props.settings.width;
	const mobileThreshold = useSelector(state => state.common.mobileThreshold);

	const [currentPaymentMethod, setCurrentPaymentMethod] = useState('');
	const [loader, setLoader] = useState(true);
	const [companyCreated, setCompanyCreated] = useState(false);
	const [paymentSuccessful, setPaymentSuccessful] = useState(false);
	const { moduleUrlId } = useParams();
	const [existingPayment, setExistingPayment] = useState(false);

	const menu = useSelector(state => state.corporateMembership.menu);
	const languageObject = useSelector(state => state.corporateMembership.languageObject);
	const loading = !useSelector(state => state.corporateMembership.menuFetched);

	const authUser = useSelector(state => state.auth.authUser);
	const contactUuid = useSelector(state => state.auth.contact_uuid);
	const ssotoken = useSelector(state => state.auth.ssotoken);
	const corporateMembership = useSelector(state => state.corporateMembership);
	const status = useSelector(state => state.corporateMembership.exhibitorCartDetails.status);
	const paymentSuccess = useSelector(state => state.common?.paymentSuccess);
	const isPaymentLoader = useSelector(state => state.common?.isPaymentLoader);
	const isUpdateMemberAfterPayment = useSelector(state => state.corporateMembership?.isUpdateMemberAfterPayment);
	const commonStatus = useSelector(state => state.common.status);
	const showThankYouMessage = (status === 'Complete' || status === 'Pending' || commonStatus === 'Complete' || commonStatus === 'Pending');

	const simpleinvoiceobj = useSelector(
		state => state.corporateMembership.exhibitorCart.simpleInvoiceObj,
	);

	const cartItems = useSelector(state => state.corporateMembership.exhibitorCart);

	const appdir = useSelector(state => state.loginInfo.appdir);

	const compUuid = useSelector(
		state => state.corporateMembership.userCompanyAffiliation.comp_uuid,
	);
	const exhibUuid = useSelector(
		state => state.corporateMembership.exhibitorCartDetails.exhibuuid,
	);
	const pendingExhibUuid = useSelector(
		state => state.corporateMembership.exhibitorCartDetails?.pending_payment_data?.exhib_uuid,
	);

	const oldExhibUuid = useSelector(
		state => state.corporateMembership.oldExhibitorUuid,
	);
	const clickBackCorporateMembership = () => {
		if (window.location.pathname.includes('/public/')) {
			//This is public_embed view
			history.push(`../../../company/${props.match.params.moduleUrlId}/staff`);
		} else {
			// This is an dashboard view
			history.push(`./staff`);
		}
	};

	const ssoLoginToFullApp = () => {
		if (window.location.pathname.includes('/public/')) {
			//This is public_embed view
			history.push(`../../../company/${props.match.params.moduleUrlId}/staff?token=${ssotoken}&uuid=${contactUuid}`);
		} else {
			// This is an dashboard view
			history.push(`./staff?token=${ssotoken}&uuid=${contactUuid}`);
		}
	};

	useAfterPaymentProcess();

	useEffect(() => {
		if (corporateMembership.exhibitorCartDetails.exhibuuid && corporateMembership.userCompanyAffiliation['comp_uuid']) {
			dispatch(requestExhibitorCart({
				compUuid: corporateMembership.userCompanyAffiliation['comp_uuid'],
				urlId: moduleUrlId,
				exhibUuid: corporateMembership.exhibitorCartDetails.exhibuuid,
			}));
		}
	}, [corporateMembership.exhibitorCartDetails.exhibuuid, corporateMembership.userCompanyAffiliation['comp_uuid']])

	useEffect(() => {
		if (appdir) {
			props.requestToFetchPaymentSettings({
				appDir: appdir,
				urlId: moduleUrlId,
			});
		}
	}, [appdir]);

	useEffect(() => {
		if (props.corporateMembership.userCompanyAffiliationFetched) {
			setLoader(false);
			if (props.corporateMembership.userCompanyAffiliation.comp_uuid) {
				setCompanyCreated(true);
			} else {
				props.history.push('home');
			}
		}
	}, [props.corporateMembership.userCompanyAffiliationFetched]);

	useEffect(() => {
		if (
			props.corporateMembership?.exhibitorCart?.PaymentItemsArray?.length > 0 ||
			props.corporateMembership?.oldExhibitorCart?.PaymentItemsArray?.length
		) {
			setExistingPayment(true);
		}
	}, [props.corporateMembership?.exhibitorCart, props.corporateMembership?.oldExhibitorCart]);

	const deletePayment = paymentId => {
		props.requestToDeleteExhibitorPayment({
			moduleUrlId,
			exhibUuid: props.corporateMembership.exhibitorCartDetails.exhibuuid,
			paymentId,
		});
	};

	const deleteItem = item => {
		// Redux action to be dispatched to delete cart item with that id.
		props.requestToDeleteRegistrationItemFromCart({
			form: {
				itemstodelete: {
					field_id: item.fieldId,
					invoice_id: item.invoiceId,
				},
			},
			urlId: moduleUrlId,
			exhibUuid: props.corporateMembership.exhibitorCartDetails.exhibuuid,
			compUuid: props.corporateMembership.userCompanyAffiliation.comp_uuid,
			authUser: authUser,
		});
	};

	const returnPaymentApproved = success => {
		setPaymentSuccessful(success);
	};

	const handleBackClick = () => {
		dispatch(requestIgnorePurchaseRequiredRedirect(true));
		props.requestToUpdateStepDetails({ currentStep: corporateMembership?.membershipStepDetails?.currentStep > 0?corporateMembership?.membershipStepDetails?.currentStep:  0 });
		props.history.goBack();
	};

	const onDownloadInvoice = () => {
		window.open(
			`https://masterapp.econference.io/masterapp_summer2012/apiv2/index.cfm?appdir=${appdir}&module=exhibitor&component=exhibitors&function=download_invoice&moduleurlid=${moduleUrlId}&compuuid=${compUuid}&exhibuuid=${oldExhibUuid ?? exhibUuid}`,
		);
	}
	return (
		<div>
			<Spin
				spinning={(isUpdateMemberAfterPayment && isPaymentLoader) || isPaymentLoader}
				tip={'Payment is Processing ...'}
				className="paymentLoader"
			>
				{!loader && (companyCreated || publicEmbed) && (
					<>
						<div
							style={{
								width:
									parseFloat(cartItems.NetOwing) !== 0 || width < mobileThreshold
										? '100%'
										: '50%',
							}}
						>
							<div
								className={
									parseFloat(cartItems.NetOwing) !== 0 ? `gx-d-lg-flex` : ''
								}
							>
								{
									<div
										style={{
											flexBasis: `${existingPayment ||
												parseFloat(cartItems.NetOwing) === 0
												? ''
												: '58%'
												}`,
										}}
									>
										{
											<div>
												{props.corporateMembership.paymentSettingsFetched &&
													props.corporateMembership
														.exhibitorCartFetched &&
													!loading &&
													!paymentSuccessful &&
													!paymentSuccess &&
													cartItems &&
													simpleinvoiceobj &&
													Object.keys(simpleinvoiceobj)?.length > 0 &&
													(!existingPayment ? (
														<div>
															<div>
																<div
																	style={{
																		textAlign: 'left',
																	}}
																>
																	<GoBack
																		handleBackClick={
																			handleBackClick
																		}
																	/>
																</div>
																<div
																	style={{ float: 'left' }}
																	className="gx-text-secondary gx-font-weight-semi-bold gx-page-title gx-ml-1"
																>
																	{
																		menu.find(
																			item =>
																				item.screentype ===
																				'Checkout',
																		)?.button
																	}
																</div>
																<BagIcon
																	className="fg-primary-dark-light"
																	style={{
																		float: 'right',
																		width: '3em',
																	}}
																/>
															</div>
															{/* cart gets reset after payment,
														so we keep the oldcart just to still display previous items to user.
														any new items will be added to the new cart */}
															<div className="gx-mb-3">
																<RegistrationItems
																	languageObject={{
																		invoice_items_header:
																			languageObject?.checkout_invoice_items_header,
																		invoice_amount_header:
																			languageObject?.checkout_invoice_amount_header,
																		total_invoiced_header:
																			languageObject?.checkout_total_invoiced_header,
																		payments_header:
																			languageObject?.checkout_payments_header,
																		non_processed_payment:
																			languageObject?.checkout_non_processed_payment,
																		net_owing_header:
																			languageObject?.checkout_net_owing_header,
																	}}
																	currentPaymentMethod={
																		currentPaymentMethod
																	}
																	cartItems={
																		props.corporateMembership
																			.oldExhibitorCart
																			? props
																				.corporateMembership
																				.oldExhibitorCart
																			: props
																				.corporateMembership
																				?.exhibitorCart
																	}
																	paymentSettings={
																		props.corporateMembership
																			.paymentSettings
																	}
																	renderPaymentComponent={value =>
																		setCurrentPaymentMethod(
																			value,
																		)
																	}
																	onDeletePayment={deletePayment}
																	onDelete={deleteItem}
																/>
															</div>
															{simpleinvoiceobj && (
																<div>
																	{Object.entries(
																		simpleinvoiceobj,
																	).length &&
																		parseInt(
																			cartItems?.NetOwing,
																		) === 0 && (
																			<div className="gx-mt-5">
																				<FreeItemPayment
																					moduleUrlId={
																						moduleUrlId
																					}
																					compuuid={
																						compUuid
																					}
																					exhibuuid={
																						exhibUuid
																					}
																					moduleIdentifier={
																						'corporateMembership'
																					}
																					paymentSettings={
																						props
																							.corporateMembership
																							.paymentSettings
																					}
																				/>
																			</div>
																		)}
																</div>
															)}

															<PaymentOptions
																renderPaymentComponent={value =>
																	setCurrentPaymentMethod(value)
																}
																languageObject={{
																	net_owing_header:
																		languageObject?.checkout_net_owing_header,
																	pay_now_by:
																		languageObject?.checkout_pay_now_by,
																}}
																currentPaymentMethod={
																	currentPaymentMethod
																}
																cartItems={
																	props.corporateMembership
																		.oldExhibitorCart ||
																	props.corporateMembership
																		.exhibitorCart
																}
																paymentSettings={
																	props.corporateMembership
																		.paymentSettings
																}
																moduleIdentifier={'exhibitor'}
															/>
														</div>
													) : (
														<div className="gx-mb-3">
															{' '}
															<Alert
																message="Last payment's status is pending"
																type="error"
															/>
														</div>
													))}
											</div>
										}
									</div>
								}
								<div>
									{showThankYouMessage ? (
										<div>
											<div
												dangerouslySetInnerHTML={{
													__html:
														props.corporateMembership.paymentSettings
															.approvedpaymentscreentext,
												}}
											/>
											<br />
											<Button
												className="login-form-button button-primary gx-mt-1 gx-ml-2"
												shape={'round'}
												onClick={onDownloadInvoice}
											>
												{' '}
												Download Invoice{' '}
											</Button>

											{!props.corporateMembership.generalSettings
												?.DisableAdditionalMembers && (
													<Button
														className="login-form-button button-primary gx-mt-1"
														shape={'round'}
														onClick={
															publicEmbed
																? ssoLoginToFullApp
																: clickBackCorporateMembership
														}
													>
														{' '}
														Manage Staff{' '}
													</Button>
												)}
										</div>
									) : (
										simpleinvoiceobj &&
										Object.keys(simpleinvoiceobj)?.length === 0 &&
										!paymentSuccessful && (
											<div
												style={{ width: 'fit-content' }}
												className="gx-d-flex"
											>
												<Typography.Title level={5} className="gx-m-2">
													You have no outstanding items.
												</Typography.Title>
												<Button
													className="login-form-button button-primary"
													shape={'round'}
													onClick={
														publicEmbed
															? ssoLoginToFullApp
															: clickBackCorporateMembership
													}
												>
													{' '}
													Manage Staff{' '}
												</Button>
											</div>
										)
									)}
								</div>
								{parseFloat(cartItems.NetOwing) !== 0 && (
									<div style={{ flexBasis: '42%' }}>
										{(currentPaymentMethod ||
											paymentSuccessful ||
											parseFloat(
												props.corporateMembership.exhibitorCart.NetOwing,
											) <= 0 ||
											parseFloat(
												props.corporateMembership.exhibitorCart
													.TotalPayment,
											) !== 0) &&
											props.corporateMembership.paymentSettingsFetched &&
											props.corporateMembership.exhibitorCartFetched &&
											!showThankYouMessage && (
												<div style={{ margin: publicEmbed ? '3%' : '' }}>
													<div
														className={
															existingPayment ||
																width < mobileThreshold
																? ''
																: 'gx-ml-5 gx-mr-4'
														}
													>
														<PayNow
															currentPaymentMethod={
																currentPaymentMethod
															}
															setCurrentPaymentMethod={
																setCurrentPaymentMethod
															}
															returnPaymentApproved={
																returnPaymentApproved
															}
															appdir={props.loginInfo.appdir}
															netOwing={parseFloat(
																props.corporateMembership
																	.exhibitorCart.NetOwing,
															)}
															cartItems={
																props.corporateMembership
																	.exhibitorCart
															}
															totalPaid={parseFloat(
																props.corporateMembership
																	.exhibitorCart.TotalPayment,
															)}
															paymentSettings={
																props.corporateMembership
																	.paymentSettings
															}
															moduleIdentifier={'exhibitor'}
															compuuid={
																props.corporateMembership
																	.userCompanyAffiliation
																	.comp_uuid
															}
															exhibuuid={
																props.corporateMembership
																	.exhibitorCartDetails.exhibuuid
															}
															publicEmbed={publicEmbed}
															status={status}
														/>
													</div>
												</div>
											)}
									</div>
								)}
								{/* </Col> */}
							</div>
							{/* </Row> */}
						</div>
					</>
				)}
				{loader && <CircularProgress className="gx-profile-content" />}
			</Spin>
		</div>
	);
};

function matchDispatchToProps(dispatch) {
	return bindActionCreators(
		Object.assign({
			requestToFetchPaymentSettings,
			requestToDeleteRegistrationItemFromCart,
			requestToDeleteExhibitorPayment,
			requestToUpdateStepDetails
		}),
		dispatch,
	);
}

function mapStateToProps(state) {
	return {
		corporateMembership: state.corporateMembership,
		loginInfo: state.loginInfo,
		settings: state.settings,
		auth: state.auth,
	};
}

export default connect(mapStateToProps, matchDispatchToProps)(ExhibitorCheckout);

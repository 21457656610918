import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory, Link } from 'react-router-dom';

import { useShoppingCart } from '../hooks/useShoppingCart';
import { useSettings } from '../hooks/useSettings';
import { getCheckoutRoute, getCheckoutPublicRoute, getBreadcrumbs } from '../helpers';

import { Button, Breadcrumb, Skeleton } from 'antd';

import { ReactComponent as CartCheck } from '../../../../assets/icons/cartCheck.svg';

const StoreHeader = ({ productId }) => {
	const history = useHistory();
	const { moduleUrlId } = useParams();
	const publicEmbed = useSelector(state => state.settings.publicEmbed);

	const [settingsLoading, storeName] = useSettings();
	const [, cartCount] = useShoppingCart();

	const products = useSelector(state => state.ecommerce.storeProducts);
	const breadcrumbs = !products.length ? [] : getBreadcrumbs(products, productId);

	const checkout = () => {
		history.push(
			publicEmbed ? getCheckoutPublicRoute(moduleUrlId) : getCheckoutRoute(moduleUrlId),
		);
	};

	return (
		<div className="gx-mb-4 gx-d-flex">
			<div>
				{settingsLoading ? (
					<Skeleton.Input style={{ width: 200 }} active className="gx-mb-2" />
				) : (
					!publicEmbed && <h1 className="gx-font-weight-semi-bold">{storeName}</h1>
				)}

				{breadcrumbs.length > 0 && (
					<Breadcrumb>
						<Breadcrumb.Item>
							<Link
								to={
									publicEmbed
										? `/public/ecommerce/${moduleUrlId}/selection${history.location.search}`
										: `/main/ecommerce/${moduleUrlId}/selection`
								}
							>
								Store Home
							</Link>
						</Breadcrumb.Item>
						{breadcrumbs.map(breadcrumb => (
							<Breadcrumb.Item key={breadcrumb.text}>
								<Link to={breadcrumb.link}>{breadcrumb.text}</Link>
							</Breadcrumb.Item>
						))}
					</Breadcrumb>
				)}
			</div>
			<Button
				disabled={parseInt(cartCount) > 0 ? false : true}
				onClick={checkout}
				className="gx-ml-auto button-primary"
				shape="round"
			>
				<div className="gx-d-flex gx-justify-content-center gx-align-items-center">
					<CartCheck className="gx-mr-2" style={{ width: '1.2em' }} />
					<p className="gx-mb-0">My Shopping Cart ({cartCount})</p>
				</div>
			</Button>
		</div>
	);
};

export default StoreHeader;

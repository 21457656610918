import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { requestStoreProducts } from '../../../../appRedux/actions/EcommerceStore';

export const useProducts = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const products = useSelector(state => state.ecommerce.storeProducts);
	const fetched = useSelector(state => state.ecommerce.storeProductsFetched);
	const appdir = useSelector(state => state.loginInfo.appdir);

	const { moduleUrlId } = useParams();

	const loading = !fetched;

	useEffect(() => {
		if (!appdir) return;
		if (fetched) return;
		dispatch(requestStoreProducts({ appdir, moduleUrlId })); // api call for all products and bundles
	}, [appdir]);

	return [loading, products];
};

import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, Button, Card } from 'antd';
import { requestToCreateCompany, requestToAddUserToCompany } from '@appRedux/actions';
import { useParams } from 'react-router';
import DuplicateCompanies from './DuplicateCompanies';
import { ReactComponent as FindCompanyIcon } from '../../../assets/icons/findCompany.svg';

const CreateCompany = props => {
	const [companyName, setCompanyName] = useState('');
	const isButtonDisabled = companyName === '';
	const [duplicateView, setDuplicateView] = useState(false);
	const [selectedCompanyUuid, setSelectedCompanyUuid] = useState('');
	const [duplicateCompaniesMessage, setDuplicateCompaniesMessage] = useState('');
	const [duplicateCompanies, setDuplicateCompanies] = useState([]);

	const { moduleUrlId } = useParams();
	const publicEmbed = useSelector(state => state.settings.publicEmbed);

	useEffect(() => {
		if (selectedCompanyUuid) {
			if (selectedCompanyUuid === 'NA') {
				props.requestToCreateCompany({
					CompanyName: companyName,
					run_add_new_company: true,
				});
			} else {
				props.requestToAddUserToCompany({
					form: {
						compUuid: selectedCompanyUuid,
						uuid: props.auth.contact_uuid,
						new_user_reg_to_company: 1,
					},
					urlId: moduleUrlId,
				});
			}

			if (!publicEmbed) {
				props.updateCompanyCreated(true);
			}
		}
	}, [selectedCompanyUuid]);

	const enterButtonPressed = () => {
		props.requestToCreateCompany({
			CompanyName: companyName,
			run_add_new_company: false,
		});
	};

	const handleBackClick = () => {
		setDuplicateView(false);
	};

	useEffect(() => {
		if (props.corporateMembership.companyCreated) {
			if (props.corporateMembership.createdCompany.DUPLICATE_COMPANY_ARRAY) {
				setDuplicateView(true);
				props.corporateMembership.createdCompany.DUPLICATE_COMPANY_ARRAY.push({
					companyName:
						props.corporateMembership.languageObject
							?.account_company_duplicate_creation_create_new_company_msg ??
						'None are applicable. Please create new company.',
					compUUID: 'NA',
				});
				setDuplicateCompanies(
					props.corporateMembership.createdCompany.DUPLICATE_COMPANY_ARRAY,
				);

				setDuplicateCompaniesMessage(props.corporateMembership.createdCompany.MESSAGE);
			} else {
				props.requestToCreateCompany({
					CompanyName: companyName,
					run_add_new_company: false,
				});
				if (!publicEmbed) {
					props.updateCompanyCreated(true);
				}
			}
		}
	}, [props.corporateMembership.createdCompany]);

	return (
		<div style={{ width: '75%' }}>
			{!duplicateView && (
				<Card className={!publicEmbed ? 'card shadow-2' : ''}>
					<div className="gx-mb-3 gx-d-flex">
						<div
							style={{ fontSize: '29px', fontWeight: 'bold' }}
							className="gx-text-secondary"
						>
							Enter Your Company Name
						</div>
						<div className="gx-ml-auto">
							<FindCompanyIcon style={{ width: '3em' }} />
						</div>
					</div>
					<Input
						className="gx-mb-3"
						style={{ maxWidth: '50%' }}
						placeholder={'Enter your Company Name here...'}
						onChange={event => {
							setCompanyName(event.currentTarget.value);
						}}
						value={companyName}
					/>
					<br />
					<Button
						disabled={isButtonDisabled}
						className={`login-form-button gx-mt-1 button-primary`}
						shape={'round'}
						style={{
							// to be replaced with shadow-hard
							boxShadow: '6px 5px 2px 1px rgba(45, 45, 45, 0.4)',
							border: 'transparent',
						}}
						onClick={enterButtonPressed}
					>
						Enter
					</Button>
				</Card>
			)}
			{duplicateView && duplicateCompanies.length > 0 && (
				<DuplicateCompanies
					handleBackClick={handleBackClick}
					duplicateCompaniesMessage={duplicateCompaniesMessage}
					duplicateCompanies={duplicateCompanies}
					setSelectedCompanyUuid={setSelectedCompanyUuid}
				/>
			)}
		</div>
	);
};

function matchDispatchToProps(dispatch) {
	return bindActionCreators(
		Object.assign({
			requestToCreateCompany: requestToCreateCompany,
			requestToAddUserToCompany: requestToAddUserToCompany,
		}),
		dispatch,
	);
}

function mapStateToProps(state) {
	return {
		auth: state.auth,
		corporateMembership: state.corporateMembership,
	};
}

export default connect(mapStateToProps, matchDispatchToProps)(CreateCompany);

import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import { PlayCircleFilled, PictureFilled, FilePdfFilled, LinkOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { FILE } from 'components/Common/constants';
import { ReactComponent as Left } from 'assets/icons/left.svg';
import VideoPlayer from './VideoPlayer';
import PDFViewer from './PDFViewer';
import ImageGallery from './ImageGallery';
import { storeFileAnalysisRequest } from '../../../../appRedux/actions';

export const renderFileIcon = (file) => {
    switch (file.type) {
        case FILE.PDF:
            return (
                <FilePdfFilled />
            );
        case FILE.VIDEO:
            return (
                <PlayCircleFilled />
            );
        case FILE.LINK:
            return (
                <LinkOutlined />
            );
        case FILE.IMAGE:
        default:
            return (
                <PictureFilled />
            );
    }
};

const FileList = ({ bundle, selectedFile, expanded, handleSelectFile }) => {
    const [isPublic, setIsPublic] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();

    const appdir = useSelector(state => state.loginInfo.appdir);
    const contactUid = useSelector(state => state.auth.contact_uuid);
    const consumptionConfig = useSelector(state => state.consumption.config);
    useEffect(() => {
        const params = new URLSearchParams(history.location.search);
        setIsPublic(!!params.get('public_embed'));
    }, [history]);

    useEffect(() => {
        // File Analysis api depends on file selection
        if (selectedFile) {
            dispatch(storeFileAnalysisRequest({
                appdir,
                contactUid,
                productId: bundle.id,
                title: selectedFile?.url ?? '',
                fileSize: selectedFile?.file_size ?? 0,
            }));
        }
    }, [selectedFile]);

    return (
        <div>
            {selectedFile?.type ? (
                <section>
                    {bundle?.files?.length > 1 ? (
                        <div className="gx-my-auto gx-pl-2 gx-bg-light-grey gx-d-flex gx-align-items-center">
                            <div
                                onClick={() => handleSelectFile(null)}
                                className="gx-p-3 gx-pointer gx-mr-2 gx-d-flex"
                            >
                                <Left className="gx-my-auto" style={{ width: '.9rem' }} />
                            </div>
                            <h3 className='gx-text-black gx-font-weight-semi-bold gx-mb-0 gx-ml-3'>
                                BACK TO ALL FILES
                            </h3>
                        </div>
                    ) : null}
                    <article
                        className="content-area"
                    >
                        <section className="bg-grey-light">
                            {selectedFile?.type === FILE.VIDEO && (
                                <VideoPlayer url={selectedFile.url} expanded={expanded} style={{ height: 'auto' }} />
                            )}
                            {selectedFile?.type === FILE.PDF && (
                                <PDFViewer
                                    url={selectedFile.url}
                                    download={selectedFile.download ?? consumptionConfig?.downloadPdf ?? false}
                                    fullscreen={!isPublic}
                                />
                            )}
                            {selectedFile?.type === FILE.IMAGE && (
                                <ImageGallery url={selectedFile.url} />
                            )}
                        </section>
                    </article>
                </section>
            ) : (
                <div className="gx-px-5 gx-pt-2">
                    {bundle?.files.map((item, index) => (
                        <div key={index}>
                            <Card onClick={() => handleSelectFile(item)} style={{ cursor: 'pointer' }}>
                                <div className='gx-d-flex'>
                                    <span className='filledSvg'>
                                        {renderFileIcon(item)}
                                    </span>
                                    <span className='gx-mx-3'>{item?.title}</span>
                                </div>
                            </Card>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
};

export default FileList;

import React from 'react';
import { Menu } from 'antd';
import auth from '../../loginAuth/loginAuth';
import { Link, useHistory } from 'react-router-dom';

import CustomScrollbars from 'util/CustomScrollbars';
import UserProfile from './UserProfile';

import {
	NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
	NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
	THEME_TYPE_LITE,
} from '../../constants/ThemeSetting';
import IntlMessages from '../../util/IntlMessages';
import { useDispatch, useSelector } from 'react-redux';

import { requestLogoutUser, requestEraseAuthData } from '../../appRedux/actions/Auth';
import { requestEraseProfileData } from '../../appRedux/actions/LoginPageInfo';
import { requestEraseEcommerceData } from '../../appRedux/actions/Ecommerce';
import { requestEraseCorporateMembershipData } from '../../appRedux/actions/CorporateMembership';
import { requestEraseCommonData } from '../../appRedux/actions/Common';
import { requestEraseUserProductsData } from '../../appRedux/actions/UserProducts';

import { ReactComponent as TemporaryCorporateMembership } from '../../assets/icons/TemporaryCorporateMembership.svg';

import { ReactComponent as ProfileHome } from '../../assets/icons/ProfileHome.svg';
import { ReactComponent as MyProducts } from '../../assets/icons/MyProducts.svg';
import { ReactComponent as ManageProfile } from '../../assets/icons/ManageProfile.svg';
import { ReactComponent as Logout } from '../../assets/icons/Logout.svg';

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = props => {
	let { navStyle, themeType, pathname } = useSelector(({ settings }) => settings);

	const ssoToken = useSelector(state => state.auth.ssotoken);
	const contactUuid = useSelector(state => state.auth.contact_uuid);
	const reviewerID = useSelector(state => state.loginInfo.reviewerID);
	const corporateMembershipModuleUrlId = useSelector(
		state => state.loginInfo.corporateMembershipModuleUrlId,
	);

	const dispatch = useDispatch();
	const history = useHistory();

	const onLogout = () => {
		auth.deleteCookie('jwt');
		auth.deleteCookie('ssotoken');
		auth.deleteCookie('contact_uuid');
		auth.deleteCookie('appdir');
		auth.deleteCookie('source_hex');

		dispatch(requestLogoutUser(props));
		dispatch(requestEraseProfileData(props));
		dispatch(requestEraseAuthData(props));
		dispatch(requestEraseEcommerceData(props));
		dispatch(requestEraseCorporateMembershipData(props));
		dispatch(requestEraseCommonData(props));
		dispatch(requestEraseUserProductsData(props));
		history.push('/signin');
	};

	const backToCorporateMembership = () => {
		history.push(`/main/company/${corporateMembershipModuleUrlId}/home`);
	};

	const getNoHeaderClass = navStyle => {
		if (
			navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
			navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
		) {
			return 'gx-no-header-notifications';
		}
		return '';
	};
	const getNavStyleSubMenuClass = navStyle => {
		if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
			return 'gx-no-header-submenu-popup';
		}
		return '';
	};
	const selectedKeys = pathname.substr(1);
	const defaultOpenKeys = selectedKeys.split('/')[1];

	const openOldProfileHome = () => {
		window
			.open(
				`https://www.xcdsystem.com/dfi/login.cfm?uuid=${contactUuid}&reviewid=${reviewerID}&token=${ssoToken}`,
			)
			.focus();
	};

	return (
		<>
			<div className="gx-sidebar-content">
				<div
					className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
					style={{ borderBottomColor: 'white' }}
				>
					<UserProfile />
				</div>
				<CustomScrollbars className="gx-layout-sider-scrollbar">
					<Menu
						defaultOpenKeys={[defaultOpenKeys]}
						selectedKeys={[selectedKeys]}
						theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
						mode="inline"
						style={{
							background: 'transparent',
							color: 'white',
						}}
					>
						<Menu.Item
							key="main/profilehome"
							style={{ color: 'white' }}
							onClick={openOldProfileHome}
						>
							{/* <Link to="/main/profilehome" style={{ color: 'white' }}> */}
							<div className="gx-d-flex gx-align-items-center">
								<ProfileHome
									className="gx-mr-2 gx-mt-1"
									style={{ width: '1.5rem', fill: 'white' }}
								/>
								<span>
									<IntlMessages id="sidebar.profileHome" />
								</span>
							</div>
							{/* </Link> */}
						</Menu.Item>

						<Menu.Item
							key="corporateMembership"
							onClick={() => backToCorporateMembership()}
						>
							<Link to={``} style={{ color: 'white' }}>
								<div className="gx-d-flex gx-align-items-center">
									<TemporaryCorporateMembership
										className="gx-mr-2 gx-mt-1"
										style={{ width: '1.5rem', fill: 'white' }}
									/>
									<span style={{ marginTop: '0.15rem' }}>
										<IntlMessages id="sidebar.corporateMembership" />
									</span>
								</div>
							</Link>
						</Menu.Item>
						{/* <Menu.Item key="main/consumption/selection">
							<Link to="/main/consumption/selection" style={{ color: 'white' }}>
								<div className="gx-d-flex gx-align-items-center">
									<MyProducts
										className="gx-mr-2 gx-mt-1"
										style={{ width: '1.5rem', fill: 'white' }}
									/>
									<span style={{ marginTop: '0.15rem' }}>
										<IntlMessages id="sidebar.myProducts" />
									</span>
								</div>
							</Link>
						</Menu.Item>

						<Menu.Item key="/main/updateaccount">
							<Link to="/main/updateaccount" style={{ color: 'white' }}>
								<div className="gx-d-flex gx-align-items-center">
									<ManageProfile
										className="gx-mr-2 gx-mt-1"
										style={{ width: '1.5rem', fill: 'white' }}
									/>
									<span style={{ marginTop: '0.15rem' }}>
										<IntlMessages id="sidebar.updateProfile" />
									</span>
								</div>
							</Link>
						</Menu.Item> */}
						<Menu.Item key="logOut" onClick={() => onLogout()}>
							<Link to={''} style={{ color: 'white' }}>
								<div className="gx-d-flex gx-align-items-center">
									<Logout
										className="gx-mr-2 gx-mt-1"
										style={{
											width: '1.5rem',
											fill: 'white',
										}}
									/>
									<span style={{ marginTop: '0.15rem' }}>
										<IntlMessages id="sidebar.logout" />
									</span>
								</div>
							</Link>
						</Menu.Item>
					</Menu>
				</CustomScrollbars>
			</div>
		</>
	);
};

SidebarContent.propTypes = {};
export default SidebarContent;

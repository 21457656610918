export const getCheckoutRoute = moduleUrlId => `/main/ecommerce/${moduleUrlId}/checkout`;

export const getCheckoutPublicRoute = moduleUrlId =>
	`/public/ecommerce/${moduleUrlId}/checkout?public_embed=1`;

export const getSelectionRoute = moduleUrlId => `/main/ecommerce/${moduleUrlId}/selection`;

export const getSelectionPublicRoute = moduleUrlId =>
	`/public/ecommerce/${moduleUrlId}/selection?public_embed=1`;

// strip text of <p> and </p> tags
export function stripPtags(text) {
	return text.replace(/<\/p>/gi, '\n\n')?.replace(/<p>/gi, '');
}

export function getBreadcrumbs(products, productId) {
	const product = products.find(prod => prod.id === productId);
	if (!product) return [];
	const isBundle = product.childrenIds.length > 0;
	const breadcrumb = {
		text: product.title,
		link: `../${isBundle ? 'bundle' : 'product'}/${product.id}`,
	};
	if (!product.parentIds.length) return [breadcrumb];
	const parentId = product.parentIds[0];
	return [...getBreadcrumbs(products, parentId), breadcrumb];
}

export const productsPerPage = 12;

import React from 'react';
import { useSelector } from 'react-redux';

import { useTags } from '../hooks/useTags';
import { useShoppingCart } from '../hooks/useShoppingCart';
import { useContentType } from '../hooks/useContentType';

import { Button, Tag, Card, Spin, Image } from 'antd';

import { ReactComponent as CartPlus } from '../../../../assets/icons/cartPlus.svg';
import { ReactComponent as Remove } from '../../../../assets/icons/remove.svg';
import { ReactComponent as PDF } from '../../../../assets/icons/pdf.svg';
import { ReactComponent as Book } from '../../../../assets/icons/book.svg';

import './selection.css';

const ProductCardList = ({ product, price, showProduct }) => {
	const [tagsLoading, tagsMap, getDisplayTagIds] = useTags();
	const [contentLoading, CONTENT, , getFascadeContentTypes] = useContentType();
	const [addToCart, , cart, removeFromCart] = useShoppingCart();
	const showTags = !useSelector(
		state =>
			state.ecommerce.attendeeSettings?.attendeesiteadmin?.generalconfigobject?.hide_all_tags,
	);
	const { styling = {} } = useSelector(({ loginInfo }) => loginInfo);

	const addedToCart = cart.some(invoice => invoice.field_id === price?.fieldid);

	const actionClicked = event => {
		event.stopPropagation();
		addedToCart ? removeFromCart(price) : addToCart(price);
	};

	return (
		<Card onClick={showProduct} hoverable size="large" className="gx-mb-0 card shadow-1" bodyStyle={{ padding: '0px' }}>
			{/* product type */}
			{/* <span className="category-ribbon">{product.category}</span> */}
			<div className="gx-d-flex" style={{ gap: '1.5em', padding: '24px' }}>
				<div style={{ flex: '0 0 auto' }}>
					{product.image
						? <Image
							className="card-image gx-mb-3"
							style={{ width: '10em' }}
							alt="placeholder"
							src={
								product.image ||
								require('../../../../assets/images/product-placeholder.png')
							}
							preview={false}
						/>
						: null
					}
					{/* product tags */}
					{/* <div>
						{!tagsLoading &&
							showTags &&
							getDisplayTagIds(product).map(tagId => (
								<Tag key={tagId} className="tag">
									{tagsMap[tagId] ?? tagId}
								</Tag>
							))}
					</div> */}

					{/* product content tags */}
					{/* <div className="gx-mt-2 gx-d-flex gx-align-items-center gx-ml-1">
						{!contentLoading &&
							getFascadeContentTypes(product).has(CONTENT.DIGITAL) && (
								<PDF className="gx-mr-2" style={{ width: '1.3em' }} />
							)}
						{!contentLoading && getFascadeContentTypes(product).has(CONTENT.PRINT) && (
							<Book className="gx-mr-2" style={{ width: '1.2em' }} />
						)}
					</div> */}
				</div>
				<section className="gx-d-flex gx-flex-column gx-flex-1">
					<div className="gx-d-flex">
						<div>
							{/* product title */}
							<h3>{price?.price_collection?.primary?.label || product?.title}</h3>
						</div>
					</div>
					<div className="gx-d-flex gx-flex-1">
						{/* product description */}
						<p
							className="gx-mb-0"
							dangerouslySetInnerHTML={{
								__html: product.description,
							}}
						/>

					</div>
				</section>
				<div className="gx-d-flex gx-flex-column">
					{/* teaser price */}
					<div className="product-price-details">
						{price?.price_collection?.teaser && (
							<Tag className="teaser-tag gx-mr-2">
								{price?.price_collection?.teaser?.price === 0
									? 'FREE'
									: `$${price?.price_collection?.teaser?.price}`}{' '}
								for members
							</Tag>
						)}
					</div>
					{/* product price */}
					<Button
						onClick={actionClicked}
						className="gx-mt-auto gx-ml-auto gx-mb-0 button-secondary button-round"
						shape={addedToCart ? "circle" : "round"}
					>
						{price?.price_collection?.primary
							? addedToCart
								? <Remove style={{ width: '0.9rem', margin: '0.65em 0' }} />
								: (
									<div className="gx-px-3 buy-btn-content">
										{price?.price_collection?.primary?.price === 0
											? 'FREE'
											: `BUY $${price?.price_collection?.primary?.price}`}
									</div>
								) : (
								<Spin className="gx-px-5 gx-mr-0 gx-mb-0 gx-text-right" size="small" />
							)}
					</Button>
				</div>
			</div>
			<div className="bottom-price-bar" style={{ backgroundColor: styling['primary-dark-color'] || "#993333" }}>
				{product?.grandChildrenCount
					? (<div className="gx-text-center gx-d-flex gx-align-items-center gx-justify-content-center">
						<CartPlus style={{ width: '1.2em', fill: '#ffffff' }} />
						<span className="gx-ml-1 gx-font-weight-normal">{product?.grandChildrenCount} Items</span>
					</div>)
					: null}
			</div>
		</Card>
	);
};

export default ProductCardList;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Button, Card } from 'antd';
import { connect, useSelector, useDispatch } from 'react-redux';

import { requestSendPayment, requestSendPaymentExhibitor } from '../../appRedux/actions/Common';
import { ReactComponent as ItemIcon } from '../../assets/icons/item.svg';
import { useHistory } from 'react-router-dom';

const FreeItemPayment = props => {
	const { moduleUrlId } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();
	const [paymentApproved, setPaymentApproved] = useState(false);
	const appdir = useSelector(state => state.loginInfo.appdir);
	const ssotoken = useSelector(state => state.auth.ssotoken);
	const contactUuid = useSelector(state => state.auth.contact_uuid);
	const publicEmbed =
		useSelector(state => state.settings.publicEmbed) ||
		window.location.search.includes('public_embed=1'); // on the refresh the redux doesn't set public_embed, need windows.location.search for safety

	const submitFreePayment = () => {
		const formData = new FormData();
		formData.append('paymentType', 'no_purchase_order');
		formData.append('amount', 0.0);
		formData.append('CURRENCYID', 1);

		if (props.moduleIdentifier === 'ecommerce') {
			dispatch(
				requestSendPayment({
					appdir,
					moduleUrlId,
					attenuuid: props.attenuuid,
					formData,
				}),
			);
		} else if (props.moduleIdentifier === 'corporateMembership') {
			dispatch(
				requestSendPaymentExhibitor({
					appdir,
					moduleUrlId,
					compuuid: props.compuuid,
					exhibuuid: props.exhibuuid,
					formData,
				}),
			);
		}
		if (props.returnFreePaymentApproved) {
			props.returnFreePaymentApproved(true);
		}
	};
	const onDownloadInvoice = () => {
		if (props.moduleIdentifier === 'ecommerce' || props.moduleIdentifier === 'registration') {
			window.open(
				`https://masterapp.econference.io/masterapp_summer2012/apiv2/index.cfm?appdir=${appdir}&module=attendee&component=attendees&function=download_invoice&moduleurlid=${moduleUrlId}&attenuuid=${
					props.attenuuid ? props.attenuuid : props.ecommerce.oldAttenuuid
				}`,
			);
		} else if (props.moduleIdentifier === 'exhibitor') {
			window.open(
				`https://masterapp.econference.io/masterapp_summer2012/apiv2/index.cfm?appdir=${appdir}&module=exhibitor&component=exhibitors&function=download_invoice&moduleurlid=${moduleUrlId}&compuuid=${props.compuuid}&exhibuuid=${props.exhibuuid}`,
			);
		}
	};
	const clickBackCorporateMembership = () => {
		if (window.location.pathname.includes('/public/')) {
			//This is public_embed view
			history.push(`../../../company/${props.match.params.moduleUrlId}/staff`);
		} else {
			// This is an dashboard view
			history.push(`./staff`);
		}
	};

	const ssoLoginToFullApp = () => {
		if (window.location.pathname.includes('/public/')) {
			//This is public_embed view
			history.push(`../../../company/${props.match.params.moduleUrlId}/staff?token=${ssotoken}&uuid=${contactUuid}`);
		} else {
			// This is an dashboard view
			history.push(`./staff?token=${ssotoken}&uuid=${contactUuid}`);
		}
	};
	useEffect(() => {
		if (props.common.paymentSuccess) {
			setPaymentApproved(true);
		}
	}, [props.common.paymentSuccess]);
	return (
		<div>
			{paymentApproved ? (
				<Card className="card">
					<ItemIcon
						className="fg-primary-dark-light"
						style={{
							float: 'right',
							width: 'min(100%, 4em)',
						}}
					/>
					{props.paymentSettings !== undefined &&
					Object.keys(props.paymentSettings).length !== 0 ? (
						<div
							dangerouslySetInnerHTML={{
								__html: props.paymentSettings.approvedpaymentscreentext,
							}}
						></div>
					) : (
						<div>
							<p>Thank you, your order has been recorded.</p>
							<br />
							<p>
								To access your digital publication, please visit your profile and
								view your purchases.
							</p>
						</div>
					)}

					{props.moduleIdentifier === 'exhibitor' ? (
						<div style={{ display: 'flex' }}>
							<Button
								className="login-form-button gx-mt-1 button-primary"
								shape={'round'}
								onClick={onDownloadInvoice}
							>
								Download Invoice
							</Button>
							<Button
								className="login-form-button gx-mt-1 button-primary"
								shape={'round'}
								onClick={
									publicEmbed ? ssoLoginToFullApp : clickBackCorporateMembership
								}
							>
								Manage Staff
							</Button>
						</div>
					) : (
						<Button
							className="login-form-button gx-mt-1 button-primary"
							shape={'round'}
							onClick={onDownloadInvoice}
						>
							Download Invoice
						</Button>
					)}
				</Card>
			) : (
				<Button
					className="login-form-button gx-mt-1 button-primary"
					shape={'round'}
					onClick={submitFreePayment}
				>
					Complete Purchase
				</Button>
			)}
		</div>
	);
};
const mapStateToProps = state => {
	return {
		common: state.common,
		ecommerce: state.ecommerce,
	};
};
export default connect(mapStateToProps)(FreeItemPayment);

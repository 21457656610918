import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { Input } from 'antd';
import _debounce from 'lodash/debounce';
import { ReactComponent as Search } from '../../../assets/icons/search.svg';
import './index.css';

const SearchBox = ({ currentRefinement, refine }) => {
	const onSearch = _debounce((value) => {
		refine(value);
	}, 1000);
	const handleSearch = (event) => {
		event.persist();
		onSearch(event.target.value);
	};
	return (
		<Input
			defaultValue={currentRefinement}
			prefix={<Search style={{ width: '1em' }} />}
			onChange={handleSearch}
			size="large"
			placeholder="Search..."
			allowClear
			addonAfter={false}
			className="gx-mb-0 searchbox"
			style={{ width: '100%' }}
		/>
	);
}

export default connectSearchBox(SearchBox);

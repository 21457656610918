import React, { useState, useRef, useLayoutEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _reduce from 'lodash/reduce';
import _groupBy from 'lodash/groupBy';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import { PlayCircleOutlined, PictureOutlined, FilePdfOutlined } from '@ant-design/icons'

import { useBundleInfo } from '../hooks/useBundleInfo';
import { useTags } from '../hooks/useTags';
import { useShoppingCart } from '../hooks/useShoppingCart';
import { FILE } from 'components/Common/constants';

import BundleProductCard from './BundleProductCard';
import { Card, Button, Spin, Popover, Divider } from 'antd';

// import { ReactComponent as BundleRibbon } from '../../../../assets/graphics/bundleRibbon.svg';
import { ReactComponent as Remove } from '../../../../assets/icons/remove.svg';

import './bundle.css';

const BundleProductRender = ({ product, heightChanged, handleSelectBundle = () => { } }) => {
	const [showing, setShowing] = useState(0);
	const collapsible = useRef();
	const [collapsibleShow, setCollapsibleShow] = useState(false);
	const [collapsibleHeight, setCollapsibleHeight] = useState(0);
	const [addToCart, , cart, removeFromCart] = useShoppingCart();
	const [, tagsMap] = useTags();
	const [
		,
		children,
		productPrice,
		,
		totalPrice,
		,
		savingsPercentage,
		pricesLoading,
	] = useBundleInfo(product.id);
	const showTags = !useSelector(
		state =>
			state.ecommerce.attendeeSettings?.attendeesiteadmin?.generalconfigobject?.hide_all_tags,
	);
	const { styling } = useSelector(({ loginInfo }) => loginInfo);

	const showingIncrement = 3;
	const collapseDuration = 400; // transition duration in miliseconds

	const addedToCart = useMemo(
		() => cart.some(invoice => invoice.field_id === productPrice?.fieldid),
		[cart],
	);

	useLayoutEffect(() => {
		if (!collapsibleShow) return;
		setCollapsibleHeight(collapsible.current.scrollHeight);
		heightChanged && heightChanged(collapsible.current.scrollHeight); // optional chaining not allowed here for some reason
		setCollapsibleShow(false);
	});

	const constructItemCount = () => {
		let stateCount = _reduce(children, (total, child) => {
			let contents = _groupBy(child.files, 'type');
			let temp = { ...total };
			_forEach(contents, (content, key) => {
				temp[key] = (total[key] || 0) + content.length;
			});
			return _isEmpty(contents) ? total : temp;
		}, {});
		return stateCount;
	};
	const productCount = useMemo(constructItemCount, [children]);

	if (product.childrenIds.length === 0) {
		// indiv product
		return (
			<BundleProductCard
				key={product.id}
				product={product}
				price={productPrice}
				heightChanged={delta => heightChanged?.(delta)}
				collapseDuration={collapseDuration}
				priceLoading={pricesLoading}
			/>
		);
	}

	const productTitle = <h2 className="gx-mb-2 gx-mr-2 bundle-title gx-font-weight-semi-bold">{product.title}</h2>;
	const hasPrice = !pricesLoading && productPrice?.price_collection;

	return (
		<div className="gx-my-3">
			<Card
				style={{ borderLeft: `6px solid ${styling['primary-dark-color']}`, borderRadius: '5px' }}
				className="gx-mb-0 card-padding-0 shadow-2"
			>
				{/* {hasPrice && <BundleRibbon className="bundle-ribbon" />} */}
				<div className="bundle-card-wrapper">
					<div className="gx-px-4 gx-pt-3 gx-pb-2 gx-d-flex gx-justify-content-between bundle-card-content">
						{productTitle}
						<div className="product-card-btn-wrapper">
							{/* description popover */}
							{product.description
								? (
									<Popover
										trigger="click"
										content={
											<p
												dangerouslySetInnerHTML={{
													__html: product.description,
												}}
												style={{
													maxWidth: '40em',
												}}
											></p>
										}
									>
										<Button
											className="gx-ml-auto button-secondary gx-w-100"
											shape="round"
										>
											MORE INFO
										</Button>
									</Popover>
								) : null
							}
						</div>
					</div>
					<div
						className={"gx-mx-4 gx-mb-2 gx-d-flex gx-align-items-end gx-justify-content-between bundle-card-content"}
					>
						<div>
							{Object.keys(productCount).length || children
								? (
									<div style={{ minWidth: '30rem' }}>
										<Divider orientation="left" plain>
											Includes
										</Divider>
										<div className="bundle-text-content">
											{Object.keys(productCount).length ? (<div>
												<span>
													{_map(productCount, (count, key) => (
														<span key={key}>
															{key === FILE.PDF &&
																<span className='gx-ml-2'><FilePdfOutlined /> {count} {key.toUpperCase()}</span>
															}
															{key === FILE.VIDEO &&
																<span className='gx-ml-2'><PlayCircleOutlined /> {count} {key}</span>
															}
															{key === FILE.IMAGE &&
																<span className='gx-ml-2'><PictureOutlined /> {count} {key}</span>
															}
														</span>
													))}
												</span>
											</div>) : null}
											{children && (<div className="gx-mt-3">
												<Button
													size={"small"}
													onClick={() => {
														handleSelectBundle(product);
													}}
												>
													Browse Content
												</Button>
											</div>)}
										</div>

									</div>

								) : null
							}
						</div>
						<div className="product-card-btn-wrapper gx-mb-1 gx-mt-3">
							<div className="product-price-details gx-d-flex gx-justify-content-center">
								{/* {productPrice?.price_collection?.teaser && (
									<Tag className="teaser-tag">
										{productPrice?.price_collection?.teaser?.price === 0
											? 'FREE'
											: `$${productPrice?.price_collection?.teaser?.price}`}{' '}
										for members
									</Tag>
								)} */}
							</div>
							{hasPrice && (
								<Button
									className="button-primary gx-w-100"
									shape="round"
									onClick={(e) => (addedToCart ? removeFromCart(productPrice) : addToCart(productPrice))}
								>
									<div className="gx-d-flex gx-justify-content-center gx-align-items-center">
										{pricesLoading ? (
											<Spin className="gx-ml-1 gx-mt-2" size="small" />
										) : addedToCart ? (
											<p>
												<Remove className="gx-mr-2" style={{ width: '0.9em' }} />
												Remove
											</p>
										) : (
											productPrice?.price_collection && (
												<>
													<p className="gx-mb-0">
														{productPrice.price_collection.primary.price === 0
															? 'FREE'
															: `BUY $${productPrice.price_collection.primary.price}`}
													</p>

													{/* {productPrice.price_collection.primary.price !== totalPrice && (
														<>
															<h4 className="gx-mr-3 gx-text-strikethrough gx-text-grey gx-mb-0">
																${totalPrice}
															</h4>
															<Tag className="gx-mb-0 bundle-tag">
																Save {savingsPercentage}%
															</Tag>
														</>
													)} */}
												</>
											)
										)}
									</div>
								</Button>
							)}
						</div>
					</div>
				</div>
			</Card>

			{/* {children && (
				<>
					<div
						ref={collapsible}
						className="bundle-children gx-pl-4 gx-mr-4 gx-ml-4"
						style={{
							height: `${collapsibleHeight}px`,
							overflow: 'hidden',
							transition: `height ${collapseDuration}ms ease-in-out`,
						}}
					>
						{children.slice(0, showing).map(child => (
							<BundleProductRender
								key={child.id}
								product={child}
								heightChanged={delta =>
									setCollapsibleHeight(height => height + delta)
								}
							/>
						))}
					</div> */}

			{/* <Card className="card-border-bottom-radius card-padding-0 gx-px-4 gx-py-2 gx-m-0 shadow-2"> */}
			{/* {showing < children.length && (
							<Button
								onClick={() => {
									setCollapsibleShow(true);
									setShowing(showing =>
										Math.min(showing + showingIncrement, children.length),
									);
								}}
								type="link"
								className="gx-mb-0 fg-grey gx-px-0 gx-mr-4"
								icon={<CaretDownOutlined />}
							>
								<p className="underline gx-d-inline-block gx-ml-2">
									Show {showing > 0 ? 'More' : 'Products'} (
									{children.length - showing})
								</p>
							</Button>
						)}
						{showing > 0 && (
							<Button
								onClick={() => {
									heightChanged && heightChanged(-collapsibleHeight);
									setCollapsibleHeight(0);
									setTimeout(() => setShowing(0), collapseDuration); // wait for closing transition to end b4 removing elements frm dom
								}}
								type="link"
								className="gx-mb-0 fg-grey gx-px-0"
								icon={<CaretUpOutlined />}
							>
								<p className="underline gx-d-inline-block gx-ml-2">Hide all</p>
							</Button>
						)} */}

			{/* tags */}
			{/* {showTags &&
							product.tagIds.map(
								tagId =>
									tagsMap[tagId] && (
										<Tag className="tag" key={tagId}>
											{tagsMap[tagId]}
										</Tag>
									),
							)} */}
			{/* </Card> */}
			{/* </>
			)} */}
		</div>
	);
};

export default BundleProductRender;

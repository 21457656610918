import React, { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Divider, Typography, Avatar } from 'antd';
import { PlayCircleOutlined, PictureOutlined, FilePdfOutlined, UserOutlined, DoubleRightOutlined } from '@ant-design/icons';
import _reduce from 'lodash/reduce';
import _groupBy from 'lodash/groupBy';
import _forEach from 'lodash/forEach';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';

import { useProducts } from '../hooks/useProducts';
import { FILE } from 'components/Common/constants';

/**
 * Convert a template string into HTML DOM nodes
 * @param  {String} str The template string
 * @return {Node}       The template HTML
 */
const stringToHTML = function (str) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    return doc.body;
};

const extractText = function (str = '') {
    const elements = stringToHTML(str);
    return elements.innerText;
};

const { Paragraph, Text } = Typography;

const BundleProductCard = ({ product, goto }) => {
    const [prodsLoading, products] = useProducts();
    const bundle = useMemo(() => products.find(prod => prod.id === parseInt(product.id)), [products, product.id]);
    const [authorInfo, setAuthorInfo] = useState({});

    const { styling } = useSelector(({ loginInfo }) => loginInfo);

    useEffect(() => {
        if (product && product.authorInfo && product.authorInfo.length) {
            let data = _filter(product.authorInfo, (prod) => prod.presenter)[0];
            setAuthorInfo(data);
        }
    }, [product]);

    const getChildProducts = (bundleInfo) => {
        let childData = [];
        if (bundleInfo?.files?.length) {
            childData = [bundleInfo];
        }
        if (bundleInfo?.childrenIds?.length) {
            _forEach(_filter(products, (p) => bundleInfo?.childrenIds.includes(p.id)), (p) => {
                childData = [...childData, ...getChildProducts(p)]
            });
        }
        return childData;
    };

    const constructItemCount = () => {
        const children = getChildProducts(bundle);
        let stateCount = _reduce(children, (total, child) => {
            let contents = _groupBy(child.files, 'type');
            let temp = { ...total };
            _forEach(contents, (content, key) => {
                temp[key] = (total[key] || 0) + content.length;
            });
            return temp;
        }, {});
        return stateCount;
    };
    const productCount = useMemo(constructItemCount, [bundle, products]);

    const handleSelectBundle = () => { };

    const handleonExpand = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <div>
            <Card
                style={{ borderLeft: `6px solid ${styling?.['primary-dark-color']}`, borderRadius: '5px' }}
                className="gx-mb-0 card-padding-0 shadow-2 gx-pointer"
                onClick={() => {
                    goto(bundle, product);
                }}
            >
                <div className={Object.keys(productCount)?.length ? "bundle-card-wrapper" : "gx-pb-1"}>
                    <div className="gx-px-4 gx-pt-3 gx-pb-2 gx-d-flex gx-justify-content-between bundle-card-content">
                        <h2 className="gx-mb-2 gx-mr-2 bundle-title gx-font-weight-semi-bold">{product.title}</h2>
                        <div className="product-card-btn-wrapper">
                        </div>
                    </div>
                    {product?.description && (
                        <div className="gx-px-4 gx-pt-1 gx-pb-1 description-text">
                            <Paragraph
                                ellipsis={{
                                    rows: 3,
                                    expandable: true,
                                    symbol: <span className='gx-text-bold gx-text-black'>{`More `}<DoubleRightOutlined style={{ fontSize: '11px' }} /></span>,
                                    onExpand: handleonExpand
                                }}
                            >
                                {extractText(product?.description)}
                            </Paragraph>
                        </div>)
                    }
                    {!_isEmpty(authorInfo)
                        ? (<div className="gx-px-4 gx-py-1 description-text gx-d-flex gx-align-items-center">
                            <Avatar size="medium" src={authorInfo.picture} icon={<UserOutlined />} />
                            <div className='gx-ml-2'>{`${authorInfo.firstname} ${authorInfo.lastname}`}</div>
                            {authorInfo.degrees ? <div>{`, ${authorInfo.degrees}`}</div> : null}
                        </div>)
                        : null
                    }
                    {Object.keys(productCount)?.length ? (
                        <div
                            className={"gx-mx-4 gx-mb-2 gx-d-flex gx-align-items-end gx-justify-content-between bundle-card-content"}
                        >
                            <div>
                                <div style={{ minWidth: '30rem' }}>
                                    <Divider orientation="left" plain>
                                        Includes
                                    </Divider>
                                    <div className="bundle-text-content gx-mb-2">
                                        <div>
                                            <span>
                                                {_map(productCount, (count, key) => (
                                                    <span key={key}>
                                                        {key === FILE.PDF &&
                                                            <span className='gx-ml-2'><FilePdfOutlined /> {count} {key.toUpperCase()}</span>
                                                        }
                                                        {key === FILE.VIDEO &&
                                                            <span className='gx-ml-2'><PlayCircleOutlined /> {count} {key}</span>
                                                        }
                                                        {key === FILE.IMAGE &&
                                                            <span className='gx-ml-2'><PictureOutlined /> {count} {key}</span>
                                                        }
                                                    </span>
                                                ))}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </Card>
        </div>
    );
};

export default BundleProductCard;

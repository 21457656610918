import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { InstantSearch, Configure } from 'react-instantsearch-dom';

import { Drawer, Alert } from 'antd';

import StateResults from '../components/StateResults';
import SearchBox from 'components/Common/Algolia/SearchBox';
import Pagination from 'components/Common/Algolia/Pagination';
import FilterSidebar from '../components/FilterSidebar';
import Header from '../components/Header';

import { useProducts } from '../hooks/useProducts';
import { useSearchClient } from '../hooks/useSearchClient';

import { hitsPerPage } from '../constants';


import '../styles/custom.less';

const SelectionPage = () => {
	const history = useHistory();
	const [prodsLoading, products] = useProducts();
	const searchClient = useSearchClient(prodsLoading, products);
	const [showFilters, setShowFilters] = useState(false);
	const query = useSelector(state => state.router.location.query);

	const publicEmbed = query.public_embed === '1';

	const gotoProduct = (product, parent) => {
		let route = '';
		if (product.isSuperBundle) route = `selection/${product.id}`; // path for super bundle
		else if (product.isBundle && !product.parentId) route = `bundle/${product.id}`; // path for independent bundle
		else if (product.isBundle && product.parentId) route = `group/${product.parentId}/${product.id}`; // path for bundle related to the superBundle
		else if (product.grandparentId && product.parentId && product.grandparentId !== product.parentId) route = `group/${product.grandparentId}/${product.parentId}/${product.id}`; // path for product which has both parent and grand parent.
		else if (product.parentId && !parent?.isSuperBundle) route = `bundle/${product.parentId}/${product.id}`; // path for products which has parent but no grand parent
		else route = `product/${product.id}`;
		history.push(`${route}${history.location.search}`);
	};

	if (!prodsLoading && (!products || products.length === 0)) {
		return (
			<div className="gx-mt-5">
				<Alert message="You currently do not have any products purchased." type="warning" />
			</div>
		);
	}

	const onPageChange = () => {
		if (window.parent && window.parent.scrollTo) {
			window.parent.scrollTo(0, 0);
		} else {
			window.scrollTo(0, 0);
		}
	};

	return (
		<InstantSearch indexName="consumption/selection" searchClient={searchClient}>
			<main className="gx-p-5">
				{!publicEmbed && <Header />}

				<section className="gx-d-flex" style={{ overflow: 'visible', gap: '1.5em' }}>
					<Drawer
						placement="left"
						visible={showFilters}
						onClose={() => setShowFilters(false)}
					>
						<div className="gx-algolia-sidebar gx-mx-0 gx-p-4">
							<FilterSidebar categories tags={false} />
						</div>
					</Drawer>

					<div className="gx-flex-1" style={{ overflow: 'visible' }}>
						<div className="gx-mb-4 gx-d-flex">
							<FilterSidebar categories tags={false} />
							<SearchBox />
						</div>

						<div className="gx-mb-4">
							<StateResults loading={prodsLoading} gotoProduct={gotoProduct} />
						</div>

						<Configure hitsPerPage={hitsPerPage} />

						<div className="gx-text-center gx-pt-3">
							<Pagination onPageChange={onPageChange} />
						</div>
					</div>
				</section>
			</main>
		</InstantSearch>
	);
};

export default SelectionPage;

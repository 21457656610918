import {
	HIDE_MESSAGE,
	INIT_URL,
	ON_HIDE_LOADER,
	ON_SHOW_LOADER,
	SHOW_MESSAGE,
	SIGNIN_USER,
	SIGNIN_USER_SUCCESS,
	SIGNOUT_USER,
	SIGNOUT_USER_SUCCESS,
	SIGNUP_USER,
	SIGNUP_USER_SUCCESS,
	SIGNIN_USER_EMAIL,
	FORGOT_PASSWORD,
	FIND_ACCOUNT,
	REQUEST_SIGNIN_TOKEN,
	RECEIVE_SIGNIN_TOKEN,
	SET_AUTH_USER,
	SET_AUTH_USER_SUCCESS,
	REQUEST_AUTHENTICATE_USER,
	RECEIVE_AUTHENTICATE_USER,
	REQUEST_LOGOUT_USER,
	RECEIVE_LOGOUT_USER,
	REQUEST_RESET_PASSWORD,
	RECEIVE_RESET_PASSWORD,
	REQUEST_SEND_EMAIL,
	RECEIVE_SEND_EMAIL,
	REQUEST_SEND_PASSCODE,
	RECEIVE_SEND_PASSCODE,
	REQUEST_VALIDATE_EMAIL,
	RECEIVE_VALIDATE_EMAIL,
	REQUEST_CLEAR_VALIDATE_EMAIL,
	RECEIVE_CLEAR_VALIDATE_EMAIL,
	REQUEST_ERASE_AUTH_DATA,
	RECEIVE_ERASE_AUTH_DATA,
	REQUEST_DELETE_RESET_PASSWORD_MSG,
	RECEIVE_DELETE_RESET_PASSWORD_MSG,
	EXHIBITOR_AUTH_SIGNEDUP,
	REQUEST_CHECKOUT_CREATE_PROFILE,
	RECEIVE_CHECKOUT_CREATE_PROFILE,
	REQUEST_MAGIC_LOGIN,
	RECEIVE_MAGIC_LOGIN,
} from 'constants/ActionTypes';

export const findAccount = user => {
	return {
		type: FIND_ACCOUNT,
		payload: user,
	};
};
export const userSignUp = user => {
	return {
		type: SIGNUP_USER,
		payload: user,
	};
};
export const userSignIn = user => {
	return {
		type: SIGNIN_USER,
		payload: user,
	};
};
export const userSignOut = () => {
	return {
		type: SIGNOUT_USER,
	};
};
export const userForgotPassword = user => {
	return {
		type: FORGOT_PASSWORD,
		payload: user,
	};
};
export const userSignInEmail = user => {
	return {
		type: SIGNIN_USER_EMAIL,
		payload: user,
	};
};
export const userSignUpSuccess = authUser => {
	return {
		type: SIGNUP_USER_SUCCESS,
		payload: authUser,
	};
};
export const userSignInSuccess = auth => {
	return {
		type: SIGNIN_USER_SUCCESS,
		payload: auth,
	};
};
export const userSignOutSuccess = () => {
	return {
		type: SIGNOUT_USER_SUCCESS,
	};
};
export const showAuthMessage = message => {
	return {
		type: SHOW_MESSAGE,
		payload: message,
	};
};

export const setInitUrl = url => {
	return {
		type: INIT_URL,
		payload: url,
	};
};

export const showAuthLoader = () => {
	return {
		type: ON_SHOW_LOADER,
	};
};

export const hideMessage = () => {
	return {
		type: HIDE_MESSAGE,
	};
};

export const hideAuthLoader = () => {
	return {
		type: ON_HIDE_LOADER,
	};
};

export const requestSigninToken = data => {
	return {
		type: REQUEST_SIGNIN_TOKEN,
		payload: data,
	};
};

export const receiveSigninToken = data => {
	return {
		type: RECEIVE_SIGNIN_TOKEN,
		payload: data,
	};
};

export const requestAuthUser = data => {
	return {
		type: SET_AUTH_USER,
		payload: data,
	};
};

export const receiveAuthUser = data => {
	return {
		type: SET_AUTH_USER_SUCCESS,
		payload: data,
	};
};

export const requestAuthenticateUser = data => {
	return {
		type: REQUEST_AUTHENTICATE_USER,
		data,
	};
};

export const receiveAuthenticateUser = data => {
	return {
		type: RECEIVE_AUTHENTICATE_USER,
		payload: data,
	};
};

export const requestLogoutUser = data => {
	return {
		type: REQUEST_LOGOUT_USER,
		payload: data,
	};
};

export const receiveLogoutUser = data => {
	return {
		type: RECEIVE_LOGOUT_USER,
		payload: data,
	};
};

export const requestResetPassword = data => {
	return {
		type: REQUEST_RESET_PASSWORD,
		data,
	};
};

export const receiveResetPassword = data => {
	return {
		type: RECEIVE_RESET_PASSWORD,
		payload: data,
	};
};

export const requestSendEmail = data => {
	return {
		type: REQUEST_SEND_EMAIL,
		data,
	};
};

export const receiveSendEmail = data => {
	return {
		type: RECEIVE_SEND_EMAIL,
		payload: data,
	};
};

export const requestMagicLogin = data => {
	return {
		type: REQUEST_MAGIC_LOGIN,
		data,
	};
};

export const receiveMagicLogin = data => {
	return {
		type: RECEIVE_MAGIC_LOGIN,
		payload: data,
	};
};

export const requestSendPasscode = data => {
	return {
		type: REQUEST_SEND_PASSCODE,
		data,
	};
};

export const receiveSendPasscode = data => {
	return {
		type: RECEIVE_SEND_PASSCODE,
		payload: data,
	};
};

export const requestValidateEmail = data => {
	return {
		type: REQUEST_VALIDATE_EMAIL,
		data,
	};
};

export const receiveValidateEmail = data => {
	return {
		type: RECEIVE_VALIDATE_EMAIL,
		payload: data,
	};
};

export const requestClearValidateEmail = data => {
	return {
		type: REQUEST_CLEAR_VALIDATE_EMAIL,
		data,
	};
};

export const receiveClearValidateEmail = data => {
	return {
		type: RECEIVE_CLEAR_VALIDATE_EMAIL,
		payload: data,
	};
};

export const requestEraseAuthData = data => {
	return {
		type: REQUEST_ERASE_AUTH_DATA,
		payload: data,
	};
};

export const receiveEraseAuthData = data => {
	return {
		type: RECEIVE_ERASE_AUTH_DATA,
		payload: data,
	};
};

export const requestDeleteResetPasswordMsg = data => {
	return {
		type: REQUEST_DELETE_RESET_PASSWORD_MSG,
		payload: data,
	};
};

export const receiveDeleteResetPasswordMsg = data => {
	return {
		type: RECEIVE_DELETE_RESET_PASSWORD_MSG,
		payload: data,
	};
};

export const exhibitorAuthSignUpSuccess = data => {
	return {
		type: EXHIBITOR_AUTH_SIGNEDUP,
		payload: data,
	};
};

export const requestCheckoutCreateProfile = data => {
	return {
		type: REQUEST_CHECKOUT_CREATE_PROFILE,
		data,
	};
};

export const receiveCheckoutCreateProfile = data => {
	return {
		type: RECEIVE_CHECKOUT_CREATE_PROFILE,
		payload: data,
	};
};

import {
	REQUEST_PREV_SCREEN,
	REQUEST_MENU_OBJECTS,
	REQUEST_ATTENDEE_ARRAY,
	REQUEST_CART_ITEMS,
	REQUEST_FREE_SELECTION_ITEMS,
	REQUEST_OPEN_SHOPPING_STYLE,
	REQUEST_SELECTION_VIEW,
	REQUEST_VALIDATION,
	REQUEST_ATTENUUID_INFO,
	RECEIVE_ATTENUUID_INFO,
	REQUEST_INVOICE_INFO,
	RECEIVE_INVOICE_INFO,
	LOADING_SELECTION_ARRAY,
	RECEIVE_SELECTION_ARRAY,
	RECEIVE_CART_INFO,
	RECEIVE_ADD_REGISTRATION_CART,
	RECEIVE_ADD_SELECTION_CART,
	RECEIVE_DELETE_CART_ITEM,
	RECEIVE_PAYMENT_SETTINGS,
	REQUEST_ERASE_ECOMMERCE_DATA,
	RECEIVE_SEND_PAYMENT,
	REQUEST_RESET_ECOMMERCE_CART,
	RECEIVE_ECOMMERCE_STORE_PRODUCT_TAGS,
	RECEIVE_ATTENDEE_SETTINGS,
	RECEIVE_ADD_ITEM_NO_LOGIN,
	RECEIVE_ECOMMERCE_MODULARURLID,
	RECEIVE_DELETE_CART_ITEM_NOLOGIN,
	RECEIVE_ADD_ITEM_API_NO_LOGIN,
	RECEIVE_ECOMMERCE_STORE_PRODUCTS,
	RECEIVE_ECOMMERCE_STORE_PRODUCT_PRICES,
	RECEIVE_PUBLIC_TO_PRIVATE_CART,
	REQUEST_RESET_CUSTOM_CHECKOUT_COMPONENT,
	RECEIVE_ECOMMERCE_DELETE_NON_PROCESSED_PAYMENT,
	REQUEST_RESET_SCREEN_NUM,
	REQUEST_RESET_REGISTRATION_UPDATED,
	REQUEST_SET_SCREEN_NUM,
	REQUEST_ECOMMERCE_STORE_PRODUCT_PRICES,
	REQUEST_INCREMENT_SCREEN_NUM,
	RECEIVE_STORE_SHIPPING_DETAILS,
	REQUEST_FILTER_PRODUCTS_BY_PRICE,
} from 'constants/ActionTypes';

import { LOADING_ATTENDEE_SETTINGS, LOADING_TAGS } from '../actions/EcommerceStore';

import Fuse from 'fuse.js';
import moment from 'moment';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import {
	RECEIVE_FLUSH_CART_ITEMS,
	REQUEST_ADD_REGISTRATION_CART,
} from '../../constants/ActionTypes';

const INIT_STATE = {
	screenIndex: 0, // the count of displayable screens
	screenNum: 1, // the actual screenNum we feed into Abhinav's component
	menuObjects: new Array(), //using array to access its methods
	editableMenuObjects: new Array(), // for accessing after initial payment
	displayableScreens: new Array(), // some menuObjects are set to not display by the backend
	attenuuid: '',
	cartUuid: '',
	cartInfoFetched: false,
	progress: '',
	status: '',
	attenuuidInfoFetched: false, // this includes attenuuid, progress, and status
	cartItemsNoLoginUpdated: false,
	cartItems: '',
	cartItemsNoLogin: new Array(),
	oldFreeSelectionItems: new Array(), //delete after
	freeSelectionItems: new Array(), //using array to access its methods
	openShoppingStyle: '',
	selectionArrayFetched: false,
	selectionView: 'grid',
	validation: true,
	invoiceInfo: '',
	invoiceInfoFetched: false,
	paymentSettingsFetched: false,
	paymentSettings: [],
	oldAttenuuid: '',
	oldCartItems: '',
	tags: [],
	tagsFetched: false,
	tagsStatus: 'idle',
	tagsMap: {},
	tagsExist: false,
	moduleUrlId: '',
	attendeeSettings: '',
	attendeeSettingsStatus: 'idle',
	attendeeSettingsFetched: false,
	storeProducts: [],
	storeProductsFetched: false,
	storeProductPrices: [],
	storeSearchFuse: {},
	storeCategoriesMap: {},
	storePricesLoading: false,
	specialCheckoutFormSubmitted: 0,
	registrationUpdated: false,
	shippingDetails: {},
	shippingDetailsStored: false,
	cartFetching: false,
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case REQUEST_PREV_SCREEN: {
			let newState = { ...state };
			return {
				...state,
				screenNum: newState.displayableScreens[newState.screenIndex - 1],
				screenIndex: newState.screenIndex - 1,
			};
		}
		case REQUEST_MENU_OBJECTS: {
			return {
				...state,
				menuObjects: action.payload,
			};
		}
		case REQUEST_ATTENDEE_ARRAY: {
			return {
				...state,
				attenuuid: action.payload.attenuuid,
				progress: action.payload.progress,
				status: action.payload.status,
			};
		}
		case REQUEST_CART_ITEMS: {
			return {
				...state,
				cartItems: action.payload,
			};
		}
		case REQUEST_FREE_SELECTION_ITEMS: {
			return {
				...state,
				// oldFreeSelectionItems: action.payload,
			};
		}
		case REQUEST_OPEN_SHOPPING_STYLE: {
			return {
				...state,
				openShoppingStyle: action.payload,
			};
		}
		case REQUEST_SELECTION_VIEW: {
			return {
				...state,
				selectionView: action.payload,
			};
		}
		case REQUEST_VALIDATION: {
			return {
				...state,
				validation: action.payload,
			};
		}

		case REQUEST_ATTENUUID_INFO: {
			return {
				...state,
			};
		}
		case RECEIVE_ATTENUUID_INFO: {
			return {
				...state,
				attenuuid: action.payload.attenuuid,
				progress: action.payload.progress,
				status: action.payload.status,
				attenuuidInfoFetched: true,
			};
		}
		case REQUEST_INVOICE_INFO: {
			return {
				...state,
				invoiceInfoFetched: true,
			};
		}
		case RECEIVE_INVOICE_INFO: {
			return {
				...state,
				invoiceInfo: action.payload.OUTPUT,
			};
		}
		case LOADING_SELECTION_ARRAY: {
			return {
				...state,
				selectionArrayFetched: true,
			};
		}
		case RECEIVE_SELECTION_ARRAY: {
			if (!action.payload.OpenShoppingStyle) {
				const displayableMenuObjects = action.payload.attenmenu.filter(
					obj => obj.display === 'Yes',
				);
				const displayableScreens = displayableMenuObjects.map(obj => obj.screen);

				return {
					...state,
					openShoppingStyle: action.payload.OpenShoppingStyle,
					menuObjects: action.payload.attenmenu,
					cartUuid: action.payload.cartuuid,
					displayableScreens: displayableScreens,
				};
			} else {
				return {
					...state,
					openShoppingStyle: action.payload.OpenShoppingStyle,
					cartUuid: action.payload.cartuuid,
					menuObjects: action.payload.attenmenu,
				};
			}
		}
		case RECEIVE_CART_INFO: {
			return {
				...state,
				cartItems: action.payload,
				cartInfoFetched: true,
			};
		}
		case REQUEST_ADD_REGISTRATION_CART: {
			return {
				...state,
				cartFetching: true,
			};
		}
		case RECEIVE_ADD_REGISTRATION_CART: {
			let newState = { ...state };

			if (action.payload.existingPayment) {
				return {
					...state,
					registrationUpdated: true,
					cartItems: action.payload,
					cartFetching: false,
				};
			} else if (action.payload.public) {
				return {
					...state,
					cartItems: action.payload,
					cartFetching: false,
				};
			} else {
				return {
					...state,
					screenNum: newState.displayableScreens[newState.screenIndex + 1],
					screenIndex: newState.screenIndex + 1,
					cartItems: action.payload,
					cartFetching: false,
				};
			}
		}
		case RECEIVE_ADD_SELECTION_CART: {
			return {
				...state,
				cartItems: action.payload,
				specialCheckoutFormSubmitted: action.payload.checkout_form,
			};
		}
		case RECEIVE_DELETE_CART_ITEM: {
			return {
				...state,
				cartItems: action.payload,
			};
		}
		case RECEIVE_ECOMMERCE_DELETE_NON_PROCESSED_PAYMENT: {
			return {
				...state,
				cartItems: action.payload,
			};
		}
		case RECEIVE_PAYMENT_SETTINGS: {
			return {
				...state,
				paymentSettings: action.payload,
				paymentSettingsFetched: true,
			};
		}
		case RECEIVE_SEND_PAYMENT: {
			if (action.payload.SUCCESS) {
				return {
					...state,
					cartItems: action.payload,
					oldAttenuuid: state.attenuuid,
					attenuuid: '',
				};
			} else {
				return {
					...state,
				};
			}
		}
		case REQUEST_ERASE_ECOMMERCE_DATA:
			return INIT_STATE;
		case REQUEST_RESET_ECOMMERCE_CART:
			return {
				...state,
				oldCartItems: '',
			};

		case RECEIVE_ECOMMERCE_STORE_PRODUCT_TAGS:
			const tags = action.payload;

			// build tags map of id -> label
			const tagsMap = {};
			for (const tag of tags) tagsMap[tag.id] = tag.label;

			return {
				...state,
				tags,
				tagsMap,
				tagsFetched: true,
			};
		case RECEIVE_ATTENDEE_SETTINGS: {
			return {
				...state,
				attendeeSettings: action.payload,
				attendeeSettingsFetched: true,
			};
		}
		case RECEIVE_ECOMMERCE_MODULARURLID: {
			return {
				...state,
				moduleUrlId: action.payload.data,
			};
		}
		case RECEIVE_ADD_ITEM_NO_LOGIN: {
			let currState = { ...state };
			if (currState.cartItemsNoLogin.length === 0) {
				let InvoiceItemArray = new Array();
				InvoiceItemArray.push(action.payload.data);

				let currCartItems = {};
				currCartItems['InvoiceItemArray'] = InvoiceItemArray;

				return {
					...state,
					cartItemsNoLogin: currCartItems,
					cartItemsNoLoginUpdated: false,
				};
			} else {
				let currInvoiceItemArray = currState.cartItemsNoLogin.InvoiceItemArray;
				let currCartItems = currState.cartItemsNoLogin;
				currCartItems['InvoiceItemArray'] = currInvoiceItemArray;

				return {
					...state,
					cartItemsNoLogin: currCartItems,
					cartItemsNoLoginUpdated: false,
				};
			}
		}
		case RECEIVE_DELETE_CART_ITEM_NOLOGIN: {
			let currState = { ...state };
			let tempCart = currState.cartItemsNoLogin;
			let tempInvoiceItemArray = currState.cartItemsNoLogin.InvoiceItemArray;

			tempInvoiceItemArray = tempInvoiceItemArray.filter(function (obj) {
				if (obj[action.payload.data['fieldname']]) {
					if (
						obj[action.payload.data['fieldname']]['valueid'] ==
						action.payload.data['valueid']
					) {
						return false;
					}
				}
				return true;
			});
			tempCart.InvoiceItemArray = tempInvoiceItemArray;

			return {
				...state,
				cartItemsNoLogin: tempCart,
			};
		}
		case RECEIVE_ADD_ITEM_API_NO_LOGIN: {
			return {
				...state,
				cartItems: action.payload,
				cartItemsNoLoginUpdated: true,
			};
		}

		case RECEIVE_ECOMMERCE_STORE_PRODUCTS: {
			const products = action.payload.map(product => ({
				id: product.product_id,
				title: product.product_label,
				description: product.product_config?.description,
				image: product.product_config?.image_url,
				publicationDate: moment(product.publication_date),
				parentIds: product.parent_products?.map(pp => parseInt(pp)) ?? [],
				childrenIds: product.child_products?.map(cp => parseInt(cp)) ?? [],
				sourceId: product.product_config?.source_product_id,
				tagIds: product.product_config?.tagid ?? [],
				categoryId: product.product_category_id,
				category: product.product_category,
				files: product.product_config?.files_array ?? [],
				code: product.product_config?.public_product_code,
				customFields: product.product_config?.custom_field_array ?? [],
				show: product.product_config?.hide_card
					? !product.product_config.hide_card
					: !product.parent_products.length, // show if hide_card is false or no parents
				sourceTagIds: [],
				digitalgood: product.digitalgood == 1 ? true : false,
				date_added: product.date_added,
				print_price: product.print_price == 1 ? true : false,
			}));

			// relationships between source/shadow products
			const shadowProducts = products.filter(p => p.sourceId);
			for (const shadow of shadowProducts) {
				const source = products.find(p => p.id === shadow.sourceId);
				shadow.childrenIds = source.childrenIds;
				shadow.parentIds = source.parentIds;
				shadow.sourceTagIds = source.tagIds; // not used, but good for uniformity.
				source.sourceId = shadow.id; // establish two way binding
				source.sourceTagIds = shadow.tagIds; // copy over tagIds because the source is the 'main' fascade product that will be shown, and this product is the shadow one.
			}

			const selectableProducts = products.filter(p => p.show);
			const options = {
				// search definitions for title and desc of product
				keys: ['title', 'description'],
			};
			const fuse = new Fuse(selectableProducts, options);

			// build categories map of id -> label
			const categoriesMap = {};
			for (const product of products) {
				if (!(product.categoryId in categoriesMap))
					categoriesMap[product.categoryId] = product.category;
			}

			return {
				...state,
				storeProducts: products,
				storeProductsFetched: true,
				storeSearchFuse: fuse,
				storeCategoriesMap: categoriesMap,
			};
		}

		case REQUEST_ECOMMERCE_STORE_PRODUCT_PRICES: {
			const newStoredPrices = [...state.storeProductPrices];
			const newProductIds = action.payload.productIds.filter(pid => {
				return !newStoredPrices.some(p => p.productid === pid && !p.isFetched);
			});
			return {
				...state,
				storePricesLoading: true,
				storeProductPrices: [
					...state.storeProductPrices,
					...newProductIds.map(id => ({ productid: id, isFetched: false })),
				],
			};
		}

		case RECEIVE_ECOMMERCE_STORE_PRODUCT_PRICES: {
			const newPrices = [...state.storeProductPrices];
			const productIdWithoutPrice = action.payload.productIds.filter(productId => {
				return !action.payload.data.some(p => p.productid === productId);
			});

			for (const price of action.payload.data) {
				const existing = newPrices.find(p => p.productid === price.productid);
				const index = newPrices.indexOf(existing);
				newPrices[index] = { ...price, isFetched: true };
			}
			for (const productId of productIdWithoutPrice) {
				const existing = newPrices.find(p => p.productid === productId);
				const index = newPrices.indexOf(existing);
				newPrices[index] = { ...existing, isFetched: true };
			}

			return {
				...state,
				storeProductPrices: newPrices,
				storePricesLoading: false,
			};
		}

		case RECEIVE_PUBLIC_TO_PRIVATE_CART: {
			return {
				...state,
				cartItems: action.payload,
			};
		}

		case REQUEST_RESET_CUSTOM_CHECKOUT_COMPONENT: {
			return {
				...state,
				specialCheckoutFormSubmitted: 0,
			};
		}
		case REQUEST_RESET_SCREEN_NUM: {
			return {
				...state,
				screenNum: 1,
				screenIndex: 0,
			};
		}
		case REQUEST_INCREMENT_SCREEN_NUM: {
			let newState = { ...state };
			return {
				...state,
				screenNum: newState.displayableScreens[newState.screenIndex + 1],
				screenIndex: newState.screenIndex + 1,
			};
		}
		case REQUEST_RESET_REGISTRATION_UPDATED: {
			return {
				...state,
				registrationUpdated: false,
			};
		}
		case REQUEST_SET_SCREEN_NUM: {
			return {
				...state,
				screenNum: action.data.screenNum,
			};
		}
		case LOADING_ATTENDEE_SETTINGS: {
			return { ...state, attendeeSettingsStatus: 'loading' };
		}
		case LOADING_TAGS: {
			return { ...state, tagsStatus: 'loading' };
		}

		case RECEIVE_STORE_SHIPPING_DETAILS: {
			return {
				...state,
				shippingDetails: action.payload.payload,
				shippingDetailsStored: true,
			};
		}
		case RECEIVE_FLUSH_CART_ITEMS: {
			return {
				...state,
				cartItems: '',
			};
		}
		case REQUEST_FILTER_PRODUCTS_BY_PRICE: {
			const oldProducts = [...state.storeProducts];
			const oldPrices = [...state.storeProductPrices];
			const updatedProducts = _filter(oldProducts, p => !_includes(action.payload, p.id));
			const updatedPrice = _filter(oldPrices, p => !_includes(action.payload, p.productid));
			return {
				...state,
				storeProducts: updatedProducts,
				storeProductPrices: updatedPrice,
			};
		}

		default:
			return state;
	}
};

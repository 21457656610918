import _filter from 'lodash/filter';
import _groupBy from 'lodash/groupBy';
import _find from 'lodash/find';
import moment from 'moment';
import { EXPIRED, IN_GOOD_STANDING, IN_RENEWAL_PERIOD, CorpMembershipStatisticsInitialState, membersTableTemplate, NEEDS_RENEWAL, STATIC_RENEWAL_PERIOD, TOTAL_CONTACTS, TOTAL_FREE_SLOTS, TOTAL_MEMBERS, TOTAL_PURCHASED_SLOTS, UNASSIGNED_MEMBERS_LABEL, DELETE_REG_AND_GROUP, DELETE_GROUP_KEEP_REG, DELETE_REG_KEEP_GROUP, ADMINS, ADD_CONTACT_BTN, MANAGE_CONTACTS } from '@components/Membership/helpers/constant';
import { isEmpty } from 'lodash';

export const goToFirstError = validationsObject => {
	const element = document.getElementById(
		`${Object.keys(validationsObject?.validationErrors)[0]}_title`,
	);
	if (element) {
		element.scrollIntoView();
	}
};

export const generateItems = formState => {
	let itemsToAdd = {};
	for (let key in formState) {
		// if (formState[key] && formState[key] > 0) {
		itemsToAdd[key] = {
			valueid: formState[key],
			qty: 1,
		};
		// }

		if (key.includes('Multidrop')) {
			if (formState[key]?.label) {
				itemsToAdd[key] = {
					valueid: formState[key].value,
					qty: formState[key].label.props.label,
				};
			}
		}
	}

	return itemsToAdd;
};

export const IsJsonString = str => {
	try {
		JSON.parse(str);
	} catch (e) {
		//TODO JSON with ' failing to parse. Need to find a solution
		console.error('JSON Parsing failed:', str, e);
		return false;
	}
	return JSON.parse(str);
};

export const expirationStateFinder = ({ expiryDate = "", renewal }) => {
	if (!expiryDate.length) { return EXPIRED }
	const renewalPeriod = renewal == 0 ? 0 : renewal ? renewal : STATIC_RENEWAL_PERIOD;
	const diffDays = moment(expiryDate).diff(moment.now(), 'days');
	if (diffDays > renewalPeriod) { return IN_GOOD_STANDING }
	if (diffDays <= renewalPeriod && diffDays >= 0) { return IN_RENEWAL_PERIOD }
	if (diffDays < 0) { return EXPIRED }
}

export const calculateMembershipStatistics = ({ companyMembers, allMemberships, generalSettings, companyState, pendingPaymentExist }) => {
	let totalContacts = 0, needsRenewal = 0, totalMembers = 0, totalPurchasedSlots = 0, totalFreeSlots = 0, expiredMembers = 0;
	let returnStats = {};
	totalContacts = companyMembers.length;
	returnStats = { ...CorpMembershipStatisticsInitialState };
	if (allMemberships.length) {
		allMemberships.forEach(({ count }) => { totalPurchasedSlots += count; })
		companyMembers.map(({ expirationDate, groupID, groupName }) => {
			const expirationState = expirationStateFinder({ expiryDate: expirationDate, renewal: generalSettings.renewal_period_days })
			if (Boolean(groupID) && (expirationState === IN_RENEWAL_PERIOD || expirationState === IN_GOOD_STANDING)) { totalMembers++; }
			if (Boolean(groupID) && expirationState === EXPIRED) { expiredMembers++; }
			if (expirationState === IN_RENEWAL_PERIOD || (expirationState === EXPIRED && groupName)) { needsRenewal++; }
		})
	}
	totalFreeSlots = totalPurchasedSlots - totalMembers;
	totalFreeSlots = totalFreeSlots < 0 ? 0 : totalFreeSlots;
	returnStats[TOTAL_CONTACTS].count = totalContacts
	returnStats[NEEDS_RENEWAL].count = needsRenewal
	returnStats[NEEDS_RENEWAL].link = generalSettings.enable_renew_all
		? needsRenewal !== 0 && companyState === IN_GOOD_STANDING
		: false;
	returnStats[TOTAL_MEMBERS].count = totalMembers
	returnStats[TOTAL_PURCHASED_SLOTS].count = totalPurchasedSlots
	returnStats[TOTAL_FREE_SLOTS].count = totalFreeSlots
	// returnStats[TOTAL_PURCHASED_SLOTS].purchaseSlotsCount = totalPurchasedSlots
	// returnStats[TOTAL_PURCHASED_SLOTS].freeSlotscount = totalFreeSlots
	//Hiding the Free slots and Purchase slots 
	if (generalSettings.no_individual_member_limit) {
	delete returnStats[TOTAL_PURCHASED_SLOTS]
	delete returnStats[TOTAL_FREE_SLOTS]
	delete returnStats["divider"]
	}
	if (pendingPaymentExist) {
		delete returnStats[TOTAL_FREE_SLOTS];
	}
	return returnStats;
}

export const constructTableColumns = ({ companyMembers, allMemberships, rolesArray, generalSettings, companyState, UnassignedColumns, tableColumns }) => {
	let returnArr = [];
	let updatedCompanyMembers = companyMembers.map((data) => {
		if (data.roles?.length) {
			return ({ ...data, roles: data.roles.map(({ roleid }) => (_find(rolesArray, { roleid }))) })
		}
		return data
	})
	let additionalAvailableGroups = [];
	if (generalSettings.membership_reg_fields?.length) {
		additionalAvailableGroups = generalSettings.membership_reg_fields.map(({ groupid }) => parseInt(groupid))
	}
	if (allMemberships) {
		allMemberships.forEach(({ groupid, group_name, count }) => {
			let tempObj = { ...membersTableTemplate };
			let freeSlots = 0;
			let filteredMembers = updatedCompanyMembers.filter(({ groupID, expirationDate }) => {
				if (`${groupID}` == `${groupid}`) {
					if (expirationStateFinder({ expiryDate: expirationDate, renewal: generalSettings.renewal_period_days }) !== EXPIRED) {
						return true
					} else {
						return false
					}
				}
			});
			freeSlots = count - filteredMembers.length;
			freeSlots = freeSlots <= 0 ? 0 : freeSlots;
			let freeSlotColor = freeSlots === 0 ? 'error' : 'success';
			let purchasedSlotsColor = 'geekblue';
			let assignedSlotsColor = 'default';
			tempObj.title = group_name;
			tempObj.id = groupid;
			tempObj.dataSource = filteredMembers;
			tempObj.columns = tableColumns;
			tempObj.tags = [{ color: assignedSlotsColor, countLabel: `${filteredMembers.length} Assigned`, type: "assigned" }, { color: freeSlotColor, countLabel: `${freeSlots} Available`, type: "available" }, 
			// { color: purchasedSlotsColor, countLabel: `${count} Purchased`, type: "purchased" }
		]
			tempObj.freeSlots = freeSlots;
			tempObj.showPurchaseSlot = additionalAvailableGroups.includes(parseInt(groupid)) && companyState !== IN_RENEWAL_PERIOD;
			returnArr.push(tempObj);
		})
	}

	let unAssignedMembers = updatedCompanyMembers.filter(
		({ expirationDate, groupID, groupName }) => {
			const expirationState = expirationStateFinder({ expiryDate: expirationDate, renewal: generalSettings.renewal_period_days });
			const needsRenewal = expirationState === EXPIRED;
			return (!groupID && groupName === '' || needsRenewal)
		}
	);
	let updatedColumns = UnassignedColumns;
	if (isEmpty(allMemberships)) {
		//Hiding Past member Type column in Unassigned table when no membership groups exist
		updatedColumns = UnassignedColumns.filter(({ key }) => {
			return key == 'past Member Type' ? false : true;
		});
	}
	let unAssignedTableTemplate = { ...membersTableTemplate };
	unAssignedTableTemplate.dataSource = unAssignedMembers;
	unAssignedTableTemplate.title = UNASSIGNED_MEMBERS_LABEL;
	unAssignedTableTemplate.columns = updatedColumns;
	unAssignedTableTemplate.showTableActions = false;
	if (!isEmpty(unAssignedTableTemplate.dataSource)) {
		returnArr.push(unAssignedTableTemplate);
	}
	return returnArr;
}

export const currencyFormatter = (value = 0) => {
	let amount = 0;
	if (Boolean(value)) { amount = value }
	return `$${parseInt(amount).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`
};

export const getGroupName = (id, memberships) => {
	return memberships.find(mem => mem.groupid == id)
}

export const getCompanyState = ({ expiryDate, renewal }) => {
	return expirationStateFinder({ expiryDate, renewal });
}

export const getIsCompanyExpired = ({ expiryDate, renewal }) => {
	return expirationStateFinder({ expiryDate, renewal }) === EXPIRED;
}

export const getIsCompanyGoodStandingState = ({ expiryDate, renewal }) => {
	return expirationStateFinder({ expiryDate, renewal }) === IN_RENEWAL_PERIOD;
}

export const getIsCompanyRenewalState = ({ expiryDate, renewal }) => {
	return expirationStateFinder({ expiryDate, renewal }) === IN_RENEWAL_PERIOD;
}

export const getIsMemberRenewalState = ({ expiryDate, renewal }) => {
	return [IN_RENEWAL_PERIOD].includes(expirationStateFinder({ expiryDate, renewal }))
}

export const getIsMemberGoodState = ({ expiryDate, renewal }) => {
	return [IN_GOOD_STANDING].includes(expirationStateFinder({ expiryDate, renewal }))
}

export const getIsMemberExpiredState = ({ expiryDate, renewal }) => {
	return [EXPIRED].includes(expirationStateFinder({ expiryDate, renewal }))
}

export const convertLowercaseFormat = (stringValue) => {
	return stringValue?.toLowerCase().trim()
}

export const searchBasedOnEmail = (members, searchValue) => {
	let emailBasedFilter = members?.filter((mem) => {
		return convertLowercaseFormat(mem?.email).includes(searchValue)
	})
	return emailBasedFilter
}

export const searchBasedOnName = (members, searchValue) => {
	let nameBasedFilter = members.filter((mem) => {
		return convertLowercaseFormat(`${mem?.firstName}${mem?.lastName}`).includes(searchValue)
	})
	return nameBasedFilter
}

export const searchBasedonRoles = (members, searchValue) => {
	let roleBasedFilter = members.filter((mem) => {
		return mem?.roles.some(data => convertLowercaseFormat(`${data?.label}`).includes(searchValue))
	})
	return roleBasedFilter
}

export const sortName = (rec1, rec2) => (`${rec1.lastName}, ${rec1.firstName}`).localeCompare(`${rec2.lastName}, ${rec2.firstName}`);

/* Exhibitor Registration  */

export const exhibitorCalculateMembershipStatistics = ({ isCompanyProfile, companyMembers, exhibitorStaffPageStatistics, exhibitorCompanyProfileStatistics }) => {
	let totalContacts = 0;
	let returnStats = {};
	totalContacts = companyMembers.length;
	if (isCompanyProfile) {
		returnStats = { ...exhibitorCompanyProfileStatistics }
		returnStats[TOTAL_CONTACTS].count = totalContacts;
		returnStats[ADMINS].count = companyMembers.filter(mem => mem.compAdmin).length;
	}
	if (!isCompanyProfile) {
		returnStats = { ...exhibitorStaffPageStatistics }
		returnStats[ADD_CONTACT_BTN].isAddContactBtn = true;
	}
	return returnStats;
}

export const exhibitorConstructTableColumns = ({ isCompanyProfile, companyMembers, badgeDiscountInfo, attendeesInfo, exhibitorProfileColumns }) => {
	if (isCompanyProfile) { return exhibitorProfilePageColumns(companyMembers, exhibitorProfileColumns); }
	return exhibitorStaffColumns({ companyMembers, badgeDiscountInfo, attendeesInfo });
}

export const exhibitorStaffColumns = ({ companyMembers, badgeDiscountInfo }) => {
	let conferenceAndUnassignedTable = [], usedPass = 0, purchasedPass = 0, remainingPass = 0, freeSlots = [], members = [];
	let conferenceAttendees = { ...membersTableTemplate };
	members = companyMembers.filter(data => data.attenuuid || data.discountid);
	conferenceAttendees.title = "Conference Attendees";
	badgeDiscountInfo.length && badgeDiscountInfo.forEach(discount => {
		freeSlots = [...freeSlots, ...Array(discount.total_available).fill(discount)]
		remainingPass += discount.total_available;
		usedPass += discount.total_used;
	})
	conferenceAttendees.dataSource = [...members, ...freeSlots]
	purchasedPass = remainingPass + usedPass;
	conferenceAttendees.tags = [
		{ color: 'green', countLabel: `${remainingPass} passes available` },
		{ color: 'geekblue', countLabel: `${purchasedPass} purchased passes` }
	];
	conferenceAttendees.showTableActions = false;
	conferenceAndUnassignedTable.push(conferenceAttendees)
	return conferenceAndUnassignedTable
}

export const exhibitorProfilePageColumns = (dataSource, exhibitorProfileColumns) => {
	let ManageContactsTable = []
	let ManageContactTemplate = { ...membersTableTemplate }
	ManageContactTemplate.dataSource = dataSource;
	ManageContactTemplate.title = MANAGE_CONTACTS;
	ManageContactTemplate.columns = exhibitorProfileColumns;
	ManageContactTemplate.showTableActions = false;
	ManageContactsTable.push(ManageContactTemplate)
	return ManageContactsTable
}

export const getDeletMethod = (key) => {
	switch (key) {
		case 0: return DELETE_REG_AND_GROUP
		case 1: return DELETE_GROUP_KEEP_REG
		case 2: return DELETE_REG_KEEP_GROUP
	}
}

export const getCountries = corporateMembership => {
	return corporateMembership.menu.find(menu => menu.screentype === 'ContactInfo').fieldids.find((item) => item.field_content_type === "country")
}

export const getStates = corporateMembership => {
	return corporateMembership.menu.find(menu => menu.screentype === 'ContactInfo').fieldids.find((item) => item.field_content_type === "state")
}

export const isWebView = () => window.innerWidth >= 768;

export const handleMemberRenewal = (props, corporateMembership) => {
	const renewPeriod = corporateMembership?.generalSettings?.renewal_period_days;
	const { expirationDate, groupName } = props
	const isCompanyexpired = getIsCompanyExpired({ expiryDate: corporateMembership?.company?.expiry_date, renewal: renewPeriod })
	const isCompanyRenewalState = getIsCompanyRenewalState({ expiryDate: corporateMembership?.company?.expiry_date, renewal: renewPeriod })
	let checkMemberRenewalState = getIsMemberRenewalState({ expiryDate: expirationDate, renewal: renewPeriod })
	let checkMemberExpiryState = getIsMemberExpiredState({ expiryDate: expirationDate, renewal: renewPeriod })
	let memberExpiryState = { isrenewalMember: false, isPastExpiryMember: false }
	if (isCompanyexpired || isCompanyRenewalState || corporateMembership?.company?.memberships_available.length == 0) {
		memberExpiryState.isrenewalMember = false
		memberExpiryState.isPastExpiryMember = false
	} else if (checkMemberRenewalState) {
		memberExpiryState.isrenewalMember = true
		memberExpiryState.isPastExpiryMember = false
	} else if (checkMemberExpiryState && groupName) {
		memberExpiryState.isPastExpiryMember = true
		memberExpiryState.isrenewalMember = false
	}
	return memberExpiryState
}

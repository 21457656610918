import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { connectStateResults } from 'react-instantsearch-dom';
import { Badge } from 'antd';
import { CaretDownFilled } from '@ant-design/icons';
import ClearRefinements from '../../../Common/Algolia/ClearRefinements';
import { ReactComponent as Up } from '../../../../assets/icons/up.svg';

const FilterContent = ({ searchState, popOverVisible }) => {
    const { styling = {} } = useSelector(({ loginInfo }) => loginInfo);
    let currentRefinementListCount = useMemo(() => {
        let tempCount = 0;
        if (searchState?.refinementList) {
            Object.values(searchState?.refinementList).forEach((item) => {
                if (item?.length) {
                    tempCount += item?.length
                }
            });
        }
        return tempCount;
    }, [searchState?.refinementList]);
    return (
        <div
            className={
                popOverVisible
                ? "gx-d-flex gx-align-items-center gx-justify-content-between filter-header filter-header-active"
                : "gx-d-flex gx-align-items-center gx-justify-content-between filter-header"
            }
        >
            <div>Filter</div>
            <div className="gx-d-flex gx-align-items-center">
                {!popOverVisible ? <Badge className="gx-mr-1" style={{ backgroundColor: styling['primary-dark-color'] || "#993333" }} count={currentRefinementListCount} /> : null}
                {currentRefinementListCount ? <ClearRefinements /> : null}
                {popOverVisible ? <Up style={{ width: '1em'}} /> : <CaretDownFilled />}
            </div>
        </div>
    )
};

export default connectStateResults(FilterContent);

import React, { useState, useEffect, useMemo } from 'react';
import { Descriptions, Form, Radio } from 'antd';
import Title from '../components/Title';
import Description from '../components/Description';
import useRules from '../hooks/useRules';
import NormalText from '../components/NormalText';

import RadioLabel from '../components/RadioLabel';
import useFilteredOptions from '../hooks/useFilteredOptions';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

interface Props {
	name: string;
	label: string;
	options: Array<{
		id: any;
		label: string;
		value: any;
		disabled: boolean;
		text: string;
		valuemetajson: any;
		checked: any;
	}>;
	value: any;
	onChange: any;
	required: string;
	description: string;
	primaryColor: string;
	secondaryColor: string;
	textColor: string;
	error: string;
	filteredValues?: any;
	item: any;
	passDonationValue: any;
	displayIf: any;
	message: string;
	passPreselectedRadioFinancial: any;
}

export const RadioFinancial = ({
	label,
	name,
	options,
	value,
	onChange,
	required,
	description,
	primaryColor,
	secondaryColor,
	textColor,
	filteredValues,
	error,
	item,
	passDonationValue,
	displayIf,
	message,
	passPreselectedRadioFinancial,
}: Props) => {
	const rules = useRules(required);
	const invoiceItemArray = useSelector(
		state => state.corporateMembership?.exhibitorCart?.InvoiceItemArray,
	);

	const filteredOptions: any = useFilteredOptions(options, filteredValues);

	const [autoDonation, setAutoDonation] = useState({});

	useEffect(() => {
		if (invoiceItemArray.length == 0) {
			const preSelected = options.filter(obj => obj.checked === 1);
			if (preSelected?.length) {
				let preSelectedValue = preSelected[0].id;
				passPreselectedRadioFinancial({ name, preSelectedValue });
			}
		}
	}, [invoiceItemArray]);

	const handleDonation = () => {
		let currValue = options.find(obj => obj.id === value);
		if (currValue) {
			setAutoDonation(currValue?.valuemetajson?.auto_donation_config);
		}
		return currValue?.valuemetajson?.auto_donation_config;
	};

	const calculation = useMemo(() => handleDonation(), [value]);

	useEffect(()=>{
		invoiceItemArray.filter((data)=>{
			if(data.field_label == 'Donation'){
				let donationValue={fieldid: data.field_id ,amount:data.amount}
				setAutoDonation(donationValue)
			}
		})
	},[])

	useEffect(() => {
		if (!isEmpty(autoDonation)) {
			passDonationValue(autoDonation);
		}
	}, [autoDonation]);

	return (
		<div>
			<Title
				label={label}
				color={primaryColor}
				error={error}
				required={required}
				name={name}
			/>
			<Description label={description} color={secondaryColor} />

			{displayIf ? (
				<Form.Item name={name} rules={rules}>
					<Radio.Group onChange={onChange} value={calculation}>
						{filteredOptions.map((option: any) => (
							<div key={option.id} style={{ margin: '7px 0px', color: 'red' }}>
								<Radio key={option.id} value={option.id}>
									<span
										className="gx-font-weight-bold gx-d-inline gx-mr-2"
										style={{
											color: textColor,
											fontSize: '0.9rem',
										}}
									>
										{option.label}
									</span>
									<NormalText
										label={`$${option.value.toLocaleString()}`}
										color={primaryColor}
										className={'gx-text-primary'}
									/>
									{option.showDateDescription ? (
										<Descriptions
											bordered
											layout="vertical"
											size="small"
											className="gx-mt-2 gx-mb-2 gx-w-100"
											labelStyle={{ color: 'black', fontWeight: 'normal' }}
										>
											<Descriptions.Item
												label={`On or before ${option.initialDate}`}
												style={{ opacity: option.date1Opacity }}
											>
												${option.initialAmount}
											</Descriptions.Item>
											<Descriptions.Item
												label={`On or before ${option.midDate}`}
												style={{ opacity: option.date2Opacity }}
											>
												${option.midAmount}
											</Descriptions.Item>
											<Descriptions.Item
												label={`After ${option.midDate}`}
												style={{ opacity: option.date3Opacity }}
											>
												${option.lastAmount}
											</Descriptions.Item>
										</Descriptions>
									) : null}
								</Radio>
								{option.showDateDescription ? (
									<Descriptions
										bordered
										layout="vertical"
										size="small"
										className="gx-mt-2 gx-mb-2"
										labelStyle={{ color: 'black', fontWeight: 'normal' }}
										style={{ marginLeft: '24px' }}
									>
										<Descriptions.Item
											label={`On or before ${option.initialDate}`}
											style={{ opacity: option.date1Opacity }}
										>
											${option.initialAmount}
										</Descriptions.Item>
										<Descriptions.Item
											label={`On or before ${option.midDate}`}
											style={{ opacity: option.date2Opacity }}
										>
											${option.midAmount}
										</Descriptions.Item>
										<Descriptions.Item
											label={`After ${option.midDate}`}
											style={{ opacity: option.date3Opacity }}
										>
											${option.lastAmount}
										</Descriptions.Item>
									</Descriptions>
								) : null}
								<RadioLabel label={option.text} color={'#545454'} />
							</div>
						))}
					</Radio.Group>
				</Form.Item>
			) : (
				<p>{message}</p>
			)}
		</div>
	);
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Typography, Card, Button, Spin, Select, Alert, Col, Row, Avatar, Tag } from 'antd';
import _groupBy from 'lodash/groupBy';
import _isEqual from 'lodash/isEqual';
import _find from 'lodash/find';
import _differenceWith from 'lodash/differenceWith';
import { NotificationManager } from 'react-notifications';
import CorporateExhibitorAddContact from '@components/Membership/ModalComponents/CorporateExhibitorAddContact';
import ManageRoles from '@components/Membership/ModalComponents/ManageRoles';
import { currencyFormatter, getGroupName, sortName, isWebView, handleMemberRenewal } from '@components/Membership/helpers/generalHelpers';
import { UN_ASSIGNED_GROUP, getPopupContainer } from '@components/Membership/helpers/constant';
import { addRegistrationItemsToCartSuccess, customStateHandlerCMS, requestToUpdateStepDetails, requestToDeleteRegistrationItemFromCart,deleteRegistrationItemFromCartSuccess } from '@appRedux/actions';
import { addRegistrationItemsToCart, deleteRegistrationItemFromCart } from '../../../api/Ecommerce';
import { AdditionalSlotPriceCalculation, getGroupDetails, sortCurrentMembership, sortFutureMembership } from './ExhibitorAdditionalSlotsHelper';
import CommonTable from '@components/Common/CustomTable/CommonTable';
import { getAdditionalSlotMembershipCount, SortBasedOnAdditionalMembership } from './ExhibitorAdditionalSlotsHelper';
import CorporateRenderMemberInfo from '@components/Membership/utilComponents/CorporateRenderMemberInfo';
import moment from 'moment';
export default function ExhibitorAdditionalSlots({ nextStep, previousStep, showMakePayment = false, showPrevious = false }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const corporateMembership = useSelector(state => state.corporateMembership);
  const { corporateMembershipModuleUrlId: moduleUrlId, appdir } = useSelector(state => state.loginInfo);
  const authUser = useSelector(state => state.auth.authUser);
  const membershipStepDetails = corporateMembership.membershipStepDetails;

  const [addContactVisibility, setAddContactVisibility] = useState(false);
  const [constructedMembers, setConstructedMembers] = useState([]); // "company_members" reconstructed and used.
  const [additionalInfo, setAdditionalInfo] = useState([]); // right-side container information.
  const [totalPrice, setTotalPrice] = useState(0); // total additional slots' price.
  const [loader, setLoader] = useState(false);
  const [additionalMembershipCount, setAdditionalMembershipCount] = useState(0)
  const [membershipAvailablefromInvoice, setMembershipAvailablefromInvoice] = useState([]);
  const [additionalMembers, setAdditionalMembers] = useState([]);
  const treatRoles = (rolesArr) => rolesArr.length.toString();
	const sortRoles = (rec1, rec2) => treatRoles(rec1.roles).localeCompare(treatRoles(rec2.roles));
  const updateAdditionalInfo = () => {
    let subMenuFields = corporateMembership.menu.find(m => m.screentype === "SubMenu")?.submenu[0].fieldids;
    let filteredResult = subMenuFields.find((data) => data.field_definition === "individual_corp_member");
    let additionalInformation = corporateMembership?.membershipAvailableFromInvoice?.map((data) => {
      let selectedMembershipGroup = getGroupDetails({ id: data.groupid, corporateMembership });
      let result = {
        ...data,
        key: data.groupid,
        title: data.group_name,
        includedMemberships: selectedMembershipGroup?.count,
        showAdditional: false,
        additionalSlots: 0,
        totalSlots: 0,
        unitPrice: 0
      };
      (corporateMembership?.generalSettings?.membership_reg_fields || []).forEach(item => {
        if (item.groupid == data.groupid) {
          result.showAdditional = true;
          result.unitPrice = filteredResult?.unit_price ?? 0;
        }
      })
      return result
    })
    let members = [];
    let inGoodStandingMembers = [];
    corporateMembership.companyMembers.forEach(member => {
      if (
        member.expirationDate &&
        corporateMembership.company.expiry_date &&
        moment(corporateMembership.company.expiry_date) < moment(member.expirationDate)
      ) {

        inGoodStandingMembers.push({
          ...member,
          futureGroupId: member.groupID,
          futureGroupName: member.groupName,
          inGoodStanding: true,
        });
      } else {
        members.push({
          ...member,
          futureGroupId: -1,
          futureGroupName: '',
          inGoodStanding: false,
        });
      }
    });
    inGoodStandingMembers.forEach(mem => {
      members.push(mem);
    });
    if(!additionalInformation){
      additionalInformation=[]
    }
    additionalInformation.forEach(availableGroup => {
      let counter = 0;
      let membersData = corporateMembership?.membershipStepDetails?.members.length ? corporateMembership.membershipStepDetails.members : [...members].filter(mem => {
        if (mem.groupID == availableGroup.groupid && counter < availableGroup.includedMemberships) {
          mem.futureGroupId = mem.groupID
          mem.futureGroupName = mem.groupName
          counter = counter + 1
        }
        const roles = corporateMembership?.roles?.length ? corporateMembership.roles : []; 
        mem.roles =  mem.roles.map(({ roleid }) => (_find(roles, { roleid }))) 
       return mem;
      })
      setConstructedMembers(membersData)
    })
    additionalInformation = SortBasedOnAdditionalMembership(additionalInformation)
    let NoOfAdditionalMembership = getAdditionalSlotMembershipCount(additionalInformation)
    setTotalPrice(membershipStepDetails?.totalAdditionalCost ? membershipStepDetails?.totalAdditionalCost : 0)
    setAdditionalMembershipCount(NoOfAdditionalMembership);
    setAdditionalInfo(additionalInformation)
    setAdditionalMembers(membershipStepDetails?.members)
  };

  useEffect(() => {
    if (corporateMembership?.membershipAvailableFromInvoice?.length > 0) {
      setMembershipAvailablefromInvoice(corporateMembership?.membershipAvailableFromInvoice)
    }
  }, [corporateMembership?.membershipAvailableFromInvoice])
  useEffect(() => {
    if (constructedMembers.length) {
      let updateAdditionalInfo = additionalInfo.map((data) => {
        let reviewTotalCount = 0;
        let AdditionalSlotCount = 0;
        let totalCount = [...constructedMembers].filter(member => member.futureGroupId == data.groupid).filter(member=> !member.inGoodStanding).length
        if (data.showAdditional) {
          reviewTotalCount = totalCount
          if (totalCount > data.includedMemberships) {
            AdditionalSlotCount = totalCount - data.includedMemberships;
          }
        }
        return { ...data, totalSlots: totalCount, additionalSlots: AdditionalSlotCount, reviewTotalCount }
      })
      let group = (corporateMembership.generalSettings?.corp_membership_value_map || []).find(item => {
        return corporateMembership?.exhibitorCart?.InvoiceItemArray.filter((data) => data.value_id === item.valueid)
      })
      let filteredInvoice = corporateMembership.exhibitorCart?.InvoiceItemArray?.filter((data) => {
        return data?.value_id == group?.valueid
      })

      const detailsObj = {
        additionalInfo: updateAdditionalInfo,
        totalAdditionalCost: totalPrice,
        members: constructedMembers,
        selectedMembership: filteredInvoice[0]
      }
      let NoOfAdditionalMembership = getAdditionalSlotMembershipCount(updateAdditionalInfo);
      setAdditionalMembershipCount(NoOfAdditionalMembership);
      updateAdditionalInfo = SortBasedOnAdditionalMembership(updateAdditionalInfo)
      setAdditionalInfo(updateAdditionalInfo);
      dispatch(requestToUpdateStepDetails(detailsObj));
    } else {
      setAdditionalInfo(additionalInfo)
    }
  }, [constructedMembers])

  useEffect(() => {
    updateAdditionalInfo();
  }, [])

  useEffect(() => {
    let checkIsCartItem = corporateMembership.exhibitorCart?.InvoiceItemArray.some((data) => data.value_id === membershipStepDetails?.selectedMembership?.value_id)
    if (corporateMembership.membershipStepDetails.additionalInfo.length > 0 && checkIsCartItem) {
      setAdditionalInfo(corporateMembership.membershipStepDetails.additionalInfo)
    }
    if (corporateMembership.membershipStepDetails.members.length > 0 && checkIsCartItem) {
      setConstructedMembers(corporateMembership.membershipStepDetails.members)
    }
  }, [corporateMembership.membershipStepDetails])


  const handleNextClick = () => {
    setLoader(true);
    const currentMenu = corporateMembership.menu.find(m => m.screentype === "SubMenu")?.submenu[0];
    let itemstoadd = { ...membershipStepDetails.itemstoadd };
    const groupedMembers = _groupBy(constructedMembers.filter(member=> !member.inGoodStanding), 'futureGroupId');
    let checkAdditionalSlotMember = constructedMembers.filter(member=> !member.inGoodStanding).filter((item) => {
      return corporateMembership?.generalSettings?.membership_reg_fields.some(data => data?.groupid == item?.futureGroupId)
    })
    if (checkAdditionalSlotMember?.length === 0) {
      let AdditionalSlotField;
      AdditionalSlotField = corporateMembership?.exhibitorCart?.InvoiceItemArray?.filter(field => {
        return corporateMembership?.generalSettings?.membership_reg_fields.some(data => data.fieldid == field.field_id);
      });
      try {
        if (checkAdditionalSlotMember.length === 0) {
          deleteRegistrationItemFromCart({
            payload: {
              itemstodelete: {
                field_id: AdditionalSlotField[0]?.field_id,
                invoice_id: AdditionalSlotField[0]?.invoice_id,
              },
            },
            urlId: moduleUrlId,
            exhibUuid: corporateMembership.exhibitorCartDetails.exhibuuid,
            compUuid: corporateMembership.userCompanyAffiliation.comp_uuid,
            authUser: authUser,
            appDir: appdir,
            cartUuid: corporateMembership.exhibitorCartDetails.exhibitorCartUuid
          }).then(data => {
            if (!data.error) {
              dispatch(deleteRegistrationItemFromCartSuccess(data));
              setLoader(false);
              nextStep();
              return;
            }
            dispatch(customStateHandlerCMS({ key: "membershipFormVisited", data: corporateMembership.membershipFormVisited + 1 }))
            NotificationManager.error("something went wrong...");
          })
        } else {
          setLoader(false);
          if (showMakePayment) { history.push(`/main/company/${moduleUrlId}/checkout`); }
          else {
            dispatch(customStateHandlerCMS({ key: "membershipFormVisited", data: corporateMembership.membershipFormVisited + 1 }))
            nextStep();
          }
        }
      } catch (error) {
        setLoader(false);
        NotificationManager.error("something went wrong...");
      }
    }
    [...corporateMembership?.membershipAvailableFromInvoice ?? []].forEach(grp => {
      let membershipField = corporateMembership?.generalSettings?.membership_reg_fields.find(field => field.groupid == grp.groupid)?.fieldid
      if (membershipField) {
        const field = currentMenu.fieldids.find(field => membershipField === field.fieldid);
        const qty = groupedMembers[grp.groupid]?.length;
        if (qty) {
          const valueid = qty * field?.unit_price;

          itemstoadd = { [field.fieldname]: { qty, valueid }, ...itemstoadd }
        }
      }
    })
    try {
      if (Object.keys(itemstoadd).length > 0) {
        addRegistrationItemsToCart({
          payload: { itemstoadd },
          urlId: moduleUrlId,
          exhibUuid: corporateMembership.exhibitorCartDetails.exhibuuid,
          compUuid: corporateMembership.userCompanyAffiliation.comp_uuid,
          appDir: appdir,
          cartUuid: corporateMembership.exhibitorCartDetails.exhibitorCartUuid
        }).then((data) => {
          if (!data.error) {
            dispatch(addRegistrationItemsToCartSuccess(data));
            setLoader(false);
            nextStep();
            return;
          }
          setLoader(false);
          dispatch(customStateHandlerCMS({ key: "membershipFormVisited", data: corporateMembership.membershipFormVisited + 1 }))
          NotificationManager.error("something went wrong...");
        }).catch(() => {
          setLoader(false);
          NotificationManager.error("something went wrong...");
        })
      } else {
        setLoader(false);
        if (showMakePayment) { history.push(`/main/company/${moduleUrlId}/checkout`); }
        else {
          dispatch(customStateHandlerCMS({ key: "membershipFormVisited", data: corporateMembership.membershipFormVisited + 1 }))
          nextStep();
        }
      }
    } catch (error) {
      setLoader(false);
      NotificationManager.error("something went wrong...");
    }
  }

  const handleDropDownChange = (futureGroupId, record) => {

    let canAddAdditionalSlots = (corporateMembership?.generalSettings?.membership_reg_fields ?? []).filter(item => item.groupid == futureGroupId);

    if ((futureGroupId == -1) || canAddAdditionalSlots.length > 0 || (getGroupDetails({ id: futureGroupId, corporateMembership })?.count > [...constructedMembers].filter(member => member.futureGroupId == futureGroupId).length)) {
      let members = constructedMembers.map(member => {
        if (record.uuid === member.uuid) {
          member.futureGroupId = futureGroupId
          member.futureGroupName = [UN_ASSIGNED_GROUP, ...corporateMembership?.membershipAvailableFromInvoice ?? []].find((data) => data.groupid == futureGroupId).group_name
        }
        return member
      })
      let { calculateTotalPrice, filteredResult } = AdditionalSlotPriceCalculation({ corporateMembership, members })
      setTotalPrice(calculateTotalPrice.length * filteredResult?.unit_price)
      setConstructedMembers(members)
    } else {
      NotificationManager.warning(`No more memberships spots are available for "${getGroupName(futureGroupId, corporateMembership?.membershipAvailableFromInvoice)?.group_name}"`)
    }

  }

  const futureMembershipColumns = [
    {
      title: 'Members', key: 'members', responsive: ['xs'], render: (record, i) => {
        let updatedDropdownColor = `${(record.futureGroupId == -1) ? " 1px solid red " : " 1px solid green "}`;
        return (

          <Row style={{ flexWrap: "nowrap" }} key={i}>
            <Col >
              <Avatar className='primary-background gx-pointer gx-mr-2 gx-ml-2' src={record.contact_picture ? record.contact_picture : ''} size={50}>
                {record?.firstName[0].toLowerCase()}
              </Avatar>
            </Col>
            <Col flex={4}>
              <div className='gx-ml-3'>
                <Typography>{record?.lastName}, {record?.firstName}</Typography>
                <Typography>{record?.email}</Typography>
                <div>
                  {handleMemberRenewal(record, corporateMembership).isrenewalMember ?
                    <div>
                      <Tag color="warning" className='gx-mt-1'>{`Expiry: ${moment(record.expirationDate).format("'MMM DD, YYYY'")}`}</Tag>
                    </div>
                    : handleMemberRenewal(record, corporateMembership).isPastExpiryMember ?
                      <div>
                        <Tag color="red" className='gx-mt-1'>{`Expiry: ${moment(record.expirationDate).format("'MMM DD, YYYY'")}`}</Tag>
                      </div> : null
                  }
                </div>
                {record.showContactID ? <Typography>Contact ID: {record.reviewID}</Typography> : null}
                {record.inGoodStanding ? <Typography>Expiry Date: {moment(record.expirationDate).format('MMM DD, YYYY')}</Typography> : null}
                <Typography className='gx-mt-2 gx-mb-1 gx-font-weight-semi-bold ' style={{ fontSize: "14px" }}>Current Membership:</Typography>
                <Typography className='text-wrap' style={{ fontSize: "14px" }}>{record?.groupName ? record?.groupName : 'Not Available'}</Typography>
                <Typography className='gx-mt-2 gx-mb-1 gx-font-weight-semi-bold ' style={{ fontSize: "14px" }}>Future Membership:</Typography>
                <div>
                  {record.inGoodStanding ? <Tag color="cyan" className='wrap-text' style={{ maxWidth: 250, whiteSpace: "pre-wrap" }}>Membership already in good standing</Tag> :
                    <Select
                      style={{ minWidth: 120, maxWidth: 250, borderRadius: '20px', border: updatedDropdownColor }}
                      onChange={e => handleDropDownChange(e, record)}
                      value={`${record.futureGroupId}`}
                      getPopupContainer={getPopupContainer}
                      dropdownMatchSelectWidth={false}
                    >
                      {[UN_ASSIGNED_GROUP, ...corporateMembership?.membershipAvailableFromInvoice ?? []].map(
                        ({ group_name, groupid }) => (
                          <Select.Option key={groupid} value={`${groupid}`} className="wrap-text">
                            <Typography style={{ fontSize: "14px" }}>{group_name}</Typography>
                          </Select.Option>
                        ),
                      )}
                    </Select>}
                </div>
              </div>
            </Col>
          </Row>
        )
      }, sorter: sortName
    },
    { title: 'Name', key: 'name', render: (data) => <CorporateRenderMemberInfo record={data} />, sorter: sortName, responsive: ['sm'] },
    {
			title: 'Roles',
			key: 'Roles',
			render: record => <ManageRoles record={record} />,
			sorter: sortRoles,
			responsive: ['sm']
		},
    {
      title: 'Current Membership', key: 'groupName', dataIndex: 'groupName', sorter: sortCurrentMembership,
      filters: corporateMembership?.membershipAvailableFromInvoice?.map(membership => ({ text: membership.group_name, value: membership.groupid })),
      onFilter: (value, existData) => existData?.groupID == value,
      responsive: ['sm']
    },
    {
      title: 'Future Membership', key: 'futureGroupName', render: (record) => {
        let updatedDropdownColor = `${(record.futureGroupId == -1) ? " 1px solid red " : " 1px solid green "}`;
        if (record.inGoodStanding) {
          return (
            <>
              <Alert message="Membership already in good standing"></Alert>
            </>
          );
        }
        return (
          <Select
            style={{ width: '100%', borderRadius: '20px', border: updatedDropdownColor }}
            onChange={e => handleDropDownChange(e, record)}
            value={`${record.futureGroupId}`}
            getPopupContainer={getPopupContainer}
          >
            {[UN_ASSIGNED_GROUP, ...corporateMembership?.membershipAvailableFromInvoice ?? []].map(
              ({ group_name, groupid }) => (
                <Select.Option key={groupid} value={`${groupid}`} className="wrap-text">
                  <Typography>{group_name}</Typography>
                </Select.Option>
              ),
            )}
          </Select>
        );
      },
      sorter: sortFutureMembership,
      filters: [UN_ASSIGNED_GROUP, ...membershipAvailablefromInvoice].map(membership => ({ text: membership.group_name, value: membership.groupid })),
      onFilter: (value, existData) => existData?.futureGroupId == value,
      responsive: ['sm']
    },
  ]

  const updateMembership = (newMember) => {
    let { calculateTotalPrice, filteredResult } = AdditionalSlotPriceCalculation({ corporateMembership, members: [...constructedMembers, newMember] })
    setTotalPrice(calculateTotalPrice.length * filteredResult?.unit_price ? calculateTotalPrice.length * filteredResult?.unit_price : 100)
    setConstructedMembers([{...newMember, inGoodStanding:false}, ...constructedMembers])
  }
  return (
    <Spin spinning={loader} tip={'Loading...'}>
      <Row>
        <Col xl={18} lg={18} md={24} sm={24} xs={24}>
          <div className="gx-mt-3">
            {isWebView() ?
              <div className="gx-text-right gx-mb-0">
                <Button
                  className="gx-mb-0"
                  type="primary"
                  size="small"
                  onClick={() => setAddContactVisibility(true)}
                >
                  + Add New Contact
                </Button>
              </div>
              :
              <Button
                className="gx-mb-3"
                type="primary"
                size="small"
                block
                onClick={() => setAddContactVisibility(true)}
              >
                + Add New Contact
              </Button>
            }

            <div>
              <Typography.Title level={5}>
                Add or edit individual memberships as needed.
              </Typography.Title>
            </div>
            <CommonTable
              columns={futureMembershipColumns}
              dataSource={constructedMembers}
              scroll={{
                y: constructedMembers.length > 1 && 425,
              }}
            />
          </div>
        </Col>
        <Col xl={6} lg={6} md={24} sm={24} xs={24}>
          <div className="gx-mt-3">
            <div
              style={{ minWidth: 'fit-content', height: 'fit-content' }}
              className="alternate-bg gx-p-4"
            >
              <Typography.Title level={5} className="gx-text-left gx-mb-1">
                Total Additional Cost: {currencyFormatter(totalPrice)}
              </Typography.Title>
              {/* <Typography.Title level={5} className='gx-text-left gx-mt-0'>{`Total Additional Memberships: ${additionalMembershipCount}`}</Typography.Title> */}
              {additionalInfo.map(
                ({
                  key,
                  title,
                  includedMemberships,
                  additionalSlots,
                  totalSlots,
                  unitPrice,
                  showAdditional,
                }) => (
                  <Card key={key}>
                    <Typography.Title level={5}>
                      {title}
                      {showAdditional ? (
                        <Typography.Text
                          disabled
                          style={{ cursor: 'text' }}
                        >
                          {' '}
                          {currencyFormatter(unitPrice)} per slot
                        </Typography.Text>
                      ) : null}
                    </Typography.Title>
                    {includedMemberships && parseInt(includedMemberships) > 0 ?
                    <div className="gx-d-flex gx-justify-content-between">
                      <Typography.Text>
                        Included Membership
                      </Typography.Text>
                      <Typography.Text>
                        {includedMemberships}
                      </Typography.Text>
                    </div>:<></>}
                    {showAdditional ? (
                      <div className="gx-d-flex gx-justify-content-between gx-align-items-center">
                        <div className="gx-d-flex gx-align-items-center">
                          <Typography.Text className="gx-mr-1">
                            Additional Memberships
                          </Typography.Text>
                        </div>
                        <Typography.Text>
                          {additionalSlots}
                        </Typography.Text>
                      </div>
                    ) : null}
                    {includedMemberships && parseInt(includedMemberships) > 0 ?
                    <div className="gx-d-flex gx-justify-content-between">
                      <Typography.Text>
                        Memberships Assigned
                      </Typography.Text>
                      <Typography.Text>{totalSlots}</Typography.Text>
                    </div>:<></>}
                  </Card>
                ),
              )}
            </div>
            <div className="gx-text-center">
              {showPrevious && (
                <Button
                  type="primary"
                  className="gx-mt-4"
                  onClick={previousStep}
                >
                  Previous
                </Button>
              )}
              <Button
                type="primary"
                className="gx-mt-4"
                onClick={handleNextClick}
              >
                {showMakePayment ? 'Make Payment' : 'Next'}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      {
        <CorporateExhibitorAddContact
          visible={addContactVisibility}
          closeModal={() => setAddContactVisibility(false)}
          additionalSlotPage={true}
          updateMembership={updateMembership}
          constructedMembers={constructedMembers}
        />
      }
    </Spin>
  );
}
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Divider, Table, Popconfirm } from 'antd';
import moment from 'moment';
import { ReactComponent as ItemIcon } from '../../assets/icons/item.svg';
import { ReactComponent as AmountIcon } from '../../assets/icons/amount.svg';
import { ReactComponent as CrossWhiteIcon } from '../../assets/icons/crosswhite.svg';
import { ReactComponent as PaymentsIcon } from '../../assets/icons/payments.svg';
import { formatPayment } from './helpers/helper';

var hash = require('object-hash');

const RegistrationItems = props => {
	const menu = useSelector(state => state.corporateMembership.menu);
	const [invoiceItemArray, setInvoiceItemArray] = useState([]);
	const [donationFieldIds, setDonationFieldIds] = useState([]);
	const [taxCalculation, setTaxCalculation] = useState(0);
	const showProcessingFees = useSelector(state => state.corporateMembership.showProcessingFees);
	const paymentProcessingDetails = useSelector(state => state.corporateMembership.paymentProcessingDetails);
	const [processingFees, setProcessingFees] = useState(0);
	const [netOwningWithProcessingFees, setNetOwningWithProcessingFees] = useState('');
	const [totalNetOwing, setTotalNetOwning] = useState(0);
	// antd data need unique key or else create duplicate issue, looping over array to create unique key for each row
	useEffect(() => {
		if (props?.cartProcessView) {
			return;
		}
		let uniqueInvoiceItemArray = [];

		for (let i = 0; i < props.cartItems?.InvoiceItemArray?.length; i++) {
			let curr = props.cartItems?.InvoiceItemArray[i];
			curr['key'] = i;
			uniqueInvoiceItemArray.push(curr);
		}
		setInvoiceItemArray(uniqueInvoiceItemArray);
	}, []);

	useEffect(() => {
	let tempTotalNetOwing = props.cartItems.NetOwing;
	if(paymentProcessingDetails?.payment_type == "wiretransfer" && paymentProcessingDetails?.enabled) {
		tempTotalNetOwing = parseFloat(props?.cartItems?.net_owing_wire_transfer)
	}else if(paymentProcessingDetails?.payment_type == "creditcard" && paymentProcessingDetails?.enabled) {
		tempTotalNetOwing = parseFloat(props?.cartItems?.net_owing_credit_card)
	}
	setTotalNetOwning(tempTotalNetOwing);
	}, [props.cartItems?.InvoiceItemArray, showProcessingFees, paymentProcessingDetails]);
	useEffect(() => {
		if (props?.cartProcessView) {
			return;
		}
		let uniqueInvoiceItemArray = [];

		for (let i = 0; i < props.cartItems?.InvoiceItemArray?.length; i++) {
			let curr = props.cartItems?.InvoiceItemArray[i];
			curr['key'] = i;
			uniqueInvoiceItemArray.push(curr);
		}

		if(showProcessingFees & paymentProcessingDetails.enabled) {
			let tempProcessingFees = 0;
			if(paymentProcessingDetails?.payment_type == "wiretransfer") {
				tempProcessingFees = parseFloat(props?.cartItems?.wire_transfer_processor_fee)
			}else if(paymentProcessingDetails?.payment_type == "creditcard") {
				tempProcessingFees = parseFloat(props?.cartItems?.credit_card_processor_fee)
			}
			setProcessingFees(tempProcessingFees);
			const processingFeesInvoiceItem ={
				amount: tempProcessingFees,
				field_label: "Processing Fees",
				invoice_description: paymentProcessingDetails?.label ?? "Payment Processing Fees",
				invoice_item_count: 1,
				key:props.cartItems?.InvoiceItemArray?.length
			}
			uniqueInvoiceItemArray.push(processingFeesInvoiceItem);
		}else{
			setProcessingFees(0);
		}
		setInvoiceItemArray(uniqueInvoiceItemArray);
	}, [props.cartItems?.InvoiceItemArray, showProcessingFees, paymentProcessingDetails]);

	useEffect(() => {
		let netOwing = props.cartItems.NetOwing
		if(paymentProcessingDetails?.payment_type == "wiretransfer") {
			netOwing = props?.cartItems?.net_owing_wire_transfer
		}else if(paymentProcessingDetails?.payment_type == "creditcard") {
			netOwing = props?.cartItems?.net_owing_credit_card
		}
		setNetOwningWithProcessingFees(`${formatPayment(parseFloat(netOwing))}`)
    }, [processingFees]);
	useEffect(() => {
		if (menu.length) {
			const submenu = menu.find(m => m.screentype === 'SubMenu')?.submenu[0];
			if (submenu?.fieldids?.length) {
				const tempIds = [];
				submenu.fieldids.forEach(field => {
					if (field.field_definition === 'Donation') {
						tempIds.push(field.fieldid);
					}
				});
				setDonationFieldIds(tempIds);
			}
		}
	}, [menu, props.cartItems?.InvoiceItemArray]);

	useEffect(() => {
		let totalTax = 0;

		invoiceItemArray.forEach(element => {
			totalTax += element.tax_amount;
		});
		setTaxCalculation(totalTax);
	}, [invoiceItemArray]);

	const paymentItemsColumns = [
		{
			title: (
				<div className="gx-text-center">
					<PaymentsIcon className="fg-primary-dark-light" style={{ width: '1.5rem' }} />
				</div>
			),
			width:
				props?.width < props?.mobileThreshold && props?.module == 'registration'
					? ''
					: '12%',
		},
		{
			title: (
				<div className="fg-primary">
					{props.languageObject?.payments_header ?? 'Payments'}
				</div>
			),
			width: '40%',
			render: record => {
				return (
					<div key={record.payment_id}>
						<div>Payment Type: {record.method}</div>
						<div>Order Number: {record.order_number}</div>
					</div>
				);
			},
		},
		{
			width: '23%',
			render: record => {
				return (
					<div className={record?.date_paid ? '' : 'fg-primary'}>
						{record.date_paid
							? moment(record.date_paid).format('MMM DD, YYYY')
							: props.languageObject?.non_processed_payment ?? 'Not Processed'}
					</div>
				);
			},
		},
		{
			width: '30%',
			render: record => {
				return <div className="gx-text-center">${formatPayment(record?.amount)}</div>;
			},
		},
		{
			width: '10%',
			render: record => {
				return (
					!record?.date_paid && (
						<div></div>
						// <Popconfirm
						// 	title="Are you sure you want to remove this item?"
						// 	okText="Yes"
						// 	cancelText="No"
						// 	onConfirm={event => {
						// 		props.onDeletePayment(record?.payment_id);
						// 	}}
						// >
						// 	<CrossWhiteIcon
						// 		className="fg-primary-dark-light"
						// 		style={{ width: '1em' }}
						// 	/>
						// </Popconfirm>
					)
				);
			},
		},
	];
	const invoiceItemsColumns = [
		{
			title: (
				<div className="gx-text-center">
					<ItemIcon className="fg-primary-dark-light" style={{ width: '1.2rem' }} />
				</div>
			),
			width:
				props?.width < props?.mobileThreshold && props?.module == 'registration'
					? ''
					: '12%',
		},
		{
			title: (
				<div className="fg-primary">
					{props.languageObject?.invoice_items_header ?? 'Items'}
				</div>
			),
			width: '68%',
			render: record => {
				return (
					<div>
						<div>{record?.invoice_description}</div>
					</div>
				);
			},
		},
		{
			title: (
				<div className="fg-primary gx-text-center">
					{props.languageObject?.invoice_amount_header ?? 'Amount'}
				</div>
			),
			width: '30%',
			render: record => {
				return (
					<div>
						<div className="gx-text-center">${formatPayment(record?.amount)}</div>
					</div>
				);
			},
		},
		{
			width: '10%',
			render: record => {
				return (
					donationFieldIds.includes(record.field_id) && (
						<div>
							{!props?.blockDelete && (
								<Popconfirm
									title="Are you sure you want to remove this item?"
									okText="Yes"
									cancelText="No"
									onConfirm={event => {
										props.onDelete({
											fieldId: record?.field_id,
											invoiceId: record?.invoice_id,
										});
									}}
								>
									<CrossWhiteIcon
										className="fg-primary-dark-light"
										style={{ width: '1em' }}
									/>
								</Popconfirm>
							)}
						</div>
					)
				);
			},
		},
	];


	const locale = {
		emptyText: <p style={{ size: '0000.1px' }}></p>,
	};

	const sortArray = obj => {
		obj.sort(function (a, b) {
			return parseFloat(b.amount) - parseFloat(a.amount);
		});
	};
	if (props.cartItems?.InvoiceItemArray?.length) {
		sortArray(props.cartItems.InvoiceItemArray);
	}

	return (
		<div>
			{props.cartItems.InvoiceItemArray && (
				<div>
					{props.cartItems.InvoiceItemArray.length > 0 ? (
						<div>
							<Table
								rowKey="invoice_id"
								columns={invoiceItemsColumns}
								dataSource={
									invoiceItemArray.length
										? invoiceItemArray
										: props.cartItems.InvoiceItemArray
								}
								pagination={false}
								key={hash(props.cartItems.InvoiceItemArray)}
							/>
							{totalNetOwing > 0 && (
								<>
									<div className="gx-d-flex gx-align-items-end">
										{props?.width < props?.mobileThreshold &&
											props?.module === 'registration' ? (
											''
										) : (
											<div
												className="gx-text-center"
												style={{ padding: '0px 16px', width: '12%' }}
											></div>
										)}
										{/* {<div>"test"</div>} */}

										<div
											style={{
												width: '68%',
												padding: '0px 16px',
											}}
										>
											<strong>
												{props.languageObject?.total_invoiced_header ??
													'Net Invoiced:'}
											</strong>
										</div>
										<div
											style={{
												padding: '0px 16px',
												width: '30%',
											}}
											className="gx-text-center"
										>
											<Divider />
											<strong className='gx-ml-4'>
												${formatPayment(totalNetOwing)}
											</strong>
										</div>
										<div style={{ padding: '0px 16px', width: '10%' }}></div>
									</div>
									{taxCalculation > 0 && (
										<div className="gx-d-flex gx-align-items-end">
											{props?.width < props?.mobileThreshold &&
												props?.module === 'registration' ? (
												''
											) : (
												<div
													className="gx-text-center"
													style={{ padding: '0px 16px', width: '12%' }}
												></div>
											)}
											<div
												style={{
													width: '68%',
													padding: '0px 16px',
												}}
											>
												<strong>{'Tax Amount'}</strong>
											</div>
											<div
												style={{
													padding: '0px 16px',
													width: '30%',
												}}
												className="gx-text-center"
											>
												<Divider />
												<strong>${formatPayment(totalNetOwing)}</strong>
											</div>
											<div
												style={{ padding: '0px 16px', width: '10%' }}
											></div>
										</div>
									)}
								</>
							)}
						</div>
					) : (
						<div>
							{
								<div>
									<div
										style={{
											textAlign: 'center',
											color: 'rgba(0, 0, 0, 0.25)',
										}}
									>
										Cart Empty
									</div>
									<Divider />
								</div>
							}
						</div>
					)}

					{props.cartItems.PaymentItemsArray.length > 0 && (
						<Table
							rowKey="payment_id"
							columns={paymentItemsColumns}
							dataSource={props.cartItems.PaymentItemsArray}
							pagination={false}
						/>
					)}

					<Table
						rowKey=""
						columns={[
							{
								title: (
									<div className="gx-text-center">
										<AmountIcon className="fg-primary-dark-light" style={{ width: '1.2rem' }} />{' '}
									</div>
								),
								width:
									props?.width < props?.mobileThreshold && props?.module == 'registration'
										? ''
										: '12%',
							},
							{
								title: (
									<div className="fg-primary">
										{props.languageObject?.net_owing_header ?? 'Amount Owing:'}
									</div>
								),
								width: props?.cartProcessView ? '48%' : '68%',
								render: record => {
									console.log('record', record);
									return (
										<div key={record.payment_id}>
											<div>Payment Type: {record.method}</div>
											<div>Order Number: {record.order_number}</div>
										</div>
									);
								},
							},
							{
								title: <div>${netOwningWithProcessingFees}</div>,
								width: '30%',
								render: record => {
									return (
										<div className="gx-text-center">${netOwningWithProcessingFees}</div>
									);
								},
							},
						]}
						dataSource={''}
						pagination={false}
						locale={locale}
					/>
				</div>
			)}
		</div>
	);
};

export default RegistrationItems;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Alert, Button, Card, Modal } from 'antd';
import CreditCard from '../Common/PaymentProcessing/CreditCard/CreditCard';
import Check from '../Common/PaymentProcessing/Check/Check';
import PurchaseOrder from '../Common/PaymentProcessing/PurchaseOrder/PurchaseOrder';
import WireTransfer from '../Common/PaymentProcessing/WireTransfer/WireTransfer';
import { ReactComponent as PaymentsIcon } from '../../assets/icons/payments.svg';
import { ReactComponent as ItemIcon } from '../../assets/icons/item.svg';
import { useHistory } from 'react-router-dom';

import { connect, useSelector } from 'react-redux';
import PaymentGateway from './PaymentProcessing/PaymentGateway/PaymentGateway';
const PayNow = props => {
	const [paymentApproved, setPaymentApproved] = useState(false);
	const [paymentDisplayDOM, setPaymentDisplayDOM] = useState('');
	const { moduleUrlId } = useParams();
	const history = useHistory();
	const [modalTitle, setModalTitle] = useState('Make Payment');
	const [modalHelpText, setModalHelpText] = useState('');

	const [showPGModal, setShowPGModal] = useState(false);
	const publicEmbed =
		useSelector(state => state.settings.publicEmbed) ||
		window.location.search.includes('public_embed=1'); // on the refresh the redux doesn't set public_embed, need windows.location.search for safety

	const appdir = useSelector(state => state.loginInfo.appdir);
	const ssotoken = useSelector(state => state.auth.ssotoken);
	const contactUuid = useSelector(state => state.auth.contact_uuid);
	const commonStatus = useSelector(state => state.common.status);
	const paymentSuccess = useSelector(state => state.common.paymentSuccess);

	const onPaymentSubmit = success => {
		if (props.returnPaymentApproved) {
			props.returnPaymentApproved(success);
		}
		setPaymentApproved(success);
	};

	const onDownloadInvoice = () => {
		if (props.moduleIdentifier === 'ecommerce' || props.moduleIdentifier === 'registration') {
			window.open(
				`https://masterapp.econference.io/masterapp_summer2012/apiv2/index.cfm?appdir=${appdir}&module=attendee&component=attendees&function=download_invoice&moduleurlid=${moduleUrlId}&attenuuid=${
					props.attenuuid ? props.attenuuid : props.ecommerce.oldAttenuuid
				}`,
			);
		} else if (props.moduleIdentifier === 'exhibitor') {
			window.open(
				`https://masterapp.econference.io/masterapp_summer2012/apiv2/index.cfm?appdir=${appdir}&module=exhibitor&component=exhibitors&function=download_invoice&moduleurlid=${moduleUrlId}&compuuid=${props.compuuid}&exhibuuid=${props.exhibuuid}`,
			);
		}
	};

	const clickBackCorporateMembership = () => {
		if (window.location.pathname.includes('/public/')) {
			//This is public_embed view
			history.push(`../../../company/${props.match.params.moduleUrlId}/staff`);
		} else {
			// This is an dashboard view
			history.push(`./staff`);
		}
	};

	const ssoLoginToFullApp = () => {
		if (window.location.pathname.includes('/public/')) {
			//This is public_embed view
			history.push(
				`../../../company/${props.match.params.moduleUrlId}/staff?token=${ssotoken}&uuid=${contactUuid}`,
			);
		} else {
			// This is an dashboard view
			history.push(`./staff?token=${ssotoken}&uuid=${contactUuid}`);
		}
	};

	useEffect(() => {
		if (props.common.paymentSuccess) {
			setPaymentApproved(true);
			if (props.returnPaymentApproved) {
				props.returnPaymentApproved(true);
			}
		}
	}, [props.common.paymentSuccess]);

	useEffect(() => {
		if (props.currentPaymentMethod && parseInt(props.cartItems?.NetOwing) > 0) {
			renderPaymentComponent();
		}
	}, [props.currentPaymentMethod]);
	const paymentComplete = () => {
		setShowPGModal(false);
		setPaymentDisplayDOM('');
	};
	const renderPaymentComponent = () => {
		switch (props.currentPaymentMethod) {
			case 'creditcard':
				setPaymentDisplayDOM(
					<CreditCard
						settings={props.paymentSettings.creditcardsettings}
						paymentSubmitted={onPaymentSubmit}
						netOwing={props.netOwing}
						attenuuid={props.attenuuid}
						moduleIdentifier={props.moduleIdentifier}
						compuuid={props.compuuid}
						exhibuuid={props.exhibuuid}
						publicEmbed={props.publicEmbed}
					/>,
				);
				break;
			case 'react_hosted':
				setPaymentDisplayDOM(
					<PaymentGateway
						settings={props.paymentSettings.reacthostedsettings}
						paymentSubmitted={onPaymentSubmit}
						netOwing={props.netOwing}
						attenuuid={props.attenuuid}
						moduleIdentifier={props.moduleIdentifier}
						compuuid={props.compuuid}
						exhibuuid={props.exhibuuid}
						publicEmbed={props.publicEmbed}
						onPaymentCompletion={paymentComplete}
					/>,
				);
				setModalTitle(
					props.paymentSettings.reacthostedsettings?.reacthostedbuttonname ?? '',
				);

				setModalHelpText(
					props.paymentSettings.reacthostedsettings?.credit_card_help_text ?? '',
				);

				setShowPGModal(true);
				break;
			case 'check':
				setPaymentDisplayDOM(
					<Check
						netOwing={props.netOwing}
						paymentSubmitted={onPaymentSubmit}
						attenuuid={props.attenuuid}
						moduleIdentifier={props.moduleIdentifier}
						compuuid={props.compuuid}
						exhibuuid={props.exhibuuid}
						publicEmbed={props.publicEmbed}
					/>,
				);
				break;
			case 'purchaseorder':
				setPaymentDisplayDOM(
					<PurchaseOrder
						paymentSubmitted={onPaymentSubmit}
						netOwing={props.netOwing}
						attenuuid={props.attenuuid}
						moduleIdentifier={props.moduleIdentifier}
						compuuid={props.compuuid}
						exhibuuid={props.exhibuuid}
						publicEmbed={props.publicEmbed}
					/>,
				);
				break;
			case 'wiretransfer':
				setPaymentDisplayDOM(
					<WireTransfer
						paymentSubmitted={onPaymentSubmit}
						netOwing={props.netOwing}
						attenuuid={props.attenuuid}
						moduleIdentifier={props.moduleIdentifier}
						compuuid={props.compuuid}
						exhibuuid={props.exhibuuid}
						publicEmbed={props.publicEmbed}
					/>,
				);
				break;
			default:
				return;
		}
	};
	const handlePGCancelOperation = () => {
		setPaymentDisplayDOM('');
		setShowPGModal(false);
		props.setCurrentPaymentMethod('');
	};
	return (
		<div>
			{
				// (props.status === 'Complete' ||
				// 	props.status === 'Pending' ||
				// 	commonStatus === 'Complete' ||
				// 	commonStatus === 'Pending')
				paymentSuccess && (
					<div>
						{(paymentApproved || parseFloat(props.netOwing) == 0) && (
							<div>
								<ItemIcon
									className="fg-primary-dark-light"
									style={{
										float: 'right',
										width: 'min(100%, 4em)',
									}}
								/>

								<div
									dangerouslySetInnerHTML={{
										__html: props.paymentSettings.approvedpaymentscreentext,
									}}
								></div>
								<br />
								{props.moduleIdentifier === 'exhibitor' ? (
									<div style={{ display: 'flex' }}>
										<Button
											className="login-form-button gx-mt-1 button-primary"
											shape={'round'}
											onClick={onDownloadInvoice}
										>
											Download Invoice
										</Button>
										<Button
											className="login-form-button gx-mt-1 button-primary"
											shape={'round'}
											onClick={
												publicEmbed
													? ssoLoginToFullApp
													: clickBackCorporateMembership
											}
										>
											Manage Staff
										</Button>
									</div>
								) : (
									<Button
										className="login-form-button gx-mt-1 button-primary"
										shape={'round'}
										onClick={onDownloadInvoice}
									>
										Download Invoice
									</Button>
								)}
							</div>
						)}
					</div>
				)
			}
			{parseFloat(props.netOwing) > 0 &&
				paymentDisplayDOM &&
				(showPGModal ? (
					<Modal
						visible={true}
						title={modalTitle}
						footer={[]}
						closable={true}
						onCancel={handlePGCancelOperation}
						style={{ top: '10px' }}
						width="70%"
					>
						{
							modalHelpText && <Alert
								message={
									<div
										className="gx-ml-4 gx-mb-1"
										dangerouslySetInnerHTML={{
											__html: modalHelpText,
										}}
									></div>
								}
							></Alert>
						}
						{paymentDisplayDOM}
					</Modal>
				) : (
					<div>{paymentDisplayDOM}</div>
				))}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		common: state.common,
		ecommerce: state.ecommerce,
	};
};

export default connect(mapStateToProps)(PayNow);

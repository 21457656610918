import React, { useState, useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
	requestSendPayment,
	requestSendPaymentExhibitor,
} from '../../../../appRedux/actions/Common';
import { ReactComponent as ChequeIcon } from '../../../../assets/icons/cheque.svg';
import '../styles/custom.less';

const PurchaseOrder = props => {
	const FormItem = Form.Item;
	const login_form = props.loginInfo.login_form;
	const dispatch = useDispatch();
	const { moduleUrlId } = useParams();
	const publicCheckout = useSelector(
		state =>
			state.ecommerce?.attendeeSettings?.attendeesiteadmin?.generalconfigobject
				?.public_checkout,
	);

	const [state, setState] = useState({
		paymentType: 'purchaseOrder',
		amount: props.netOwing,
		payorName: '',
		PONumber: '',
		CURRENCYID: 1,
		TRYCOUNT: 1,
	});
	const [submitLoad, setSubmitLoad] = useState(false);
	const handleInputChange = ({ target }) => {
		setState({
			...state,
			[target.name]: target.value,
		});
	};

	useEffect(() => {
		props.paymentSubmitted(false);
	}, []);

	useEffect(() => {
		if (props.common.paymentSuccess === 1) {
			setSubmitLoad(false);
			props.paymentSubmitted(true);
		}
	}, [props.common.paymentSuccess]);

	const submitPayment = () => {
		setSubmitLoad(true);
		let formData = new FormData();
		for (let key in state) {
			formData.append(key, state[key]);
		}
		if (props.ecommerce.shippingDetailsStored) {
			formData.append('shipping_details', JSON.stringify(props.ecommerce.shippingDetails));
		}

		if (props.moduleIdentifier === 'ecommerce') {
			dispatch(
				requestSendPayment({
					appdir: props.loginInfo.appdir,
					moduleUrlId: moduleUrlId,
					attenuuid: props.attenuuid,
					formData: formData,
					moduleIdentifier: props.moduleIdentifier,
					publicEmbed: props.publicEmbed,
					publicCheckout: publicCheckout,
				}),
			);
		} else if (props.moduleIdentifier === 'exhibitor') {
			dispatch(
				requestSendPaymentExhibitor({
					appdir: props.loginInfo.appdir,
					moduleUrlId: moduleUrlId,
					compuuid: props.compuuid,
					exhibuuid: props.exhibuuid,
					formData: formData,
					moduleIdentifier: props.moduleIdentifier,
				}),
			);
		}
	};

	return (
		<div className="gx-app-login-container" style={{ 'word-wrap': 'break-word' }}>
			<ChequeIcon className="embedded-icon fg-primary-dark-light" />
			<h2 className="payment-method">Payment Method: Purchase Order</h2>
			<p className="inner-details">
				Please complete the payment form. Include your purchase order number where possible.
			</p>
			<Form
				initialValues={{ remember: true }}
				name="myForm"
				onFinish={submitPayment}
				// onFinishFailed={onFinishFailed}
				className="gx-signin-form gx-form-row0"
			>
				{publicCheckout && (
					<div>
						<Form.Item
							label={'First Name'}
							labelCol={{ span: 24 }}
							required
							rules={[
								{
									required: true,
									message: 'Please input your first name!',
								},
							]}
						>
							<input
								type="text"
								name="firstName"
								className="ant-input form-control"
								placeholder="First Name"
								required={true}
								onChange={handleInputChange}
							/>
						</Form.Item>

						<Form.Item
							label={'Last Name'}
							labelCol={{ span: 24 }}
							required
							rules={[
								{
									required: true,
									message: 'Please input your last name!',
								},
							]}
						>
							<input
								type="text"
								name="lastName"
								className="ant-input form-control"
								placeholder="Last Name"
								required={true}
								onChange={handleInputChange}
							/>
						</Form.Item>

						<Form.Item
							label={'Company'}
							labelCol={{ span: 24 }}
							required
							rules={[
								{
									required: true,
									message: 'Please input your company!',
								},
							]}
						>
							<input
								type="text"
								name="company"
								className="ant-input form-control"
								placeholder="Company"
								required={true}
								onChange={handleInputChange}
							/>
						</Form.Item>

						<Form.Item
							label={'Email'}
							labelCol={{ span: 24 }}
							required
							rules={[
								{
									required: true,
									message: 'Please input your email!',
								},
							]}
						>
							<input
								type="text"
								name="email"
								className="ant-input form-control"
								placeholder="Email"
								required={true}
								onChange={handleInputChange}
							/>
						</Form.Item>
					</div>
				)}
				<Form.Item
					label="Payor Name"
					labelCol={{ span: 24 }}
					validateTrigger="onSubmit"
					name="payorName"
					rules={[
						{
							// required: true,
							message: 'Please input your Payor Name!',
						},
					]}
				>
					<Input
						placeholder="Payor Name"
						onChange={handleInputChange}
						value={state}
						// required
						name="payorName"
					/>
				</Form.Item>

				<Form.Item
					label="P.O. Number"
					labelCol={{ span: 24 }}
					validateTrigger="onSubmit"
					name="PONumber"
					rules={[
						{
							// required: true,
							message: 'Please input your P.O. Number!',
						},
					]}
				>
					<Input
						placeholder="P.O. Number"
						onChange={handleInputChange}
						name="PONumber"
						value={state}
						// required
					/>
				</Form.Item>

				<FormItem>
					<Button
						form="myForm"
						shape={'round'}
						type="default"
						className="login-form-button gx-mt-1 button-primary"
						key="submit"
						htmlType="submit"
						loading={submitLoad}
						style={{
							backgroundColor: login_form.primary_color
								? login_form.primary_color
								: '#33b4fd',
							color: '#fff',
							width: '100%',
						}}
					>
						Submit
					</Button>
				</FormItem>
			</Form>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		auth: state.auth,
		loginInfo: state.loginInfo,
		common: state.common,
		ecommerce: state.ecommerce,
	};
};

export default connect(mapStateToProps)(PurchaseOrder);

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Upload, Modal, Typography } from 'antd';
import imageCompression from 'browser-image-compression';
import { PlusOutlined } from '@ant-design/icons';
import { requestToUpdateCompanyLogo } from '@appRedux/actions';

const LogoImage = ({ test }) => {
  const dispatch = useDispatch();
  const corporateMembership = useSelector(state => state.corporateMembership);

  const [fileList, setFileList] = useState([]);
  const [modalVisibility, setModalVisibility] = useState(false);

const handleImageCompression = file => {
	return new Promise(function (resolve, reject) {
		imageCompression(file, {
			maxSizeMB: 1,
			maxWidthOrHeight: 1000,
			useWebWorker: true,
		})
			.then(function (compressedFile) {
				resolve(compressedFile);
			})
			.catch(function (error) {
				console.log(error.message);
			});
	});
};

  const toggleModalVisibility = () => { setModalVisibility(f => !f); }
  const onPreview = options => {
    handleImageCompression(options.file).then(image => {
      let reader = new FileReader();
      reader.onloadend = async function () {
        image = reader.result;
        // Make redux request to post data.
        dispatch(requestToUpdateCompanyLogo({
          compuuid: corporateMembership.userCompanyAffiliation['comp_uuid'],
          base64Img: image,
        }));
      };
      reader.readAsDataURL(image);
    });
  };
  const onUploadChange = info => { setFileList([...info.fileList]) };
  const onRemoveFile = () => {
    toggleModalVisibility();
    return false;
  };
  const handleOkClick = () => {
    setFileList([]);
    dispatch(requestToUpdateCompanyLogo({
      compuuid: corporateMembership.userCompanyAffiliation['comp_uuid'],
      base64Img: '',
      deleteLogo: true,
    }));
    toggleModalVisibility();
  }
  useEffect(() => {
    if (corporateMembership.company.complogo) {
      setFileList([{ url: corporateMembership.company.complogo, }]);
    } else {
      setFileList([])
    }
  }, [corporateMembership.companyFetched, corporateMembership.company.complogo]);
  return (
    <div className="companyLogo">
      <Upload
        accept="image/*"
        customRequest={onPreview}
        listType="picture-card"
        fileList={fileList}
        onChange={onUploadChange}
        className="image-upload-grid"
        onRemove={onRemoveFile}
      >
        {fileList.length >= 1 ? null : (
          <div>
            <Typography className='gx-mb-2'>Add Logo</Typography>
            <PlusOutlined />
          </div>
        )}
      </Upload>
      <Modal visible={modalVisibility} title={'Are You Sure?'} onOk={handleOkClick} onCancel={toggleModalVisibility}>
        <Typography>You want to remove the Company Logo?</Typography>
      </Modal>
    </div>
  );
}

export default LogoImage;
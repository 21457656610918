import { useMemo } from 'react';
import Fuse from 'fuse.js';
import { paginate } from 'components/Common/helpers/helper';

export const useSearchClient = (loading, products) => {
	const searchFuse = useMemo(
		() =>
			loading ||
			new Fuse(products, {
				keys: ['title', 'description', 'children.title'],
				includeMatches: true,
				useExtendedSearch: true,
			}),
		[loading],
	);

	const search = requests =>
		new Promise((resolve, reject) => {
			if (loading) return reject();

			const { query, page, hitsPerPage, facetFilters = [] } = requests[0].params;

			const result = searchFuse.search(`'${query}`);
			const superBundleRes = result.filter((res) => res.item.isSuperBundle);
			const bundleRes = result.filter((res) => res.item.isBundle && !res.item.isSuperBundle);
			const productRes = result.filter((res) => !res.item.isBundle && !res.item.isSuperBundle);
			const searchedProducts = [...superBundleRes, ...bundleRes, ...productRes].map(({ item, matches }) => {
				const hasChildrenMatch = matches.some(match => match.key === 'children.title');
				const searchedChildren =
					item.children?.filter((_, index) =>
						matches.some(match => match.refIndex === index),
					) ?? [];
				// keep all children if none of the matches are from children.title
				const children = hasChildrenMatch ? searchedChildren : item.children;
				return { ...item, children };
			});

			let totalHits = query ? searchedProducts : products.filter(p => !p.parentId);
			// let totalHits = query ? searchedProducts : products;

			const facets = { category: {}, tag: {} };
			const categoryIdSet = new Set(products.flatMap(p => p.categoryId ?? []));
			const tagIdSet = new Set(products.flatMap(p => p.tagIds));

			const buildCategoryFacets = () => {
				for (const categoryId of categoryIdSet) {
					const count = totalHits.filter(p => p.categoryId === categoryId).length;
					facets.category[categoryId] = count;
				}
			};

			const buildTagFacets = () => {
				for (const tagId of tagIdSet) {
					const count = totalHits.filter(p => p.tagIds.includes(tagId)).length;
					facets.tag[tagId] = count;
				}
			};

			// build facets before filtering first
			buildCategoryFacets();
			buildTagFacets();

			// facet filtering logic
			for (let i = 0; i < facetFilters.length; i++) {
				const key = facetFilters[i][0].split(':')[0];
				const values = facetFilters[i].map(filter => parseInt(filter.split(':')[1]));

				switch (key) {
					case 'category':
						totalHits = totalHits.filter(p => values.includes(p.categoryId));
						buildTagFacets(); // filtering by category only affects count of tags
						break;
					case 'tag':
						totalHits = totalHits.filter(p => values.some(id => p.tagIds.includes(id)));
						buildCategoryFacets(); // filtering by tags only affect count of cats
						break;
				}
			}

			// pagination
			const nbHits = totalHits.length;
			const nbPages = Math.ceil(nbHits / hitsPerPage);
			const hits = paginate(totalHits, page, hitsPerPage);

			resolve({ results: [{ hits, nbHits, page, nbPages, hitsPerPage, facets }] });
		});

	return { search };
};

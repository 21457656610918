import React,{useEffect} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Main from './main/index';
import { useMenu } from '@components/Membership/hooks/useMenu';

const App = ({ match }) => {
	useMenu()
	return(
	<Switch>
		<Route path={`${match.url}main`} component={Main} />
	</Switch>
);
	}

export default App;

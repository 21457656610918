import React, { useState, useMemo } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';
import { InstantSearch } from 'react-instantsearch-dom';
import _sortBy from 'lodash/sortBy';

import { useProducts } from '../hooks/useProducts';
import { useSearchClient } from '../hooks/useSearchClient';
import ConsumptionHeader from '../components/ConsumptionHeader';
import ConsumptionArea from '../components/ConsumptionArea';
import { paramsToSearchState, searchStateToParams } from '../helpers';

import '../styles/custom.less';

const GroupSelectionPage = () => {
	const { superBundleId } = useParams();

	const history = useHistory();
	const location = useLocation();
	const [searchState, setSearchState] = useState(paramsToSearchState(location.search));

	const [prodsLoading, products] = useProducts();
	const superBundle = useMemo(() => products.find(prod => prod.id === parseInt(superBundleId)), [
		products,
	]);
	const children = useMemo(() => _sortBy(products.filter(prod => superBundle?.childrenIds.some(childId => childId === prod.id)).map(p => {
		const tempChild = products.filter(prod => p?.childrenIds.some(childId => childId === prod.id))
			.map(({ childrenIds, description, id, tagIds, title, uuid, ...rest }) => ({ childrenIds, description, id, tagIds, title, uuid, children: rest?.children ?? [] }));
		return {
			childrenIds: p.childrenIds,
			description: p.description ?? "",
			id: p.id,
			tagIds: p.tagIds,
			title: p.title,
			uuid: p.uuid,
			children: tempChild ?? []
		}
	}), [(data) => data.title]), [
		products, superBundle
	]);
	const searchClient = useSearchClient(prodsLoading, children);

	const loading = prodsLoading;

	const searchStateChange = state => {
		setSearchState(state);
		history.push(`?${searchStateToParams(state)}`);
	};


	return (
		<InstantSearch
			indexName="consumption/group-selection"
			searchClient={searchClient}
			onSearchStateChange={searchStateChange}
			searchState={searchState}
		>
			<header /* style={{ maxHeight: '100vh', overflowY: 'auto' }} */>
				<ConsumptionHeader
					loading={loading}
					product={superBundle}
					goBack={() => history.push(`../selection`)}
				/>

				<section className="gx-flex-1 gx-d-flex">
					<article
						// className="content-area"
						style={{
							// width: `calc(100% - ${toggleWidth + (sidebarOpen ? sidebarWidth : 0)}px)`,
							width: `100%`,
						}}
					>
						<ConsumptionArea
							loading={loading || !superBundle}
							product={superBundle}
						/>
					</article>
				</section>
			</header>
		</InstantSearch>
	);
};

export default GroupSelectionPage;

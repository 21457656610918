import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useSettings } from './useSettings';

import { requestLoginFormData } from '../../../../appRedux/actions';
import { requestStoreProductTags } from '../../../../appRedux/actions/EcommerceStore';

export const useTags = () => {
	const dispatch = useDispatch();
	const appdir = useSelector(state => state.loginInfo.appdir);
	const source = useSelector(state => state.loginInfo.source_hex);

	const fetched = useSelector(state => state.ecommerce.tagsFetched);
	const tagsMap = useSelector(state => state.ecommerce.tagsMap);

	const [settingsLoading, , printTagIds, digitalTagIds, hiddenTagIds] = useSettings();
	const loading = settingsLoading || !fetched;

	useEffect(() => {
		if (source) return;
		if (!appdir) return;
		dispatch(requestLoginFormData({ appdir }));
	}, [appdir]);

	useEffect(() => {
		if (!source) return;
		if (fetched) return;
		dispatch(requestStoreProductTags({ source }));
	}, [source]);

	const getFilterTagIds = product => {
		const tagIds = [...new Set([...product.tagIds, ...product.sourceTagIds])];
		return [...tagIds.filter(tagId => !hiddenTagIds?.includes(tagId))];
	};

	const getDisplayTagIds = product => {
		let displayTagIds = getFilterTagIds(product);
		displayTagIds = displayTagIds.filter(tagId => !digitalTagIds?.includes(tagId));
		displayTagIds = displayTagIds.filter(tagId => !printTagIds?.includes(tagId));
		return displayTagIds;
	};

	return [loading, tagsMap, getDisplayTagIds, getFilterTagIds];
};

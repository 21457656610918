export const paramsToSearchState = params => {
	const urlParams = new URLSearchParams(params);
	const searchState = {
		query: urlParams.get('query'),
		page: parseInt(urlParams.get('page')) || 1,
		refinementList: {
			category: urlParams.get('categories')?.split(',') ?? [],
			tag: urlParams.get('tags')?.split(',') ?? [],
		},
	};
	return searchState;
};

export const searchStateToParams = state => {
	const query = state.query;
	const page = state.page;
	const categories = state.refinementList?.category;
	const tags = state.refinementList?.tag;
	const searchParams = new URLSearchParams({ page });
	if (query) searchParams.append('query', query);
	if (categories?.length) searchParams.append('categories', categories);
	if (tags?.length) searchParams.append('tags', tags);
	return searchParams;
};

import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

import { useProducts } from '../hooks/useProducts';
import { useProductPrices } from '../hooks/useProductPrices';
import { useShoppingCart } from '../hooks/useShoppingCart';
import { useTags } from '../hooks/useTags';
import { getCheckoutRoute, getCheckoutPublicRoute } from '../helpers';

// import StoreHeader from '../components/StoreHeader';
import BundlePromo from './ProductBundlePromo';
// import { FILE } from 'components/Common/constants';

import { Card, Tag, Button, Pagination, Image, Skeleton, Spin } from 'antd';
// import { FieldTimeOutlined } from '@ant-design/icons';

import { ReactComponent as CartPlus } from '../../../../assets/icons/cartPlus.svg';
import { ReactComponent as Remove } from '../../../../assets/icons/remove.svg';
import { ReactComponent as PDF } from '../../../../assets/icons/pdf.svg';
import { ReactComponent as Video } from '../../../../assets/icons/video.svg';
import { ReactComponent as Left } from '../../../../assets/icons/left.svg';
import { ReactComponent as CartCheck } from '../../../../assets/icons/cartCheck.svg';

import './product.css';

const ProductPage = () => {
	const { moduleUrlId, productId } = useParams();
	const history = useHistory();
	const publicEmbed = useSelector(state => state.settings.publicEmbed);
	const [productsLoading, products] = useProducts();
	const [productPrices, getPrices] = useProductPrices();
	const [addToCart, cartCount, cart, removeFromCart] = useShoppingCart();
	const [tagsLoading, tagsMap, getDisplayTagIds] = useTags();
	const showTags = !useSelector(
		state =>
			state.ecommerce.attendeeSettings?.attendeesiteadmin?.generalconfigobject?.hide_all_tags,
	);

	const [currentIndex, setCurrentIndex] = useState(0);

	const id = parseInt(productId);
	const product = useMemo(() => products.find(prod => prod.id === id), [products]);
	const price = useMemo(() => productPrices.find(price => price.productid === id), [
		productPrices,
	]);

	const checkout = () => {
		history.push(
			publicEmbed ? getCheckoutPublicRoute(moduleUrlId) : getCheckoutRoute(moduleUrlId),
		);
	};

	useEffect(() => {
		getPrices([id]);
	}, []);

	const addedToCart = useMemo(() => cart.some(invoice => invoice.field_id === price?.fieldid), [
		cart,
	]);
	const currentItem = product?.files[currentIndex];

	const loading = tagsLoading || productsLoading;

	return (
		<main className={publicEmbed ? 'gx-m-4' : 'container'}>
			{/* <StoreHeader productId={id} /> */}
			<div className="gx-mb-4 gx-d-flex">
				<Link
					to={
						publicEmbed
							? `/public/ecommerce/${moduleUrlId}/selection${history.location.search}`
							: `/main/ecommerce/${moduleUrlId}/selection`
					}
				>
					<div className="gx-d-flex gx-align-items-center">
						<Left style={{ width: '1em' }} />
						<span className="gx-ml-1 gx-text-black">Back</span>
					</div>
				</Link>
				<Button
					disabled={parseInt(cartCount) > 0 ? false : true}
					onClick={checkout}
					className="gx-ml-auto button-primary"
					shape="round"
				>
					<div className="gx-d-flex gx-justify-content-center gx-align-items-center">
						<CartCheck className="gx-mr-2" style={{ width: '1.2em' }} />
						<p className="gx-mb-0">My Shopping Cart ({cartCount})</p>
					</div>
				</Button>
			</div>

			<main className="gx-d-lg-flex gx-align-items-start">
				<div style={{ flexBasis: '100%' }}>
					<Card className="card shadow-2">
						{loading ? (
							<>
								<Image
									className="gx-mb-3 card-image"
									alt="placeholder"
									src={require('../../../../assets/images/placeholder-image.png')}
								/>
								<Skeleton active className="gx-mb-3"></Skeleton>
								<Skeleton.Button
									className="gx-d-block"
									style={{ width: '100%' }}
									active
									size="large"
								/>
							</>
						) : (
							<>
								<div className="gx-d-flex gx-justify-content-between">
									<div>
										{/* category */}
										<span className="category-ribbon">{product.category}</span>

										{/* <Image
											className="gx-mb-3 card-image"
											alt="placeholder"
											src={
												product.image ||
												require('../../../../assets/images/product-placeholder.png')
											}
										/> */}

										{/* tags */}
										{showTags &&
											getDisplayTagIds(product).map(
												tagId =>
													tagsMap[tagId] && (
														<Tag key={tagId} className="tag">
															{tagsMap[tagId]}
														</Tag>
													),
											)}

										{/* title */}
										<h2 className="gx-mb-2 gx-font-weight-semi-bold gx-pt-5">
											{price?.price_collection?.primary.label ?? product?.title}
										</h2>

										{/* <hr /> */}
										{/* description */}
										<p
											className="gx-mb-3"
											dangerouslySetInnerHTML={{
												__html: product.description,
											}}
										/>
									</div>
									<div className="gx-mb-3">
										<div className="gx-mb-3 gx-d-flex">
											<h4 className="gx-m-0 gx-my-auto fg-grey">Price:</h4>
											{price?.price_collection?.primary ? (
												<div className="gx-d-flex gx-align-items-center gx-ml-2">
													<h2 className="gx-mr-2 gx-mb-0">
														{price.price_collection.primary.price === 0
															? 'FREE'
															: `$${price.price_collection.primary.price}`}
													</h2>

													{/* teaser */}
													<div className="product-price-details">
														{price?.price_collection?.teaser && (
															<Tag className="teaser-tag gx-mr-2">
																{price?.price_collection?.teaser?.price === 0
																	? 'FREE'
																	: `$${price?.price_collection?.teaser?.price}`}{' '}
																for members
															</Tag>
														)}
													</div>
												</div>
											) : (
												<Spin className="gx-ml-1" size="small" />
											)}
										</div>
										<div className="gx-text-center">
											<Button
												onClick={() =>
													addedToCart
														? removeFromCart(price)
														: addToCart(price)
												}
												className="button-primary"
												size="large"
												shape="round"
												style={{ height: 'auto' }}
											>
												<div className="gx-d-flex gx-justify-content-center gx-align-items-center gx-px-2 gx-py-1">
													{addedToCart ? (
														<Remove className="gx-mr-2" style={{ width: '1em' }} />
													) : (
														<CartPlus className="gx-mr-2" style={{ width: '1.2em' }} />
													)}

													{price?.price_collection && (
														<h4 className="gx-mb-0 gx-text-white">
															{addedToCart ? 'Remove from Cart' : `Add to Cart`}
														</h4>
													)}
												</div>
											</Button>
										</div>
									</div>
								</div>
								{/* {product.parentIds.length > 0 && (
									<>
										<hr />
										{product.parentIds.map(parentId => (
											<BundlePromo key={parentId} bundleId={parentId} />
										))}
									</>
								)} */}
							</>
						)}
					</Card>
				</div>

				{/* <Card className="gx-mb-0 card" style={{ flexBasis: '60%' }}>
					{loading ? (
						<Skeleton active></Skeleton>
					) : (
						<>
							{product.files.length > 1 && (
								<Pagination
									className="gx-text-center gx-mb-2"
									current={currentIndex + 1}
									total={product.files.length ?? 0}
									defaultPageSize={1}
									onChange={page => setCurrentIndex(page - 1)}
								/>
							)}
							<h2 className="">{currentItem?.title}</h2>

							<p className="">{currentItem?.description}</p>

							{currentItem?.type === FILE.VIDEO && (
								<div className="gx-mb-3 gx-d-flex gx-align-items-center">
									<div className="gx-d-flex gx-align-items-center gx-mr-4">
										<Video
											className="fg-secondary"
											style={{ width: '1.2em' }}
										/>
										<h4 className="gx-ml-2 gx-mb-0 fg-grey">
											{currentItem.type}
										</h4>
									</div>
									<div className="gx-d-flex gx-align-items-center">
										<FieldTimeOutlined />
										<h4 className="gx-ml-2 gx-mb-0 fg-grey">
											{(currentItem?.duration / 60).toFixed(2)} minutes
										</h4>
									</div>
								</div>
							)}

							{currentItem?.type === FILE.PDF && (
								<div className="gx-mb-3 gx-d-flex gx-align-items-center">
									<PDF style={{ width: '1.4em' }} />
									<h4 className="gx-ml-2 gx-mb-0 fg-grey">{currentItem.type}</h4>
								</div>
							)}
						</>
					)}

					{currentItem?.image_url ? (
						<Image
							className="gx-mx-auto"
							style={{ maxHeight: '1000px', borderRadius: '3px' }}
							src={currentItem.image_url}
							alt={currentItem.title}
						></Image>
					) : (
						<div className="gx-bg-light gx-d-flex" style={{ minHeight: '700px' }}>
							<h4 className="gx-text-center gx-m-auto gx-text-light-grey">
								Preview Unavailable
							</h4>
						</div>
					)}
				</Card> */}
			</main>
		</main>
	);
};

export default ProductPage;

import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Tabs, Skeleton } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import _sortBy from 'lodash/sortBy';

import BundleDetails from './BundleDetails';
import Presentations from './Presentations';
import FileList from './FileLists';

import { hitsPerPage } from '../../constants';
import { useProducts } from '../../hooks/useProducts';
import { storeProductAnalysisRequest } from '../../../../appRedux/actions';
import auth from '../../../../loginAuth/loginAuth';

const ConsumptionArea = ({ product, expanded }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { bundleId, superBundleId, productId } = useParams();
	const [page, setPage] = useState(0);
	const [bundlePageNum, setBundlePageNum] = useState(0);

	const [activeTab, setActiveTab] = useState("0");
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);

	const appdir = useSelector(state => state.loginInfo.appdir);
	const contactUid = useSelector(state => state.auth.contact_uuid);

	const [prodsLoading, products] = useProducts();

	let id = bundleId;
	if (superBundleId && bundleId) {
		id = bundleId;
	} else if (superBundleId) {
		id = superBundleId;
	} else if (productId && !superBundleId && !bundleId) {
		id = productId;
	}
	const bundle = useMemo(() => products.find(prod => prod.id === parseInt(id)), [products]);
	const productInfo = useMemo(() => products.find(prod => prod.id === parseInt(productId)), [products, productId]);

	const children = useMemo(
		() => _sortBy(products.filter(p => bundle?.childrenIds.some(childId => childId === p.id)), [(data) => data.title]),
		[products, bundle],
	);

	useEffect(() => {
		if (!prodsLoading) {
			if (productId && bundleId) {
				setSelectedProduct(productInfo);
				setActiveTab('4');
			} else if (!productId && bundleId && superBundleId) {
				setSelectedProduct(productInfo);
			} else {
				setSelectedProduct(children[0]);
			}
		}
	}, [prodsLoading, productId]);

	useEffect(() => {
		if (productId) {
			let selectedIndex = children.findIndex(prod => prod.id === parseInt(productId));
			if (selectedIndex >= 0) {
				let tempPage = ((selectedIndex + 1) % hitsPerPage) === 0
					? (parseInt((selectedIndex + 1) / hitsPerPage) - 1)
					: parseInt((selectedIndex + 1) / hitsPerPage);
				setPage(tempPage);
			}
			setSelectedProduct(productInfo);
		} else {
			setSelectedProduct(children[0]);
		}
	}, [productId]);

	const handleBundlePageChange = (value) => {
		setPage(value);
		setBundlePageNum(value);
	};

	const handleSelectProduct = (data) => {
		if (bundleId && productId && !superBundleId) {
			// to display the product in the bundle page directly
			history.push(
				`/${history.location.pathname.split('/')[1]
				}/consumption/bundle/${bundleId}/${data.id}${history.location.search}`,
			);
		} else if (bundleId && productId && superBundleId) {
			// to display the product in the group consumption(grand parent) page directly
			history.push(
				`/${history.location.pathname.split('/')[1]
				}/consumption/group/${superBundleId}/${bundleId}/${data.id}${history.location.search}`,
			);
		}
		dispatch(storeProductAnalysisRequest({
			appdir,
			contactUid: contactUid === ''
				? JSON.parse(auth.getCookie('contact_uuid'))
				: contactUid,
			productId: data.id
		}));
		setSelectedProduct(data);
		scrollToView();
	};

	const handleSelectFile = (data) => {
		setSelectedFile(data);
	};

	const scrollToView = () => {
		try {
			if (window.parent && window.parent) {
				// window.parent.scrollTo(0, 0);
				window.parent.postMessage({ type: 'scrollToTop' }, '*');

			} else {
				window.scrollTo(0, 0);
			}
		} catch (err) {
			console.log('err', err);
		}
	};

	const handleTabChange = (key, productData) => {
		setActiveTab(key);
		scrollToView();
		if (productData) setSelectedProduct(productData);
		if (bundle?.files?.length === 1) {
			setSelectedFile(bundle?.files?.[0]);
		} else {
			handleSelectFile(null);
		}
	};

	const renderTabBar = (props, DefaultTabBar) => (
		<DefaultTabBar {...props} className="gx-px-5 gx-pt-5 gx-pb-3 gx-mb-0 gx-bg-grey" />
	);

	if (prodsLoading)
		return (
			<main className="gx-d-flex gx-flex-column gx-h-100">
				<div
					className="gx-px-5 gx-pt-3 gx-pb-5 shadow-top-1"
					style={{ zIndex: 4, flex: '1 1 auto' }}
				>
					<Skeleton active paragraph={{ rows: 6 }}></Skeleton>
				</div>
			</main>
		);

	if (bundle?.isSuperBundle && bundle?.isBundle && !bundleId && !bundle?.files?.length && !bundle?.description) {
		return (
			<main className="gx-d-flex gx-flex-column-reverse gx-h-100">
				<section
					className="fshadow-top-1 gx-px-5"
					style={{ zIndex: 4, flex: '1 1 auto' }}
				>
					<BundleDetails
						bundle={product}
						page={bundlePageNum}
						setPage={handleBundlePageChange}
						selectedProduct={selectedProduct}
						handleTabChange={handleTabChange}
						scrollToView={scrollToView}
					/>
				</section>
			</main>
		)
	}

	return (
		<main className="gx-d-flex gx-flex-column-reverse gx-h-100">
			<section
				className="fshadow-top-1"
				style={{ zIndex: 4, flex: '1 1 auto' }}
			>
				<Tabs
					defaultActiveKey={0}
					size="default"
					renderTabBar={renderTabBar}
					activeKey={activeTab}
					onChange={handleTabChange}
				>
					<Tabs.TabPane
						key={0}
						className="gx-px-5 gx-pt-2"
						tab={
							<div className="gx-d-flex gx-align-items-center fg-grey">
								<span>Description</span>
							</div>
						}
					>
						<BundleDetails
							bundle={product}
							page={bundlePageNum}
							setPage={handleBundlePageChange}
							selectedProduct={selectedProduct}
							handleTabChange={handleTabChange}
							scrollToView={scrollToView}
						/>
					</Tabs.TabPane>
					{bundle?.files?.length ? (
						<Tabs.TabPane
							key={1}
							// className="gx-px-5 gx-pt-2"
							tab={
								<div className="gx-d-flex gx-align-items-center fg-grey">
									<span>Files</span>
								</div>
							}
						>
							<FileList
								bundle={bundle}
								selectedFile={selectedFile}
								handleSelectFile={handleSelectFile}
							/>
						</Tabs.TabPane>
					) : null}
					{/* <Tabs.TabPane
						key={2}
						className="gx-px-5 gx-pt-2"
						tab={
							<div className="gx-d-flex gx-align-items-center">
								<span>Evaluations</span>
							</div>
						}
					>
						<h4 className="fg-grey">These are the evaluations</h4>
					</Tabs.TabPane>
					<Tabs.TabPane
						key={3}
						className="gx-px-5 gx-pt-2"
						tab={
							<div className="gx-d-flex gx-align-items-center">
								<span>Discussions</span>
							</div>
						}
					>
						<h4 className="fg-grey">These are the discussions</h4>
					</Tabs.TabPane> */}
					{/* Hidden the Presentation tab on the product and grand parent page */}
					{(!product?.isSuperBundle && !product?.isBundle && !bundleId && !superBundleId) || (bundle?.isSuperBundle && bundle?.isBundle && !bundleId)
						? null
						: <Tabs.TabPane
							key={4}
							// className="gx-px-5 gx-pt-2"
							tab={
								<div className="gx-d-flex gx-align-items-center">
									<span>Presentations</span>
								</div>
							}
						>
							{activeTab === '4'
								? (
									<Presentations
										loading={prodsLoading}
										children={children}
										product={selectedProduct}
										expanded={expanded}
										page={page}
										setPage={(value) => {
											setPage(value);
											scrollToView();
										}}
										handleSelectProduct={handleSelectProduct}
									/>
								) : null}
						</Tabs.TabPane>}
				</Tabs>
			</section>
		</main>
	);
};

export default ConsumptionArea;

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import 'react-credit-cards/es/styles-compiled.css';
import 'react-phone-number-input/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { useParams } from 'react-router';
import {
	requestSendPayment,
	requestSendPaymentExhibitor,
	requestResetPaymentSuccess,
} from '../../../../appRedux/actions/Common';
import '../styles/custom.less';
import { requestPaymentLoader } from '../../../../appRedux/actions';
const PaymentGateway = props => {
	const publicCheckout = useSelector(
		state =>
			state.ecommerce?.attendeeSettings?.attendeesiteadmin?.generalconfigobject
				?.public_checkout,
	);
	const [submitLoad, setSubmitLoad] = useState(false);
	const cartUuid = useSelector(state => state.ecommerce.cartUuid);
	const dispatch = useDispatch();
	const { moduleUrlId } = useParams();
	const [isSubmitProcessing, setIsSubmitProcessing] = useState(false);
	const IsJsonString = str => {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return JSON.parse(str);
	};
	const submitPayment = data => {
		if (!isSubmitProcessing && data.hasOwnProperty('success')) {
			setIsSubmitProcessing(true);
			if (!data.success) {
				//Transaction Failed
				NotificationManager.error(
					'Payment Failed. Retry with another payment option.',
					'Failed',
				);
				setSubmitLoad(true);
				return;
			}
			setSubmitLoad(true);
			let formData = new FormData();
			formData.append('paymentType', 'react_hosted');
			if (publicCheckout) {
				formData.append('public_checkout', true);
			}
			if (cartUuid) {
				formData.append('CartUUID', cartUuid);
			}
			if (props.ecommerce.shippingDetailsStored) {
				formData.append(
					'shipping_details',
					JSON.stringify(props.ecommerce.shippingDetails),
				);
			}
			if (props.netOwing) {
				formData.append('amount', props.netOwing);
			}
			formData.append('CURRENCYID', 1);
			if (data.hasOwnProperty('payload')) {
				Array.isArray(data.payload) && data.payload[0]
					? formData.append('react_paymentResponseJSON', JSON.stringify(data.payload[0]))
					: formData.append('react_paymentResponseJSON', JSON.stringify(data.payload));
			}
			if (props.moduleIdentifier === 'ecommerce') {
				dispatch(
					requestSendPayment({
						appdir: props.loginInfo.appdir,
						moduleUrlId: moduleUrlId,
						attenuuid: props.attenuuid,
						formData: formData,
						moduleIdentifier: props.moduleIdentifier,
						publicEmbed: props.publicEmbed,
						publicCheckout: publicCheckout,
					}),
				);
			} else if (props.moduleIdentifier === 'exhibitor') {
				dispatch(requestPaymentLoader(true));
				dispatch(
					requestSendPaymentExhibitor({
						appdir: props.loginInfo.appdir,
						moduleUrlId: moduleUrlId,
						compuuid: props.compuuid,
						exhibuuid: props.exhibuuid,
						formData: formData,
						moduleIdentifier: props.moduleIdentifier,
					}),
				);
			}
			setIsSubmitProcessing(true);
		}
	};
	useEffect(() => {
		props.paymentSubmitted(false);
	}, []);
	useEffect(() => {
		window.addEventListener('message', function (e) {
			e.preventDefault()
			e.stopImmediatePropagation()
			if (IsJsonString(e.data)) {
				const data = JSON.parse(e.data);
				submitPayment(data);
			} else {
				return;
			}
		});
	}, []);
	useEffect(() => {
		if (!submitLoad) {
			return;
		}
		if (props.common.paymentSuccess !== '') {
			setSubmitLoad(false);
		}
		if (props.common.paymentSuccess === false) {
			dispatch(requestResetPaymentSuccess(props));
		}
		// Done loading
		props.paymentSubmitted(props.common.paymentSuccess);
		props.onPaymentCompletion();
	}, [props.common.paymentSuccess, submitLoad]);
	return (
		<div>
			{/* mock=true */}
			<iframe
				src={`https://xcdsystem.com/masterapp_summer2012/paymentprocessing/react_hosted.cfm?appdir=${props.loginInfo.appdir
					}&moduleUrlId=${moduleUrlId}&contactuuid=${props.auth.contact_uuid}&exhibuuid=${props.exhibuuid
					}&compuuid=${props.compuuid}${props.attenuuid ? `&attenuuid=${props.attenuuid}` : ''
					}`}
				frameBorder="0"
				width="100%"
				style={{ height: '80vh' }}
				scrolling="no"
			></iframe>
		</div>
	);
};
function mapStateToProps(state) {
	return {
		auth: state.auth,
		loginInfo: state.loginInfo,
		common: state.common,
		ecommerce: state.ecommerce,
		corporateMembership: state.corporateMembership,
	};
}
export default connect(mapStateToProps)(PaymentGateway);

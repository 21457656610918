import React, { useMemo, useState } from 'react';
import { Dropdown, Menu, Skeleton } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import { RefinementList } from 'react-instantsearch-dom';

import FilterContent from './FilterContent';
import { useTags } from '../hooks/useTags';
import { useCategory } from '../hooks/useCategory';
import { useProducts } from '../hooks/useProducts';


const FilterSidebar = ({ categories, tags, onTags }) => {
	const [visible, setVisibleFlag] = useState(false);
	const [tagsLoading, tagsMap] = useTags();
	const [catsLoading, categoriesMap] = useCategory();
	const [prodsLoading] = useProducts();

	const loading = tagsLoading || catsLoading || prodsLoading;

	const handleVisibleChange = (flag) => {
		setVisibleFlag(flag);
	};

	const MenuItem = useMemo(() => {
		if (loading || _isEmpty(categoriesMap)) {
			return (
				<main className="refinements-filter-body-wrapper shadow-2">
					<div className="gx-p-4">
						<Skeleton.Button active size="large" shape="round"></Skeleton.Button>
						{categories && (
							<>
								<h3 className="gx-algolia-category-title gx-mb-2 gx-mt-5">Category</h3>
								<hr className="gx-mt-0 filter-divider" />
								<Skeleton active title={false} paragraph={{ rows: 6 }}></Skeleton>
							</>
						)}
						{tags && (
							<>
								<h3 className="gx-algolia-category-title gx-mb-2 gx-mt-5">Tags</h3>
								<hr className="gx-mt-0 filter-divider" />
								<Skeleton active title={false} paragraph={{ rows: 6 }}></Skeleton>
							</>
						)}
					</div>
				</main>
			);
		}
		return (
			<Menu className="refinements-filter-body-wrapper">
				<Menu.Item>
					<div className="gx-algolia-sidebar">
						{categories && (
							<>
								<h3 className="gx-algolia-category-title gx-mb-2 gx-mt-3">Category</h3>
								<hr className="gx-mt-0 filter-divider" />
								<RefinementList
									className="gx-algolia-refinementList"
									attribute="category"
									limit={Number.POSITIVE_INFINITY}
									transformItems={categories =>
										categories
											.sort((a, b) => parseInt(a.label) - parseInt(b.label))
											.map(category => ({
												...category,
												label: categoriesMap[category.label] ?? category.label,
											}))
									}
								/>
							</>
						)}
						{tags && (
							<>
								<h3 className="gx-algolia-category-title gx-mb-2 gx-mt-3">Tags</h3>
								<hr className="gx-mt-0 filter-divider" />
								<RefinementList
									className="gx-algolia-refinementList"
									attribute="tag"
									limit={Number.POSITIVE_INFINITY}
									transformItems={tags => {
										onTags && onTags(tags);
										return tags
											.sort((a, b) => parseInt(a.label) - parseInt(b.label))
											.map(tag => ({
												...tag,
												label: tagsMap[tag.label] ?? tag.label,
											}));
									}}
								/>
							</>
						)}
					</div>
				</Menu.Item>
			</Menu>
		);
	}, [loading, categories, tags, categoriesMap]);

	return (
		<div className="refinements-filter-wrapper gx-mr-2">
			<Dropdown
				trigger={"click"}
				overlay={MenuItem}
				visible={visible}
				onVisibleChange={handleVisibleChange}
			>
				<div>
					<FilterContent popOverVisible={visible} />
				</div>
			</Dropdown>
		</div>
	);
};

export default FilterSidebar;
